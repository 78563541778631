import React from "react";
import MainLogo from "../../../../src/assets/pic/Main-Logo.svg";
import completeprofile from "../../../../src/assets/pic/complete-profile.svg";
import { useLanguage } from "../../../lib/language-context";
import { content } from "../../../localization/content";
import { useHistory, useLocation } from "react-router-dom";
import routes from "../../../routes";

const ShouldCompleteProfile = () => {
  const [lang, setLang] = useLanguage("");
  const langContent = content[lang];
  const history = useHistory();
  const { pathname, ...location } = useLocation();

  return (
    <div>
      <div className="mx-auto w-full h-full fixed  text-maintext  transition-all  ease-in-out  delay-100 bg-backgroundGray">
        {/* sign in */}
        <div className="max-w-7xl h-[45rem] absolute my-auto mx-auto left-0 right-0 top-[50%] bottom-[50%]">
          <img
            className="absolute z-10 -top-5 md:left-[45%]  left-[37%]  w-32 h-32  rounded-full mx-auto bg-white p-4"
            src={MainLogo}
            alt="logo"
          />
          <div className=" max-w-7xl h-[45rem]  overflow-hidden  rounded-3xl  bg-white absolute my-auto mx-auto left-0 right-0  top-[55%] bottom-[50%] text-center ">
            <div className="mx-auto mt-20">
              <img className="mx-auto" src={completeprofile} alt="" />
              <h1 className="text-3xl font-bold text-maintext py-2  ">
                {langContent.CompletedProfile.You_Should}
              </h1>
              <p className="py-4 w-80 mx-auto text-subtext">
                {langContent.CompletedProfile.to_continue}
              </p>
              <button
                onClick={() => {
                  history.push(routes.auth.completeprofileimg, {
                    Id: location?.state?.Id,
                    name: location?.state?.name,
                    img: location?.state?.img,
                    supplierCode: location?.state?.supplierCode,
                  });
                }}
                className="bg-maincolor text-white text-2xl rounded-full w-96 py-4 my-2"
              >
                {langContent.CompletedProfile.go_to}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShouldCompleteProfile;
