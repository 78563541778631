import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Button, Image, Segment } from "semantic-ui-react";
import UploadImgModel from "../../../component/Containers-component/Upload-Img-model";
import { useLanguage } from "../../../lib/language-context";
import { content } from "../../../localization/content";
import Containericongreen from "../../../../src/assets/pic/Container-icon-green-size.svg";
import Containernumbergreen from "../../../../src/assets/pic/Container-number-green.svg";
import { FiArrowUpRight } from "react-icons/fi";
import useAxios from "../../../lib/use-axios";
import { authAxios } from "../../../config/axios-config";
import api from "../../../api";
import routes from "../../../routes";
import { Carousel } from "react-responsive-carousel";
import Editimgmodel from "../../../component/Containers-component/Edit-img-model";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const UpdateContainer = () => {
  const [selectedSize, setSelectedSize] = React.useState("");
  const [lang, setLang] = useLanguage("");
  const langContent = content[lang];

  const [containers, SetContainers] = React.useState(0);
  const [checkcontainers, SetCheckContainers] = React.useState();

  const { state } = useLocation();

  const { pathname } = useLocation();
  const history = useHistory();

  const { run, isLoading, data, isError, error } = useAxios({});
  const {
    run: runpostcontainer,
    isLoading: isloadingpostcontainer,
    data: datapostcontainer,
    isError: inerrorpostcontainer,
    error: errorpostcontainer,
  } = useAxios({});
  const handelplus = () => SetNum(num + 1);
  const handelnegt = () => SetNum(num - 1);

  const editnum = data?.amount;
  const [num, SetNum] = useState(editnum);
  // SetNum(editnum);

  const getContainerSize = (size) => {
    run(authAxios.get(api.Dashboard.containers.getcontainers(size))).then(
      (res) => {
        setSelectedSize(size);
        SetNum(res?.data?.data?.amount);
      }
    );
  };

  const postContainerSize = () => {
    const body = {
      availabile_amount: num,
    };
    const success = (content) =>
      toast.success(content, {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

    runpostcontainer(
      authAxios.put(api.Dashboard.containers.getcontainers(data?.id), body)
    ).then(() => {
      history.push(routes.Dashboard.Branches.BranchesDetailsActive(state.id), {
        // ubdattostcontainer: true,
      });
      success(
        lang === "en"
          ? "This container has been added successfully"
          : " تمت التعديل علي هذه الحاوية بنجاح"
      );
    });
  };

  const [forceReload, setForceReload] = React.useState(false);
  const onReload = React.useCallback(() => setForceReload((p) => !p), []);

  React.useEffect(() => {
    getContainerSize(selectedSize);
  }, [forceReload]);

  return (
    <div className="max-w-7xl mx-auto ">
      <ToastContainer />
      <Segment
        className="border-none shadow-none bg-transparent  p-0 m-0 -z-1"
        loading={isLoading}
      >
        <div className=" pt-10 md:mx-auto  mx-2   max-w-7xl">
          <div className="text-4xl my-2 ">
            {langContent.SideBar.update_containers}
          </div>
          {/* <div className="text-subtext rtl:hidden">
            {pathname.split("/").join(" > ").slice(2)}
          </div> */}
        </div>
        <div className="py-4 md:mx-auto  mx-4  max-w-7xl">
          <p className="text-subtext ">{langContent.Branches.branch_name}</p>
          <p className="py-2 px-4 bg-[#E8AA001C] text-maincolor w-fit rounded-full flex mt-1">
            {state?.branchname} <FiArrowUpRight size={20} />
          </p>
        </div>
        <div className="grid md:grid-cols-2 grid-cols-1  py-16 max-w-7xl mx-auto mt-10">
          <div className="mx-auto">
            {/* <edit_ImgModel /> */}
            <Editimgmodel
              onReload={onReload}
              Container_id={data?.id}
              photo_1={data?.images && data?.images[0]?.link}
              photo_2={data?.images && data?.images[1]?.link}
              photo_3={data?.images && data?.images[2]?.link}
              photo_id_1={data?.images && data?.images[0]?.id}
              photo_id_2={data?.images && data?.images[1]?.id}
              photo_id_3={data?.images && data?.images[2]?.id}
            />
          </div>
          <div>
            <div>
              {/* Size */}
              <div className="w-full">
                <div className="flex mt-10 mb-8 mx-auto ">
                  <img className=" mx-2 " src={Containericongreen} alt="" />
                  <p className="text-subtext text-xl">
                    {langContent.Container.sizes_in_yards}
                  </p>
                </div>
                <div className="flex md:justify-start justify-center md:gap-10 gap-2">
                  <button
                    onClick={
                      state.TINY === false
                        ? ""
                        : () => {
                            SetContainers(6);
                            getContainerSize(state?.TINY_ID);
                          }
                    }
                    className={` ${
                      containers === 6
                        ? "bg-maincolor text-white border-maincolor  "
                        : state.TINY === false
                        ? "border-border text-border cursor-not-allowed "
                        : "text-maincolor border-maincolor "
                    }  border-2 w-24 h-16 rounded-2xl `}
                  >
                    6
                  </button>
                  <button
                    onClick={
                      state.SMALL === false
                        ? ""
                        : () => {
                            SetContainers(10);
                            getContainerSize(state?.SMALL_ID);
                          }
                    }
                    className={` ${
                      containers === 10
                        ? "bg-maincolor text-white "
                        : state.SMALL === false
                        ? "border-border text-border cursor-not-allowed "
                        : "text-maincolor border-maincolor "
                    }  border-2 w-24 h-16 rounded-2xl `}
                  >
                    10-12
                  </button>
                  <button
                    onClick={
                      state.MEDIUM === false
                        ? ""
                        : () => {
                            SetContainers(20);
                            getContainerSize(state?.MEDIUM_ID);
                          }
                    }
                    className={` ${
                      containers === 20
                        ? "bg-maincolor text-white "
                        : state.MEDIUM === false
                        ? "border-border text-border cursor-not-allowed "
                        : "text-maincolor border-maincolor "
                    }  border-2 w-24 h-16 rounded-2xl `}
                  >
                    20
                  </button>
                  <button
                    onClick={
                      state.LARGE === false
                        ? ""
                        : () => {
                            SetContainers(30);
                            getContainerSize(state?.LARGE_ID);
                          }
                    }
                    className={` ${
                      containers === 30
                        ? "bg-maincolor text-white "
                        : state.LARGE === false
                        ? "border-border text-border cursor-not-allowed "
                        : "text-maincolor border-maincolor "
                    }  border-2 w-24 h-16 rounded-2xl `}
                  >
                    30
                  </button>
                </div>
                {containers === 0 ? (
                  <span className="h-4 mx-4 py-3.5 text-red-600 text-left text-sm flex  ">
                    {lang === "en"
                      ? "Please select Sizes and yards of containers "
                      : "يرجى تحديد أحجام وساحات الحاويات"}
                  </span>
                ) : (
                  ""
                )}
              </div>
              {/* Number of containers */}
              <div className="py-16  ">
                <div className="flex my-2">
                  <img className=" mx-2 " src={Containernumbergreen} alt="" />
                  <p className="text-subtext text-xl">
                    {langContent.Container.number_Containers}
                  </p>
                </div>
                <div className="flex justify-center md:justify-start my-9 mx-auto gap-6">
                  <button
                    onClick={handelplus}
                    className="text-2xl text-subtext border-maincolor bg-white border-2 w-12 h-12 rounded-xl"
                  >
                    +
                  </button>
                  <p className="w-24 h-12 flex justify-center py-3 bg-subcolor text-black rounded-xl overflow-hidden ">
                    {/* {editnum || 0} */}
                    <input
                      className="text-center bg-transparent outline-none"
                      type="number"
                      value={parseInt(num)}
                      onChange={(e) => SetNum(parseInt(e.target.value))}
                    />
                  </p>
                  <button
                    onClick={num <= 0 ? "" : handelnegt}
                    className="text-2xl text-subtext border-maincolor bg-white border-2 w-12 h-12 rounded-xl"
                  >
                    -
                  </button>
                </div>
              </div>
              {/* buttons */}
              <div className=" mx-auto">
                <div className="flex md:mx-auto mx-8">
                  <Button
                    onClick={postContainerSize}
                    className="bg-maincolor text-white w-48 h-12 rounded-full shadow-lg shadow-maincolor/50 font-serif"
                  >
                    {langContent.Container.save}
                  </Button>
                  <Button
                    onClick={() => {
                      history.push(
                        routes.Dashboard.Branches.BranchesDetailsActive(
                          state.id
                        )
                      );
                    }}
                    className="bg-white text-maincolor border-2 border-maincolor w-48 h-12 rounded-full mx-4 shadow-md shadow-maincolor/10 font-serif"
                  >
                    {lang === "en" ? "close" : " إغلاق"}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Segment>
    </div>
  );
};

export default UpdateContainer;
