import React from "react";
import { axios } from "../../config/axios-config";
import { useHistory } from "react-router-dom";
import MainLogo from "../../../src/assets/pic/Main-Logo.svg";
import api from "../../api";
import InputForm from "../../component/common/input-form/Input-Form";
import { useLanguage } from "../../lib/language-context";
import useAxios from "../../lib/use-axios";
import { content } from "../../localization/content";
import routes from "../../routes";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import FormikInput from "../../component/common/formik/formik-input";
import { Button } from "semantic-ui-react";

const ForgetPassword = () => {
  const [lang, setLang] = useLanguage("");
  const langContent = content[lang];
  const history = useHistory();
  const { run, isLoading, isError, error, data, status } = useAxios();

  const registerSchema = Yup.object({
    mainPhoneNumber: Yup.string()
      .required(`${langContent.SignUpScreen.you_must_start_with} + 20`)
      .min(3, `${langContent.SignUpScreen.must_be_3}`),
  });

  const ForgetPassword = (values) => {
    const body = {
      phoneNumber: values.mainPhoneNumber,
    };
    run(axios.post(api.auth.ForgetPassword, body))
      .then((res) => {
        history.push(routes.auth.OtpForgetPass, {
          expiredToken: res.data.data.expiredToken,
          otp_id: res.data.data.otpResponse.otp_id,
        });
      })
      .catch((err) => {});
  };
  return (
    // bg
    <div className="mx-auto w-full h-full fixed  text-maintext transition-all ease-in-out delay-100  bg-backgroundGray">
      {/* ForgetPassword  */}
      <div className="max-w-7xl  h-[45rem] absolute my-auto mx-auto left-0 right-0  top-[50%] bottom-[50%]">
        <img
          className="absolute z-10 -top-5 left-[45%]  w-32 h-32  rounded-full mx-auto bg-white p-4"
          src={MainLogo}
          alt="logo"
        />
        <div className=" max-w-7xl h-[45rem]  overflow-hidden  rounded-3xl  bg-white absolute my-auto mx-auto left-0 right-0  top-[55%] bottom-[50%] text-center ">
          <div className="flex flex-col justify-center my-44">
            <h1 className="text-3xl font-sans mb-8">
              {" "}
              {langContent.ForgetPasswordScreen.forget_password}
            </h1>
            <p className=" text-sm">
              {langContent.ForgetPasswordScreen.please_enter_your_email}
            </p>

            <div className="pt-20 mx-auto w-96">
              <Formik
                initialValues={{
                  mainPhoneNumber: "",
                }}
                onSubmit={ForgetPassword}
                validationSchema={registerSchema}
              >
                {(formik) => (
                  <Form onSubmit={formik.handleSubmit}>
                    <FormikInput
                      placeholder={
                        langContent.ForgetPasswordScreen.phone_number
                      }
                      label={langContent.ForgetPasswordScreen.phone_number}
                      name="mainPhoneNumber"
                    />
                    <span className="h-4 mt-1 text-red-600 text-left text-sm flex  ">
                      {lang === "en" ? error?.message?.en : error?.message?.ar}
                    </span>
                    <div className="flex justify-between">
                      <Button
                        loading={isLoading}
                        className="w-full mx-auto py-4  my-4 rounded-3xl text-white bg-maincolor font-serif "
                        type="submit"
                      >
                        {langContent.ForgetPasswordScreen.send}
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
        {/* logo  */}
      </div>
    </div>
  );
};

export default ForgetPassword;
