import React, { useState } from "react";
import { BsDashLg } from "react-icons/bs";
import { GiWeight } from "react-icons/gi";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { Button } from "semantic-ui-react";
import UploadImgModel from "../../../component/Containers-component/Upload-Img-model";
import { useLanguage } from "../../../lib/language-context";
import { content } from "../../../localization/content";
import Contanergray from "../../../../src/assets/pic/Contaner-gray-sizes.svg";
import Contanergraynumber from "../../../../src/assets/pic/Container-gray-number.svg";
import useAxios from "../../../lib/use-axios";
import api from "../../../api";
import { authAxios } from "../../../config/axios-config";
import { FiArrowUpRight } from "react-icons/fi";
import routes from "../../../routes";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// 6- yards => house waste (نفايات منازل (
// 6-yards => restaurant waste (نفايات مطاعم )
// 10-12 yards => rubble waste (نفايات هدم )
// 20 -yards => rubble waste(نفايات هدم )
// 30 -yards =>light waste (نفايات خفيفة )

const AddContainer = () => {
  const [lang, setLang] = useLanguage("");
  const langContent = content[lang];
  const { state } = useLocation();
  const { pathname } = useLocation();
  const history = useHistory();

  const number = (t) => /^(?:[1-9]\d*)$/.test(t);

  const [photo1, SetPhoto1] = useState(null);
  const [photo2, SetPhoto2] = useState(null);
  const [photo3, SetPhoto3] = useState(null);

  const [num, SetNum] = useState(0);
  console.log(num);

  const [min, SetMin] = useState(0);
  const [max, SetMax] = useState(0);

  const handelplus = () => SetNum(num + 1);
  const handelnegt = () => SetNum(num - 1);

  const [wide, SetWide] = useState(0);
  const [tall, SetTall] = useState(0);
  const [long, SetLong] = useState(0);

  const [containers, SetContainers] = React.useState(0);
  const [checkcontainers, SetCheckContainers] = React.useState();

  // console.log(number(min));
  // console.log(number(max));

  // console.log();

  // number(long);
  // number(tall);
  // number(long);

  React.useEffect(() => {
    const success = (content) =>
      toast.success(content, {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    if (state.addtost === true) {
      success(
        lang === "en"
          ? "This branch has been added successfully"
          : " تمت إضافة هذا الفرع بنجاح"
      );
    }
  }, [state.addtost]);

  const { run, isLoading, data, isError, error } = useAxios({});
  const Addcontainers = () => {
    const formData = new FormData();
    formData.append("availabile_amount", num);
    formData.append("size", checkcontainers);
    formData.append("locationId", state.id);
    formData.append("capacity_min", min);
    formData.append("capacity_max", max);
    formData.append("size_wide", wide);
    formData.append("size_tall", tall);
    formData.append("size_long", long);
    formData.append("images", photo1);
    formData.append("images", photo2);
    formData.append("images", photo3);
    // for (var pair of formData.entries()) {
    //   console.log(pair[0] + ", " + pair[1]);
    // }

    const success = (content) =>
      toast.success(content, {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    run(
      authAxios
        .post(api.Dashboard.containers.containers, formData)
        .then((res) => {
          success(
            lang === "en"
              ? "This branch has been added successfully"
              : " تمت إضافة هذا الفرع بنجاح"
          );
          history.push(
            routes.Dashboard.Branches.BranchesDetailsActive(state.id),
            {
              // addtostcontainer: true,
            }
          );
          SetPhoto1(null);
          SetPhoto2(null);
          SetPhoto3(null);
          SetNum(0);
          SetMin(0);
          SetMax(0);
          SetWide(0);
          SetTall(0);
          SetLong(0);
          checkcontainers(0);
        })
    );
  };

  const onSave = () => {
    if (
      containers &&
      num > 0 &&
      number(wide) &&
      number(tall) &&
      number(long) &&
      number(min) &&
      number(max)
    ) {
      Addcontainers();
    }
  };

  return (
    <div className=" mx-auto  max-w-7xl">
      <ToastContainer />
      <div className=" pt-10 ">
        <div className="text-4xl my-2 mx-2 ">
          {langContent.Container.add_container}
        </div>
        {/* <div className="text-subtext rtl:hidden  ">
          {pathname.split("/").join(" > ").slice(2)}
        </div> */}
      </div>
      <div className=" py-4 mx-auto  max-w-7xl   ">
        <p className="text-subtext mx-4">{langContent.Branches.branch_name}</p>
        <p className="py-2 px-3  mx-2 bg-[#E8AA001C] text-maincolor w-fit rounded-full  flex mt-1">
          {state?.branchname} <FiArrowUpRight size={20} />
        </p>
      </div>
      <div className=" mx-auto mt-16 grid md:grid-cols-2 grid-cols-1 ">
        {/* left */}
        <div className="mx-auto ">
          <div className=" ">
            <h1 className="flex py-4 mx-2 text-subtext">
              <img
                src={Contanergray}
                className="ltr:mr-2 rtl:ml-2 "
                size={20}
                alt=""
              />
              <p>{langContent.Container.sizes_in_yards}</p>
            </h1>
            <div className="flex md:justify-start justify-center  gap-4 ">
              <button
                onClick={
                  state.TINY === true
                    ? ""
                    : () => {
                        SetContainers(6);
                        SetCheckContainers("TINY");
                      }
                }
                className={` ${
                  containers === 6
                    ? "bg-maincolor text-white border-maincolor  "
                    : state.TINY === true
                    ? "border-border text-border cursor-not-allowed "
                    : "text-maincolor border-maincolor "
                }  border-2 w-24 h-16 rounded-2xl `}
              >
                6
              </button>
              <button
                onClick={
                  state.SMALL === true
                    ? ""
                    : () => {
                        SetContainers(10);
                        SetCheckContainers("SMALL");
                      }
                }
                className={` ${
                  containers === 10
                    ? "bg-maincolor text-white "
                    : state.SMALL === true
                    ? "border-border text-border cursor-not-allowed "
                    : "text-maincolor border-maincolor "
                }  border-2 w-24 h-16 rounded-2xl `}
              >
                10-12
              </button>
              <button
                onClick={
                  state.MEDIUM === true
                    ? ""
                    : () => {
                        SetContainers(20);
                        SetCheckContainers("MEDIUM");
                      }
                }
                className={` ${
                  containers === 20
                    ? "bg-maincolor text-white "
                    : state.MEDIUM === true
                    ? "border-border text-border cursor-not-allowed "
                    : "text-maincolor border-maincolor "
                }  border-2 w-24 h-16 rounded-2xl `}
              >
                20
              </button>
              <button
                onClick={
                  state.LARGE === true
                    ? ""
                    : () => {
                        SetContainers(30);
                        SetCheckContainers("LARGE");
                      }
                }
                className={` ${
                  containers === 30
                    ? "bg-maincolor text-white "
                    : state.LARGE === true
                    ? "border-border text-border cursor-not-allowed "
                    : "text-maincolor border-maincolor "
                }  border-2 w-24 h-16 rounded-2xl `}
              >
                30
              </button>
            </div>
            {containers === 0 ? (
              <span className="h-4 py-3.5 mx-4 text-red-600 text-left text-sm flex  ">
                {lang === "en"
                  ? "Please add yards sizes of containers "
                  : "الرجاء إضافة ساحات الأحجام من الحاويات"}
              </span>
            ) : (
              ""
            )}
            <div>
              <h1 className="flex py-4 mx-2 text-subtext font-serif">
                <img
                  src={Contanergraynumber}
                  className="ltr:mr-2 rtl:ml-2 "
                  size={20}
                  alt=""
                />
                {langContent.Container.purpose}
              </h1>
              <p className=" py-6 text-subtext px-4 mx-4 rounded-2xl bg-white">
                {containers === 6
                  ? lang === "en"
                    ? "house waste - restaurant waste "
                    : "نفايات منازل  - نفايات مطاعم  "
                  : containers === 10
                  ? lang === "en"
                    ? "rubble waste"
                    : "نفايات هدم "
                  : containers === 20
                  ? lang === "en"
                    ? "rubble waste"
                    : "نفايات هدم "
                  : containers === 30
                  ? lang === "en"
                    ? "light waste"
                    : " نفايات خفيفة"
                  : ""}
              </p>
            </div>
          </div>
          {/* number and size cm */}
          <div className="grid grid-cols-2 gap-5 mx-2 mt-4">
            {/* number */}
            <div className="">
              <h1 className="flex py-4 text-subtext font-serif">
                <img
                  src={Contanergraynumber}
                  className="ltr:mr-2 rtl:ml-2 "
                  size={20}
                  alt=""
                />
                {langContent.Container.number_Containers}
              </h1>
              <div className="flex mt-10 gap-6">
                <button
                  onClick={handelplus}
                  className="text-2xl text-subtext border-border bg-white border-2 w-12 h-12 rounded-xl"
                >
                  +
                </button>
                <p className="w-24 h-12 flex justify-center py-3 bg-plastext text-black rounded-xl overflow-hidden ">
                  <input
                    className="text-center bg-transparent outline-none "
                    type="number"
                    value={num <= 0 ? 0 : num}
                    onChange={(e) => SetNum(parseInt(e.target.value))}
                  />
                </p>
                <button
                  onClick={num <= 0 ? "" : handelnegt}
                  className="text-2xl text-subtext border-border bg-white border-2 w-12 h-12 rounded-xl"
                >
                  -
                </button>
              </div>
              {num <= 0 ? (
                <span className="h-4 py-3.5 text-red-600 text-left text-sm flex  ">
                  {lang === "en"
                    ? "Please add the Number of Containers"
                    : "الرجاء إضافة عدد الحاويات"}
                </span>
              ) : (
                ""
              )}
            </div>
            {/* cm */}
            <div>
              <h1 className="flex  py-4 text-subtext font-serif">
                <img
                  src={Contanergray}
                  className="ltr:mr-2 rtl:ml-2 "
                  size={20}
                  alt=""
                />
                {langContent.Container.size_in_cm}
              </h1>
              <div className="flex gap-4">
                <div className="">
                  <h1 className="py-2 font-serif">
                    {langContent.Container.length}
                  </h1>
                  <input
                    onChange={(e) => SetLong(e.target.value)}
                    className="text-2xl px-1 text-subtext border-border bg-white border-2 w-14 h-14 rounded-xl"
                    type="text"
                    maxLength={3}
                  />
                </div>
                <div className="">
                  <h1 className="py-2 font-serif">
                    {langContent.Container.width}
                  </h1>
                  <input
                    onChange={(e) => SetWide(e.target.value)}
                    className="text-2xl px-1 text-subtext border-border bg-white border-2 w-14 h-14 rounded-xl"
                    type="text"
                    maxLength={3}
                  />
                </div>
                <div className="">
                  <h1 className="py-2 font-serif">
                    {" "}
                    {langContent.Container.high}
                  </h1>
                  <input
                    onChange={(e) => SetTall(e.target.value)}
                    className="text-2xl px-1 text-subtext border-border bg-white border-2 w-14 h-14 rounded-xl"
                    type="text"
                    maxLength={3}
                  />
                </div>
              </div>
              {long === 0 ? (
                <span className="h-4  pt-3  text-red-600 text-left text-xs flex  ">
                  {lang === "en"
                    ? "Please add Length,Width,Height of containers "
                    : "الرجاء إضافة الطول والعرض والارتفاع للحاويات "}
                </span>
              ) : wide === "" ? (
                <span className="h-4  pt-3 text-red-600 text-left text-xs flex  ">
                  {lang === "en"
                    ? "Please add Length,Width,Height of containers "
                    : "الرجاء إضافة الطول والعرض والارتفاع للحاويات "}
                </span>
              ) : tall === "" ? (
                <span className="h-4 pt-3  text-red-600 text-left text-xs flex  ">
                  {lang === "en"
                    ? "Please add Length,Width,Height of containers "
                    : "الرجاء إضافة الطول والعرض والارتفاع للحاويات"}
                </span>
              ) : (
                ""
              )}
            </div>
          </div>
          {/* hold and wait */}
          <div className=" py-8">
            <h1 className="flex py-4 text-subtext mx-2 font-serif">
              <GiWeight className="ltr:mr-2 rtl:ml-2 mb-2" size={20} />
              {langContent.Container.holds_wight}
            </h1>
            <div className="grid grid-cols-2 gap-4 mx-1">
              <div className="mx-auto">
                <div className="flex">
                  <input
                    onChange={(e) => SetMin(e.target.value)}
                    className="bg-white  md:w-full w-32 py-4 px-2 rounded-xl outline-none  "
                    type="text"
                    maxLength={4}
                  />
                  <p className="text-2xl text-subtext py-3 px-2">
                    {langContent.Container.kg}
                  </p>
                  <div className="md:block mt-4 hidden  text-subtext">
                    <BsDashLg size={25} />
                  </div>
                </div>
              </div>
              <div className="mx-auto">
                <div className="flex">
                  <input
                    onChange={(e) => SetMax(e.target.value)}
                    className="bg-white md:w-full w-32 py-4 px-2 rounded-xl outline-none  "
                    type="text"
                    maxLength={4}
                  />
                  <p className="text-2xl text-subtext py-3 px-2">
                    {langContent.Container.kg}
                  </p>
                </div>
              </div>
            </div>
            {min === 0 ? (
              <span className="h-4 mx-2  text-red-600 text-left text-sm flex  ">
                {lang === "en"
                  ? "Please add Holds Wight of containers "
                  : "يرجى إضافة أوزان الحاويات"}
              </span>
            ) : max === 0 ? (
              <span className="h-4  text-red-600 text-left text-sm flex  ">
                {lang === "en"
                  ? "Please add Holds Wight of containers "
                  : "يرجى إضافة أوزان الحاويات"}
              </span>
            ) : (
              ""
            )}
          </div>
        </div>
        {/* right */}
        <div className="mx-auto  ">
          {/* imge upload */}
          <div className=" mx-auto py-4">
            <UploadImgModel
              SetPhoto_1={SetPhoto1}
              SetPhoto_2={SetPhoto2}
              SetPhoto_3={SetPhoto3}
            />
            <span className="h-4 mt-1 text-red-600 text-left text-sm flex  ">
              {lang === "en" ? error?.message?.en : error?.message?.ar}
            </span>
          </div>
          {/* button */}
          <div className=" flex justify-between mb-8 mx-auto">
            <Button
              loading={isLoading}
              onClick={onSave}
              disabled={
                containers &&
                num > 0 &&
                number(wide) &&
                number(tall) &&
                number(long) &&
                number(min) &&
                number(max)
                  ? false
                  : true
              }
              className="text-white w-48 h-12 rounded-full  shadow-lg bg-maincolor font-serif"
            >
              {langContent.Container.save}
            </Button>
            <Button
              onClick={() => {
                history.push(
                  routes.Dashboard.Branches.BranchesDetailsActive(state.id)
                );
              }}
              className="bg-white text-maincolor border-2 border-maincolor w-48 h-12 rounded-full  shadow-md shadow-maincolor/10 font-serif"
            >
              {lang === "en" ? "close" : " إغلاق"}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddContainer;
