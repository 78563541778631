import React, { useState } from "react";
import { Carousel } from "react-responsive-carousel";
import { Button, Image, Modal } from "semantic-ui-react";
import UploadIcon from "../../../src/assets/pic/Upload-icon.svg";
import { useLanguage } from "../../lib/language-context";
import { content } from "../../localization/content";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import useAxios from "../../lib/use-axios";
import { authAxios } from "../../config/axios-config";
import api from "../../api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Editimgmodel({
  photo_1,
  photo_2,
  photo_3,
  photo_id_1,
  photo_id_2,
  photo_id_3,
  Container_id,
  onReload,
}) {
  const [isLoading, setIsLoading] = useState("");

  const [photo1, SetPhoto1] = useState(null);
  const [photo2, SetPhoto2] = useState(null);
  const [photo3, SetPhoto3] = useState(null);

  const [photoone, SetPhotoOne] = useState(null);
  const [phototwo, SetPhotoTwo] = useState(null);
  const [photothree, SetPhotoThree] = useState(null);

  const [photoCheck1, SetPhotoCheck1] = useState(false);
  const [photoCheck2, SetPhotoCheck2] = useState(false);
  const [photoCheck3, SetPhotoCheck3] = useState(false);

  const [lang, setLang] = useLanguage("");
  const langContent = content[lang];
  const [open, setOpen] = React.useState(false);

  const { run, data, isError, error } = useAxios({});

  const UploadImg = (id, photo) => {
    const formData = new FormData();
    formData.append("oldImageId", id);
    formData.append("image", photo);
    setIsLoading(id);
    const success = (content) =>
      toast.success(content, {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

    run(
      authAxios.post(
        api.Dashboard.containers.postimgcontainer(Container_id),
        formData
      )
    ).finally(() => {
      success(
        lang === "en"
          ? "The Container image added successfully"
          : " تمت إضافة صورة الحاوية بنجاح"
      );
      setIsLoading("");
    });
  };

  return (
    <div dir="ltr">
      <ToastContainer />
      <Modal
        className="md:w-full w-[80rem] h-[40rem]   overflow-hidden overflow-y-scroll  scrollbar-hide"
        onClose={() => {
          setOpen(false);
          SetPhotoCheck1(false);
          SetPhotoCheck2(false);
          SetPhotoCheck3(false);
          onReload();
        }}
        onOpen={() => {
          setOpen(true);
        }}
        open={open}
      >
        <div className="">
          <div className="mx-auto md:flex justify-around grid grid-cols-1 my-8">
            <label className="border-maincolor border-2 border-dashed w-[25rem] h-[30rem] bg-transparent  rounded-xl  mx-auto ">
              <input
                onChange={(e) => {
                  SetPhoto1(URL?.createObjectURL(e?.target?.files[0]));
                  SetPhotoOne(e.target.files[0]);
                  SetPhotoCheck1(true);
                }}
                type="file"
                className="hidden"
              />

              {photo_1 === undefined ? (
                <div className="flex flex-col items-center my-36">
                  <img className="mx-auto" src={UploadIcon} alt="" />
                  <p className="text-maincolor  text-sm  my-3 ">
                    {langContent.Container.uplad_img_s}
                  </p>
                  <p className="text-sm  text-subtext mx-4 text-center ">
                    {langContent.Container.uplad_img_m}
                  </p>
                </div>
              ) : (
                <div className=" w-[24rem] h-[29rem] mx-auto my-1  ">
                  {photoCheck1 === true ? (
                    <img
                      className="w-full h-full object-cover border-2    rounded-xl "
                      src={photo1}
                      alt=""
                    />
                  ) : (
                    <img
                      className="w-full h-full object-cover border-2    rounded-xl "
                      src={photo_1}
                      alt=""
                    />
                  )}
                </div>
              )}
            </label>
            <Button
              loading={isLoading === photo_id_1}
              onClick={() => {
                UploadImg(photo_id_1, photoone);
              }}
              className=" md:hidden block bg-maincolor text-white w-48 h-12 rounded-full my-2 shadow-lg shadow-maincolor/50 mx-auto font-serif"
            >
              {lang === "en" ? " Upload img one" : " قم برفع الصورة الأولي"}
            </Button>
            <label className=" border-maincolor border-2 border-dashed w-[25rem] h-[30rem] bg-transparent  rounded-xl  mx-auto ">
              <input
                type="file"
                className="hidden"
                onChange={(e) => {
                  SetPhoto2(URL?.createObjectURL(e?.target?.files[0]));
                  SetPhotoTwo(e.target.files[0]);
                  SetPhotoCheck2(true);
                }}
              />

              {photo_2 !== undefined ? (
                <div className=" w-[24rem] h-[29rem] mx-auto my-1  ">
                  {photoCheck2 === true ? (
                    <img
                      className="w-full h-full object-cover border-2    rounded-xl "
                      src={photo2}
                      alt=""
                    />
                  ) : (
                    <img
                      className="w-full h-full object-cover border-2    rounded-xl "
                      src={photo_2}
                      alt=""
                    />
                  )}
                </div>
              ) : (
                <div className="flex flex-col items-center my-36">
                  <img className="mx-auto" src={UploadIcon} alt="" />
                  <p className="text-maincolor  text-sm  my-3 ">
                    {langContent.Container.uplad_img_s}
                  </p>
                  <p className="text-sm  text-subtext mx-4 text-center ">
                    {langContent.Container.uplad_img_m}
                  </p>
                </div>
              )}
            </label>
            <Button
              loading={isLoading === photo_id_2}
              onClick={() => {
                UploadImg(photo_id_2, phototwo);
              }}
              className=" md:hidden block bg-maincolor text-white w-48 h-12 my-2 rounded-full mx-auto shadow-lg shadow-maincolor/50 font-serif"
            >
              {lang === "en" ? " Upload img two" : " قم برفع الصورة الثانية"}
            </Button>
            <label className="border-maincolor border-2 border-dashed w-[25rem] h-[30rem] bg-transparent  rounded-xl mx-auto ">
              <input
                type="file"
                className="hidden"
                onChange={(e) => {
                  SetPhoto3(URL?.createObjectURL(e?.target?.files[0]));
                  SetPhotoThree(e?.target?.files[0]);
                  SetPhotoCheck3(true);
                }}
              />
              {photo_3 !== undefined ? (
                <div className=" w-[24rem] h-[29rem] mx-auto my-1  ">
                  {photoCheck3 === true ? (
                    <img
                      className="w-full h-full object-cover border-2    rounded-xl "
                      src={photo3}
                      alt=""
                    />
                  ) : (
                    <img
                      className="w-full h-full object-cover border-2    rounded-xl "
                      src={photo_3}
                      alt=""
                    />
                  )}
                </div>
              ) : (
                <div className="flex flex-col items-center my-36">
                  <img className="mx-auto" src={UploadIcon} alt="" />
                  <p className="text-maincolor  text-sm  my-3 ">
                    {langContent.Container.uplad_img_s}
                  </p>
                  <p className="text-sm  text-subtext mx-4 text-center ">
                    {langContent.Container.uplad_img_m}
                  </p>
                </div>
              )}
            </label>
            <Button
              loading={isLoading === photo_id_3}
              onClick={() => {
                UploadImg(photo_id_3, photothree);
              }}
              className="md:hidden block bg-maincolor text-white w-48 h-12 rounded-full mx-auto py-2 shadow-lg shadow-maincolor/50 my-2 font-serif"
            >
              {lang === "en" ? " Upload img three" : " قم برفع الصورة الثالثة"}
            </Button>
          </div>

          <div className="mx flex justify-end gap-10 ">
            <div className="md:flex hidden w-full  justify-around ">
              <Button
                loading={isLoading === photo_id_1}
                onClick={() => {
                  UploadImg(photo_id_1, photoone);
                }}
                className="bg-maincolor text-white w-fill h-12 rounded-full mx-4 shadow-lg shadow-maincolor/50 font-serif "
              >
                {lang === "en" ? " Upload img one" : " قم برفع الصورة الأولي"}
              </Button>
              <Button
                loading={isLoading === photo_id_2}
                onClick={() => {
                  UploadImg(photo_id_2, phototwo);
                }}
                className="bg-maincolor text-white w-fill h-12 rounded-full mx-4 shadow-lg shadow-maincolor/50 font-serif"
              >
                {lang === "en" ? " Upload img two" : " قم برفع الصورة الثانية"}
              </Button>
              <Button
                loading={isLoading === photo_id_3}
                onClick={() => {
                  UploadImg(photo_id_3, photothree);
                }}
                className="bg-maincolor text-white w-fill h-12 rounded-full mx-4 shadow-lg shadow-maincolor/50 my-2  font-serif"
              >
                {lang === "en"
                  ? " Upload img three"
                  : " قم برفع الصورة الثالثة"}
              </Button>
            </div>
          </div>
          {photo_1 === undefined ? (
            <p className="text-red-500 py-8 flex justify-center">
              {lang === "en"
                ? "Upload 3 images with different sides"
                : "قم بتحميل 3 صور بجوانب مختلفة"}
            </p>
          ) : photo_2 === undefined ? (
            <p className="text-red-500 py-8 flex justify-center">
              {lang === "en"
                ? "Upload 3 images with different sides "
                : "قم بتحميل 3 صور بجوانب مختلفة"}
            </p>
          ) : photo_3 === undefined ? (
            <p className="text-red-500 py-8 flex justify-center ">
              {lang === "en"
                ? "Upload 3 images with different sides"
                : "قم بتحميل 3 صور بجوانب مختلفة"}
            </p>
          ) : (
            ""
          )}
        </div>
      </Modal>

      {photo_1 === undefined ? (
        <Button
          className={`  ${
            photo1 !== undefined
              ? "border-maincolor border-2 border-dashed w-[25rem] h-[30rem] bg-transparent   rounded-xl  font-serif"
              : "hidden"
          }  `}
          onClick={() => {
            setOpen(true);
          }}
        >
          <div>
            <img className="mx-auto" src={UploadIcon} alt="" />
            <p className="text-maincolor  text-sm  my-3">
              {langContent.Container.uplad_img_s}
            </p>
            <p className="text-sm  text-subtext">
              {langContent.Container.uplad_img_m}
            </p>
          </div>
        </Button>
      ) : (
        <Carousel
          // infiniteLoop={true}
          // autoPlay={true}
          showThumbs={false}
          className="border-maincolor border-2 border-dashed w-[25rem] h-[30rem] bg-transparent  object-cover rounded-xl"
        >
          <div className="w-[24rem] h-[29rem] object-cover edit-for-btn-img">
            <Image
              className="w-[24rem] h-[29rem] object-cover border-2  rounded-xl bg-cover"
              as={Button}
              onClick={() => setOpen(true)}
              src={photo_1}
              alt=""
            />
          </div>
          <div>
            <Image
              as={Button}
              onClick={() => setOpen(true)}
              className="w-[24rem] h-[29rem] object-cover border-2  rounded-xl bg-cover"
              src={photo_2}
              alt=""
            />
          </div>
          <div>
            <Image
              as={Button}
              onClick={() => setOpen(true)}
              className="w-[24rem] h-[29rem] object-cover border-2  rounded-xl bg-cover"
              src={photo_3}
              alt=""
            />
          </div>
        </Carousel>
      )}
    </div>
  );
}

export default Editimgmodel;
