import {
  Combobox,
  ComboboxInput,
  ComboboxList,
  ComboboxOption,
  ComboboxPopover,
} from "@reach/combobox";
import { useEffect } from "react";
import { Button } from "semantic-ui-react";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";

const MapSearch = ({ selectedLocation, panTo, address, confirm }) => {
  const {
    ready,
    value,
    setValue,
    suggestions: { status, data },
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      location: {
        lat: () => selectedLocation.lat,
        lng: () => selectedLocation.lng,
      },
      radius: 200 * 1000,
    },
  });

  useEffect(() => {
    setValue(address, false);
    clearSuggestions();
  }, [address, clearSuggestions, setValue]);

  const handleInput = (e) => {
    setValue(e.target.value);
  };

  const handleSelect = async (address) => {
    setValue(address, false);
    clearSuggestions();

    try {
      const results = await getGeocode({ address });
      const { lat, lng } = await getLatLng(results[0]);
      panTo({ lat, lng });
    } catch (error) {
      console.log("😱 Error: ", error);
    }
  };

  return (
    <div className="mapSearch mt-5 lg:mt-0  mx-5 md:mx-0 ">
      <Combobox onSelect={handleSelect}>
        <ComboboxInput
          value={value}
          onChange={handleInput}
          disabled={!ready}
          className="rounded-2xl py-4 px-2  outline-none  bg-white/90 "
          placeholder="Search your location"
        />
        <ComboboxPopover className="rounded-xl border-0 mt-2 shadow-lg hovrt:rounded-xl z-0  bg-white/90">
          <ComboboxList>
            {status === "OK" &&
              data.map(({ id, description }) => (
                <ComboboxOption
                  key={id}
                  value={description}
                  className="cursor-pointer"
                />
              ))}
          </ComboboxList>
        </ComboboxPopover>
      </Combobox>
      {/* <div className="text-center">
        <Button
          content={"Confirm"}
          disabled={["", null, undefined].includes(value)}
          className="bg-primaryRedColor-default text-white mt-5 z-0"
          onClick={() => confirm(value)}
        />
      </div> */}
    </div>
  );
};

export default MapSearch;
