import React, { useState } from "react";
import { Carousel } from "react-responsive-carousel";
import { Button, Image, Modal } from "semantic-ui-react";
import UploadIcon from "../../../src/assets/pic/Upload-icon.svg";
import { useLanguage } from "../../lib/language-context";
import { content } from "../../localization/content";
import "react-responsive-carousel/lib/styles/carousel.min.css";

function UploadImgModel({ SetPhoto_1, SetPhoto_2, SetPhoto_3 }) {
  const [photo1, SetPhoto1] = useState(null);
  const [photo2, SetPhoto2] = useState(null);
  const [photo3, SetPhoto3] = useState(null);

  const [lang, setLang] = useLanguage("");
  const langContent = content[lang];
  const [open, setOpen] = React.useState(false);

  return (
    <div dir="ltr" className="mx-auto">
      <Modal
        className="md:w-[80rem] w-full h-[39rem] overflow-hidden overflow-y-scroll scrollbar-hide"
        onClose={() => {
          setOpen(false);
        }}
        onOpen={() => {
          // SetPhoto1(null);
          // SetPhoto2(null);
          // SetPhoto3(null);
          setOpen(true);
        }}
        open={open}
      >
        <div className="">
          <div className="mx-auto md:flex justify-around grid grid-cols-1 gap-5 md:mx-5 my-8">
            <label className="border-maincolor border-2 border-dashed w-[25rem] h-[30rem] bg-transparent  rounded-xl  mx-auto">
              <input
                onChange={(e) => {
                  SetPhoto1(URL?.createObjectURL(e?.target?.files[0]));
                  SetPhoto_1(e.target.files[0]);
                }}
                type="file"
                className="hidden"
              />

              {photo1 === null ? (
                <div className="flex flex-col items-center my-36 ">
                  <img className="mx-auto" src={UploadIcon} alt="" />
                  <p className="text-maincolor  text-sm  my-3 ">
                    {langContent.Container.uplad_img_s}
                  </p>
                  <p className="text-sm  text-subtext mx-4 text-center ">
                    {langContent.Container.uplad_img_m}
                  </p>
                </div>
              ) : (
                <div className=" w-[24rem] h-[29rem] mx-auto my-1  ">
                  <img
                    className="w-full h-full object-cover border-2    rounded-xl "
                    src={photo1}
                    alt=""
                  />
                </div>
              )}
            </label>
            <label className="border-maincolor border-2 border-dashed w-[25rem] h-[30rem] bg-transparent  rounded-xl mx-auto my-8 md:my-0 ">
              <input
                type="file"
                className="hidden"
                onChange={(e) => {
                  SetPhoto2(URL?.createObjectURL(e?.target?.files[0]));
                  SetPhoto_2(e.target.files[0]);
                }}
              />

              {photo2 !== null ? (
                <div className=" w-[24rem] h-[29rem] mx-auto my-1  ">
                  <img
                    className="w-full h-full object-cover border-2    rounded-xl "
                    src={photo2}
                    alt=""
                  />
                </div>
              ) : (
                <div className="flex flex-col items-center my-36">
                  <img className="mx-auto" src={UploadIcon} alt="" />
                  <p className="text-maincolor  text-sm  my-3 ">
                    {langContent.Container.uplad_img_s}
                  </p>
                  <p className="text-sm  text-subtext mx-4 text-center ">
                    {langContent.Container.uplad_img_m}
                  </p>
                </div>
              )}
            </label>
            <label className="border-maincolor border-2 border-dashed w-[25rem] h-[30rem] bg-transparent  rounded-xl  mx-auto ">
              <input
                type="file"
                className="hidden"
                onChange={(e) => {
                  SetPhoto3(URL?.createObjectURL(e?.target?.files[0]));
                  SetPhoto_3(e.target.files[0]);
                }}
              />
              {photo3 !== null ? (
                <div className=" w-[24rem] h-[29rem] mx-auto my-1  ">
                  <img
                    className="w-full h-full object-cover border-2    rounded-xl "
                    src={photo3}
                    alt=""
                  />
                </div>
              ) : (
                <div className="flex flex-col items-center my-36">
                  <img className="mx-auto" src={UploadIcon} alt="" />
                  <p className="text-maincolor  text-sm  my-3 ">
                    {langContent.Container.uplad_img_s}
                  </p>
                  <p className="text-sm  text-subtext mx-4 text-center ">
                    {langContent.Container.uplad_img_m}
                  </p>
                </div>
              )}
            </label>
          </div>
          <div className="mx-2  flex md:justify-end justify-center flex-wrap  gap-10 ">
            <div className=" flex md:flex-row flex-col gap-5">
              <div className="flex">
                {photo1 === null ? (
                  <p className="text-red-500 py-2">
                    {lang === "en"
                      ? "Upload 3 images with different sides"
                      : "قم بتحميل 3 صور بجوانب مختلفة"}
                  </p>
                ) : photo2 === null ? (
                  <p className="text-red-500 py-2">
                    {lang === "en"
                      ? "Upload 3 images with different sides"
                      : "قم بتحميل 3 صور بجوانب مختلفة"}
                  </p>
                ) : photo3 === null ? (
                  <p className="text-red-500 py-2">
                    {lang === "en"
                      ? "Upload 3 images with different sides"
                      : "قم بتحميل 3 صور بجوانب مختلفة"}
                  </p>
                ) : (
                  ""
                )}
              </div>
              <Button
                onClick={
                  photo1 === null
                    ? ""
                    : photo2 === null
                    ? ""
                    : photo3 === null
                    ? ""
                    : () => {
                        setOpen(false);
                      }
                }
                className="bg-maincolor text-white w-48 h-12 rounded-full md:mx-4 mx-auto shadow-lg shadow-maincolor/50 font-serif"
              >
                {langContent.Container.save}
              </Button>
              <Button
                onClick={() => {
                  setOpen(false);
                }}
                className="bg-white text-maincolor border-2 border-maincolor w-48 h-12 rounded-full md:mx-4 mx-auto shadow-md shadow-maincolor/10 mb-8 font-serif"
              >
                {langContent.Container.cancel}
              </Button>
            </div>
          </div>
        </div>
      </Modal>

      {photo1 === null ? (
        <Button
          className={`  ${
            photo1 !== null
              ? "hidden"
              : "border-maincolor border-2 border-dashed w-[25rem] h-[30rem] bg-transparent  rounded-xl font-serif "
          }  `}
          onClick={() => {
            setOpen(true);
          }}
        >
          <div>
            <img className="mx-auto" src={UploadIcon} alt="" />
            <p className="text-maincolor  text-sm  my-3">
              {langContent.Container.uplad_img_s}
            </p>
            <p className="text-sm  text-subtext">
              {langContent.Container.uplad_img_m}
            </p>
          </div>
        </Button>
      ) : (
        <Carousel
          infiniteLoop={true}
          autoPlay={true}
          showThumbs={false}
          className="border-maincolor border-2 border-dashed w-[25rem] h-[30rem] bg-transparent  object-cover rounded-xl"
        >
          <div className="w-[24rem] h-[29rem] object-cover edit-for-btn-img">
            <Image
              className="w-[24rem] h-[29rem] object-cover border-2  rounded-xl bg-cover"
              as={Button}
              onClick={() => setOpen(true)}
              src={photo1}
              alt=""
            />
          </div>
          <div>
            <Image
              as={Button}
              onClick={() => setOpen(true)}
              className="w-[24rem] h-[29rem] object-cover border-2  rounded-xl bg-cover"
              src={photo2}
              alt=""
            />
          </div>
          <div>
            <Image
              as={Button}
              onClick={() => setOpen(true)}
              className="w-[24rem] h-[29rem] object-cover border-2  rounded-xl bg-cover"
              src={photo3}
              alt=""
            />
          </div>
        </Carousel>
      )}
    </div>
  );
}

export default UploadImgModel;
