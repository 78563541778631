import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Button, Segment, Table } from "semantic-ui-react";
import api from "../../api";
import { authAxios } from "../../config/axios-config";
import useAxios from "../../lib/use-axios";
import routes from "../../routes";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { content } from "../../localization/content";
import { useLanguage } from "../../lib/language-context";
import emity from "../../../src/assets/pic/emity-state-branch.svg";

const BrachesArchiveTable = () => {
  const history = useHistory();
  const [lang, setLang] = useLanguage("");
  const langContent = content[lang];
  const { run, isLoading, data, isError, error, totalPages } = useAxios({});

  const { pathname, ...location } = useLocation();

  const isArchived = true;
  React.useEffect(() => {
    const success = (content) =>
      toast.success(content, {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    if (location?.state?.greentost === true) {
      success(
        lang === "en"
          ? "This branch has been removed from the archive"
          : " تمت إزالة هذا الفرع من الأرشيف "
      );
    }
  }, [lang, location?.state?.greentost]);
  React.useEffect(() => {
    run(authAxios.get(`${api.Dashboard.ViewBraches}?isArchived=${isArchived}`));
  }, [isArchived, run]);

  // const unarchive = () => {
  //   run(
  //     authAxios.patch(`${api.Dashboard.BranchesDetails(data.id)}/unarchive`)
  //   ).then((res) => {});
  // };

  return (
    <div>
      {data?.length === 0 ? (
        <div className="my-4">
          <img className="mx-auto  " src={emity} alt="" />
          <p className="text-maincolor font-bold text-5xl text-center my-10">
            {lang === "en"
              ? "There are no archived branches"
              : "لا توجد فروع مؤرشفة "}
          </p>
        </div>
      ) : (
        <Segment
          className="border-none shadow-none p-0 m-0 -z-1"
          loading={isLoading}
        >
          <ToastContainer />
          <Table className="" basic="very">
            <Table.Header className="hidden md:contents">
              <Table.Row>
                <Table.HeaderCell className=" text-center border-none text-hedertext ">
                  {langContent.Branches.branchـname}
                </Table.HeaderCell>
                <Table.HeaderCell className=" text-center border-none text-hedertext ">
                  {langContent.Branches.Location}
                </Table.HeaderCell>
                <Table.HeaderCell className=" text-center border-none text-hedertext ">
                  {langContent.Branches.Container}
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            {data?.map((e) => (
              <Table.Body key={e._id}>
                <div className="my-2"></div>
                <Table.Row
                  loading={isLoading}
                  className="active:bg-subcolor text-subtext/60  "
                  onClick={() =>
                    history.push(
                      routes.Dashboard.Branches.BranchesDetailsArchive(e?.id)
                    )
                  }
                >
                  <Table.Cell className="text-center py-8 ltr:border-l-2 rtl:border-r-2 ltr:rounded-l-xl rtl:rounded-r-xl border-y-2 border-border">
                    <div className="flex md:contents justify-between mx-2 ">
                      <h1 className="text-hedertext bg-gray-50 w-32 p-4 rounded-lg md:hidden flex justify-center  ">
                        {langContent.Branches.branchـname}
                      </h1>
                      <p className="pt-4 text-maintext ">{e?.name}</p>
                    </div>
                  </Table.Cell>
                  <Table.Cell className="text-center py-8 border-y-2 border-border">
                    <div className="flex md:contents justify-between mx-2  ">
                      <h1 className="text-hedertext bg-gray-50  w-32 p-4  rounded-lg md:hidden flex justify-center">
                        {langContent.Branches.Location}
                      </h1>
                      <p className="pt-4 text-maintext ">{e?.area.name}</p>
                    </div>
                  </Table.Cell>
                  <Table.Cell className="text-center py-8 border-y-2 border-border">
                    <div className="flex md:contents justify-between mx-2 ">
                      <h1 className="text-hedertext bg-gray-50 w-32 p-4  rounded-lg md:hidden flex justify-center">
                        {langContent.Branches.Container}
                      </h1>
                      <div className="flex justify-center gap-3 pt-4 ">
                        <p className="pt-2 text-maintext "> {e?.containers}</p>
                      </div>
                    </div>
                  </Table.Cell>
                  <Table.Cell className="text-center py-8 ltr:border-r-2 rtl:border-l-2 ltr:rounded-r-xl rtl:rounded-l-xl  border-y-2 border-border">
                    {/* <Button
                      // loading={isLoading}
                      // onClick={unarchive}
                      className="bg-maincolor text-white rounded-full px-8 py-3 my-2"
                    >
                      restore
                    </Button> */}
                  </Table.Cell>
                </Table.Row>
                <div className="my-2"></div>
              </Table.Body>
            ))}
          </Table>
        </Segment>
      )}
    </div>
  );
};
export default BrachesArchiveTable;
