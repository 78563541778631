import React from "react";
import { Redirect, Route } from "react-router-dom";
import { Switch } from "react-router-dom";
import AnotherPhoneScreen from "../component/auth-component/Another-Phone-Screen";
import CreateNewPassword from "../component/auth-component/Create-New-Password";
import EmailphoneScreen from "../component/auth-component/Email-phone-Screen";
import OtpScreen from "../component/auth-component/OTP-Screen";
import WaitAdminApproval from "../component/auth-component/Wait-Admin-Approval";
import CompleteProfile from "../pages/app/Complete-profile/Complete-Profile";
import ShouldCompleteProfile from "../pages/app/Complete-profile/Should-Complete-Profile";
import ForgetPassword from "../pages/auth/Forget-Password";
import SignIn from "../pages/auth/Sign-In";
import SignUp from "../pages/auth/Sign-Up";
import routes from "../routes";

const AuthLayout = () => {
  return (
    <div>
      <Switch>
        <Route path={routes.auth.OtpSignUP} component={OtpScreen} />
        <Route
          path={routes.auth.AddAntherPhoneSignUP}
          component={AnotherPhoneScreen}
        />
        <Route path={routes.auth.enterEmail} component={EmailphoneScreen} />
        <Route path={routes.auth.WaitSignUP} component={WaitAdminApproval} />
        <Route path={routes.auth.SignIn} component={SignIn} />
        <Route exact path={routes.auth.SignUp} component={SignUp} />
        <Route exact path={routes.auth.ForgetPass} component={ForgetPassword} />
        <Route path={routes.auth.OtpForgetPass} component={OtpScreen} />
        <Route path={routes.auth.CreateNewPass} component={CreateNewPassword} />
        <Route
          exact
          path={routes.auth.completeprofile}
          component={ShouldCompleteProfile}
        />
        <Route
          path={routes.auth.completeprofileimg}
          component={CompleteProfile}
        />
      </Switch>
    </div>
  );
};

export default AuthLayout;
