import React from "react";
import { Button } from "semantic-ui-react";
import useFilter from "../../../lib/use-filter";

/**
 *
 * filter = [1st, '']
 * value = [1st-value, 2nd-value]
 * state = ['', 2nd-value] -> sent to setFilter
 */

function compare(filter, setFilter, value, index, isArray) {
  if (isArray) {
    console.log({ isArray, filter });
    console.log(filter[index] === value[index]);
    setFilter(
      filter[index] === value[index]
        ? filter.map((f, i) => (i === index ? "" : f))
        : filter.map((f, i) => (i === index ? value[i] : f))
    );
  } else {
    setFilter(filter === value ? "" : value);
  }
}

const ButtonFilter = ({
  name,
  label,
  value,
  index,
  isArray = false,
  initailValue = "",
  ...props
}) => {
  const [filter, setFilter] = useFilter(
    name,
    initailValue || (isArray ? [] : "")
  );

  // console.trace({ initailValue, filter });
  console.log({ filter, name, value });

  return (
    <Button
      onClick={() => compare(filter, setFilter, value, index, isArray)}
      basic={value[index] !== filter[index]}
      {...props}
    >
      {label}
    </Button>
  );
};
export default ButtonFilter;
