import React from "react";
import { Button, Header, Image, Modal } from "semantic-ui-react";
import api from "../../../../api";
import { authAxios } from "../../../../config/axios-config";
import { useLanguage } from "../../../../lib/language-context";
import useAxios from "../../../../lib/use-axios";
import { content } from "../../../../localization/content";

function Rejectmodel({ requestId, onReload, setSuccesstostreject }) {
  const [lang, setLang] = useLanguage("");
  const langContent = content[lang];
  const [open, setOpen] = React.useState(false);

  const { run, isLoading, data, isError, error } = useAxios({});

  const SentReject = () => {
    const body = {
      requestId: requestId,
    };
    run(authAxios.put(api.Dashboard.orders.setreject, body)).then(() => {
      setOpen(false);
      onReload();
      setSuccesstostreject(true);
    });
  };

  return (
    <Modal
      className="md:w-[30rem] md:h-[15rem] w-[25rem] h-[15rem] bg-white rounded-2xl"
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      closeIcon
      trigger={
        <button className="   w-56 h-14  py-2  md:my-10 my-2 rounded-full text-white bg-red-500 ">
          {lang === "en" ? "Reject" : "رفض"}
        </button>
      }
    >
      {/* edit heeeeeeeeeeeeeeeeeeeeer  */}
      <div className="">
        <div className="">
          <h1 className="text-2xl text-maintext text-center md:py-6 py-10 mx-4  ">
            {lang === "en"
              ? " Are you sure set this order request is Reject ?"
              : "هل أنت متأكد من رفض  هذا  الطلب ؟"}
          </h1>
          <span className="h-4 mt-1 text-red-600  flex justify-center text-sm   ">
            {lang === "en" ? error?.message?.en : error?.message?.ar}
          </span>
          <div className="flex justify-between mx-8 md:my-8 my-0">
            <Button
              onClick={() => {
                SentReject();
              }}
              className=" w-48 h-12 py-2  rounded-full text-white bg-red-500 font-serif"
            >
              {lang === "en" ? "Yes" : "نعم"}
            </Button>
            <button
              onClick={() => {
                setOpen(false);
              }}
              className="w-48 h-12 py-2  rounded-full text-maincolor border-2 border-maincolor"
            >
              {lang === "en" ? "close" : "أغلاق"}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default Rejectmodel;
