import React from "react";
import { Button, Modal } from "semantic-ui-react";
import { useLanguage } from "../../lib/language-context";
import { content } from "../../localization/content";

function Requestaneditmodel({ massg, open, onClose }) {
  React.useEffect(() => {}, [massg]);
  const [lang, setLang] = useLanguage("");
  const langContent = content[lang];

  return (
    <Modal
      className="w-[45rem] h-[16rem] bg-white rounded-3xl  scale-in"
      open={open}
      onClose={onClose}
      closeIcon
    >
      {/* edit heeeeeeeeeeeeeeeeeeeeeeeeer */}
      <div className="p-4">
        <p className="text-2xl text-textGray1 text-center pt-12">{massg}</p>
        <div className="flex justify-center pt-12">
          <Button
            basic
            color="green"
            onClick={onClose}
            className="w-48 h-14 bg-backgroundGray text-maincolor text-base font-medium rounded-xl ltr:font-serifEN rtl:font-serifAR "
          >
            {langContent.auth.confirm}
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default Requestaneditmodel;
