import React from "react";
import { AiOutlineCloudUpload } from "react-icons/ai";
import { HiOutlinePhotograph } from "react-icons/hi";
import { Button, Header, Image, Modal } from "semantic-ui-react";
import api from "../../../api";
import { authAxios } from "../../../config/axios-config";
import { useLanguage } from "../../../lib/language-context";
import useAxios from "../../../lib/use-axios";
import { content } from "../../../localization/content";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Avatar from "../../../component/common/Avatar/Avater";

function EditPicModel({ img, imglength, id, onReload, successs, name }) {
  const [open, setOpen] = React.useState(false);
  const [PhotoShow, SetPhotoShow] = React.useState(null);
  const [PhotoBackend, SetPhotoBackend] = React.useState(null);
  const [lang, setLang] = useLanguage("");
  const langContent = content[lang];

  const {
    run: runputProfileimg,
    isLoading: isLoadingputProfileimg,
    data: dataputProfileimg,
    error: errorputProfileimg,
  } = useAxios({});

  const putProfileImg = () => {
    const formData = new FormData();
    formData.append("image", PhotoBackend);
    debugger;
    runputProfileimg(
      authAxios.post(api.Dashboard.profile.putProfileImg(id), formData)
    ).then((res) => {
      onReload();
      setOpen(false);
      successs(
        lang === "en"
          ? "Provider imge added successfully"
          : " تمت إضافة صورة الموزع بنجاح"
      );
    });
  };

  return (
    <Modal
      className="w-[30rem] h-[20rem] bg-white"
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      trigger={
        imglength === 0 ? (
          <div className="w-[12rem] h-[12rem]  border-maincolor border-dashed  border-2 rounded-full  relative">
            <Button className="flex w-fitt text-white bg-maincolor rounded-full py-1 rtl:px-4 ltr:px-2 my-4 text-sm  absolute bottom-0 ltr:left-40  rtl:right-40 font-serif">
              {lang === "en" ? "Edit " : " تعديل"}
              <AiOutlineCloudUpload size={22} className="pb-1 mx-1" />
            </Button>
            {/* <div className="flex justify-center my-40 text-maincolor  cursor-pointer ">
              {langContent.Profile.Upload_Provide_image}
              <HiOutlinePhotograph size={25} className="" />
            </div> */}
            <Avatar
              name={name}
              styles={
                "flex justify-center w-full py-20 h-full rounded-full text-7xl text-maincolor bg-white  cursor-pointer "
              }
            />
          </div>
        ) : (
          <div className="w-[12rem] h-[12rem] border-maincolor border-dashed  border-2 rounded-full  relative">
            <Button className="flex w-fitt text-white bg-maincolor rounded-full py-1 rtl:px-4 ltr:px-2 my-4 text-sm  absolute bottom-0 ltr:left-28 rtl:right-28">
              {lang === "en" ? "Edit " : " تعديل"}
              <AiOutlineCloudUpload size={22} className="pb-1 mx-1" />
            </Button>
            <img
              className="w-full h-full object-cover  rounded-full"
              src={img}
              alt=""
            />
          </div>
        )
      }
    >
      <ToastContainer />
      {/* edit here */}
      <div className="w-full">
        <label className="border-maincolor border-2 border-dashed w-[12rem] h-[12rem]  flex rounded-full mx-auto my-2">
          <input
            onChange={(e) => {
              SetPhotoShow(URL?.createObjectURL(e?.target?.files[0]));
              SetPhotoBackend(e.target.files[0]);
            }}
            type="file"
            className="hidden"
          />
          {PhotoShow === null ? (
            <div className=" flex justify-center mx-auto   ">
              {imglength === 0 ? (
                <div className="flex  justify-center items-center text-maincolor  ">
                  {langContent.Profile.Upload_Provide_image}
                  <HiOutlinePhotograph size={25} className="mb-1" />
                </div>
              ) : (
                <div className="w-[11rem] h-[11rem]  mx-auto my-1">
                  <img
                    className="w-full h-full object-cover border-2  rounded-full"
                    src={img}
                    alt=""
                  />
                </div>
              )}
            </div>
          ) : (
            <div className=" w-[11rem] h-[11rem]  mx-auto my-1   ">
              <img
                className="w-full h-full object-cover border-2  rounded-full"
                src={PhotoShow}
                alt=""
              />
            </div>
          )}
        </label>
        <span className="flex justify-center text-sm text-red-600">
          {lang === "en"
            ? errorputProfileimg?.message?.en
            : errorputProfileimg?.message?.ar}
        </span>
        <div className="flex gap-10 mx-8">
          <Button
            onClick={() => {
              putProfileImg();
            }}
            loading={isLoadingputProfileimg}
            className=" w-full text-white bg-maincolor rounded-full py-2 px-4 my-4 text-xl "
          >
            {lang === "en" ? "Save" : "حفظ"}
          </Button>
          <button
            onClick={() => setOpen(false)}
            className=" w-full text-maincolor bg-white rounded-full py-2 px-4 my-4 text-xl border-2 border-maincolor"
          >
            {lang === "en" ? "Cancel" : "الغاء"}
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default EditPicModel;
