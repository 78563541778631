import React, { useState, useEffect } from "react";
import { Button } from "semantic-ui-react";
import useLocalStorage from "../../lib/use-localstorage";
import { getTimeFromSeconds } from "../../utils/time";

const Timer = ({
  canSendResendRequest,
  setCanSendResendRequest,
  className,
}) => {
  const [endTime, setEndTime] = useLocalStorage("endTime");

  const [remaining, setRemaining] = useState(0);
  const [allTimeData, setAllTimeData] = useState({});

  const stopTimer = () => {
    setCanSendResendRequest(true);
  };

  const createEndTime = (interval) => {
    let endTime = Date.now() + 120000;
    setEndTime(endTime);
    interval = setInterval(function () {
      const remaining = endTime - Date.now();
      if (remaining >= 0) {
        setRemaining(remaining);
      } else {
        window.localStorage.removeItem("endTime");
        stopTimer();
      }
    }, 1000);
  };

  useEffect(() => {
    let interval;
    if (!canSendResendRequest) {
      if (endTime)
        interval = setInterval(function () {
          const remaining = endTime - new Date();
          if (remaining >= 0) {
            setRemaining(remaining);
          } else {
            window.localStorage.removeItem("endTime");
            stopTimer();
          }
        }, 1000);
      else createEndTime(interval);
    }
    return () => {
      clearInterval(interval);
    };
  }, []);
  useEffect(() => {
    if (remaining > 0) {
      let tdata = getTimeFromSeconds(Number(remaining / 1000).toFixed(0));
      setAllTimeData(tdata);
    }
  }, [remaining]);

  return (
    <div>
      <Button
        className={`${className} bg-transparent text-white text-sm font-normal p-0 cursor-wait ltr:font-serifEN rtl:font-serifAR `}
      >
        <div className={`${className} flex gap-0.5 `}>
          <p className="pt-0.5">{allTimeData?.minutes}</p> :
          <p className="pt-0.5">{allTimeData?.seconds}</p>
        </div>
      </Button>
    </div>
  );
  // return <div>Timer</div>;
};

export default Timer;
