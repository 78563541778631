import React from "react";
import { Form, Formik } from "formik";
import { Button } from "semantic-ui-react";
import BranchNameLogo from "../../../src/assets/pic/branch-name-logo.svg";
import { content } from "../../localization/content";
import FormikInput from "../common/formik/formik-input";
import * as Yup from "yup";
import { useLanguage } from "../../lib/language-context";
import DropdownBranches from "./Drop-down-Branches";

const BranchesName = ({ SetBrachname }) => {
  const [lang, setLang] = useLanguage("");
  const langContent = content[lang];

  const onSubmit = (values) => {
    SetBrachname(values.Brach_name);
  };

  const registerSchema = Yup.object({
    Brach_name: Yup.string()
      .min(3, `${langContent.SignUpScreen.must_be_more_than}`)
      .max(25, `${langContent.SignUpScreen.name_must_be_20}`)
      .required(`${langContent.SignUpScreen.required}`),
  });

  return (
    <div className="max-w-7xl mx-auto">
      <div className=" max-w-5xl mx-auto rounded-3xl py-8  ">
        <img className=" mx-auto " src={BranchNameLogo} alt="" />
        <div className="w-96 mx-auto ">
          <h1 className=" font-bold text-2xl text-center py-16">
            {langContent.Branches.Please_branch}
          </h1>
          <div className="py-10">
            <Formik
              initialValues={{
                Brach_name: "",
              }}
              onSubmit={onSubmit}
              validationSchema={registerSchema}
            >
              {(formik) => (
                <Form onSubmit={formik.handleSubmit}>
                  <FormikInput
                    label={langContent.SignUpScreen.branch_name}
                    placeholder={langContent.SignUpScreen.branch_name}
                    name="Brach_name"
                  />
                  <div className="flex justify-between ">
                    <Button
                      // loading={"isLoading"}
                      className="w-full mx-auto py-4  font-sans my-4 rounded-3xl text-white bg-maincolor "
                      type="submit"
                    >
                      {langContent.SignUpScreen.next}
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BranchesName;
