import { Form, Formik } from "formik";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Button } from "semantic-ui-react";
import MainLogo from "../../../src/assets/pic/Main-Logo.svg";
import SignUpPic from "../../../src/assets/pic/Sign-Up-Pic.svg";
import api from "../../api";
import FormikInput from "../../component/common/formik/formik-input";
import * as Yup from "yup";
import { authAxios, axios } from "../../config/axios-config";
import { useLanguage } from "../../lib/language-context";
import useAxios from "../../lib/use-axios";
import { content } from "../../localization/content";
import routes from "../../routes";
import auth from "../../utils/auth";
import Timer from "../../component/common/timer";
import useLocalStorage from "../../lib/use-localstorage";
import Requestaneditmodel from "../../component/common/request-an-edit-model";

const SignIn = () => {
  const [lang, setLang] = useLanguage("");
  const langContent = content[lang];
  const history = useHistory();

  const [openAcceptModal, setOpenAcceptModal] = useState(false);
  const [sucssmsg, setSucssmsg] = useState();

  const [canSendResendRequest, setCanSendResendRequest] = useLocalStorage(
    "canSendResendRequest",
    true,
    undefined,
    (val) => JSON.parse(val)
  );

  const {
    run: runForget,
    isLoading: isLoadingForget,
    isError: isErrorForget,
    error: errForget,
    data: dataForget,
    status: statusForget,
  } = useAxios();

  const requestForget = () => {
    runForget(authAxios.get(api.criticalinfo.forgetPassword)).then((res) => {
      setSucssmsg(res?.data?.message);
      setOpenAcceptModal(true);
      setCanSendResendRequest(false);
    });
  };

  const { run, isLoading, isError, error, data, status } = useAxios();

  const Login = (values) => {
    const body = {
      phoneNumber: values.phoneNumber,
      password: values.password,
    };
    run(axios.post(api.auth.Login, body))
      .then((res) => {
        const { access_token, refresh_token } = res.data.data;
        auth.setToken({
          newAccessToken: access_token,
          newRefreshToken: refresh_token,
        });
        if (res?.data?.data?.status === "APPROVED") {
          history.push(routes.Dashboard.Branches.ActiveBranches);
          window.localStorage.setItem("providerId", res?.data?.data?.id);
        }
        if (res?.data?.data?.status === "PENDING_APPROVAL") {
          history.push(routes.auth.WaitSignUP);
          window.localStorage.setItem("providerId", res?.data?.data?.id);
        }
        if (res?.data?.data?.status === "PENDING_PROFILE_COMPLETE") {
          history.push(routes.auth.completeprofile, {
            Id: res?.data?.data?.id,
            name: res?.data?.data?.name,
            img: res?.data?.data?.image,
            supplierCode: res?.data?.data?.supplierCode,
          });
        }
      })
      .catch((err) => {});
  };

  const registerSchema = Yup.object({
    phoneNumber: Yup.string()
      .min(3, `${langContent.SignUpScreen.you_must_start_with} + 20`)
      .max(20, `${langContent.SignUpScreen.must_be_20}`)
      .required(`${langContent.SignUpScreen.required}`),
    password: Yup.string()
      .min(8, `${langContent.SignUpScreen.password_must_be_8}`)
      .max(20, `${langContent.SignUpScreen.password_must_be_20}`)
      .required(`${langContent.SignUpScreen.required}`),
  });

  return (
    // bg
    <div className="mx-auto w-full h-full fixed  text-maintext  transition-all  ease-in-out  delay-100 bg-backgroundGray">
      {/* sign in */}
      <div className="max-w-7xl h-[45rem] absolute my-auto mx-auto left-0 right-0 top-[50%] bottom-[50%]">
        <img
          className="absolute z-10 -top-5 md:left-[45%]  left-[37%]  w-32 h-32  rounded-full mx-auto bg-white p-4"
          src={MainLogo}
          alt="logo"
        />
        <div className=" max-w-7xl h-[45rem]  overflow-hidden  rounded-3xl  bg-white absolute my-auto mx-auto left-0 right-0  top-[55%] bottom-[50%] text-center ">
          {/* content in div left and right */}
          <div className=" flex justify-between rtl:flex-col-reverse mx-24 ">
            {/* left section */}
            <div className=" md:flex hidden ">
              <img
                className="max-w-4xl  absolute left-[-20%] top-[-16%]  "
                src={SignUpPic}
                alt="authlogo"
              />
              <div className="absolute flex flex-col justify-center top-[30%] left-[25%] text-white">
                <h1 className="  pt-6 pb-3  font-serif text-center mx-auto text-2xl font-bold">
                  {langContent.SignInScreen.hello}
                  <p>_______</p>
                </h1>
                <p className="   px-1">
                  {langContent.SignInScreen.not_a_member}
                  <span className="text-white py-4 px-1">
                    {langContent.SignInScreen.create_an_account}
                    <br></br>
                    {langContent.SignInScreen.to_continue}
                  </span>
                </p>
                <button
                  onClick={() => {
                    history.push(routes.auth.SignUp);
                  }}
                  className="  mx-auto border-2 w-28 my-4 py-2 rounded-3xl"
                >
                  {langContent.SignInScreen.sign_up}
                </button>
              </div>
            </div>
            {/* right section */}
            <div className="py-14 my-28 md:w-fit w-full mx-auto md:mx-0">
              <h1 className="text-xl font-bold font-serif ">
                {langContent.SignInScreen.sign_in}
              </h1>
              <p className="text-sm my-4">
                {langContent.SignInScreen.lets_get_start_now}
              </p>
              <Formik
                initialValues={{
                  phoneNumber: "",
                  password: "",
                }}
                onSubmit={Login}
                validationSchema={registerSchema}
              >
                {(formik) => (
                  <Form onSubmit={formik.handleSubmit}>
                    <FormikInput
                      dir="ltr"
                      label={langContent.SignUpScreen.phone_number}
                      placeholder={langContent.SignUpScreen.phone_number}
                      name="phoneNumber"
                    />

                    <FormikInput
                      dir="ltr"
                      label={langContent.SignUpScreen.password}
                      placeholder={langContent.SignUpScreen.password}
                      name="password"
                      type="password"
                    />
                    {/* <p
                      onClick={() => {
                        // history.push(routes.auth.ForgetPass);
                      }}
                      className="text-maincolor flex justify-end text-xs py-1 cursor-pointer"
                    >
                      {langContent.SignInScreen.i_cant_remember_my_password}
                    </p> */}

                    {/*  */}
                    {canSendResendRequest ? (
                      <Button
                        loading={isLoadingForget}
                        as="div"
                        onClick={() => {
                          history.push(routes.auth.enterEmail);
                        }}
                        className="text-maincolor bg-transparent w-fit flex  text-xs py-1 cursor-pointer font-normal p-0 rtl:font-serifAR ltr:font-serifEN"
                      >
                        {langContent.SignInScreen.i_cant_remember_my_password}
                      </Button>
                    ) : (
                      <div className=" flex justify-end ">
                        <Timer
                          canSendResendRequest={canSendResendRequest}
                          setCanSendResendRequest={setCanSendResendRequest}
                        />
                      </div>
                    )}
                    {/*  */}

                    <span className="h-4 mt-1 text-red-600 text-left text-sm flex  ">
                      {lang === "en" ? error?.message?.en : error?.message?.ar}
                    </span>

                    <Button
                      loading={isLoading}
                      // onClick={Login}
                      className="md:w-96 py-4 font-serif mx-auto w-full my-2 rounded-3xl text-white bg-maincolor  "
                    >
                      {langContent.SignInScreen.sign_in}
                    </Button>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
        {/* logo  */}
        {openAcceptModal && (
          <Requestaneditmodel
            open={openAcceptModal}
            onClose={() => setOpenAcceptModal(false)}
            massg={lang === "en" ? sucssmsg.en : sucssmsg.ar}
          />
        )}
      </div>
    </div>
  );
};

export default SignIn;
