import React from "react";
import { Dropdown } from "semantic-ui-react";
import useFilter from "../../lib/use-filter";

const DropdownFilter = ({
  name,
  label,
  options = [],
  defaultValue = "",
  onChangeCB = () => null,
  ...props
}) => {
  const [filter, setFilter] = useFilter(name, defaultValue);

  React.useEffect(() => {
    setFilter(defaultValue);
  }, []);

  return (
    <Dropdown
      value={filter}
      placeholder={label}
      selection
      options={options}
      onChange={(_, { value }) => {
        setFilter(value);
        onChangeCB(value);
      }}
      clearable
      {...props}
      className="rounded-xl  "
    />
  );
};

export default DropdownFilter;
