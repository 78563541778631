import React, { useState } from "react";
import MainLogo from "../../../src/assets/pic/Main-Logo.svg";
import { useLanguage } from "../../lib/language-context";
import { content } from "../../localization/content";
import useAxios from "../../lib/use-axios";
import { axios } from "../../config/axios-config";
import api from "../../api";
import { Button } from "semantic-ui-react";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import FormikInput from "../common/formik/formik-input";
import Timer from "../common/timer";
import useLocalStorage from "../../lib/use-localstorage";
import Requestaneditmodel from "../common/request-an-edit-model";

const EmailphoneScreen = () => {
  const [lang, setLang] = useLanguage("");
  const langContent = content[lang];
  const [openAcceptModal, setOpenAcceptModal] = useState(false);
  const [sucssmsg, setSucssmsg] = useState();

  const [canSendResendRequest, setCanSendResendRequest] = useLocalStorage(
    "canSendResendRequest",
    true,
    undefined,
    (val) => JSON.parse(val)
  );

  const { run, isLoading, isError, error, data } = useAxios();

  const registerSchema = Yup.object({
    email: Yup.string()
      .email(`${langContent.SignUpScreen.email_not_valid}`)
      .required(`${langContent.SignUpScreen.required}`)
      .trim(),
  });

  const sentEmail = (values) => {
    const body = {
      email: values?.email,
    };
    run(axios.post(api.auth.sendforgetpassword, body))
      .then((res) => {
        setCanSendResendRequest(false);
        setOpenAcceptModal(true);
        setSucssmsg(res?.data?.data?.message);
      })
      .catch((err) => {});
  };
  return (
    // bg
    <div className="mx-auto w-full h-full fixed  transition-all ease-in-out delay-100  bg-backgroundGray">
      {/* AnotherPhoneScreen  */}
      <div className="max-w-7xl  h-[45rem] absolute my-auto mx-auto left-0 right-0  top-[50%] bottom-[50%]">
        <img
          className="absolute z-10 -top-5 left-[45%]  w-32 h-32  rounded-full mx-auto bg-white p-4"
          src={MainLogo}
          alt="logo"
        />
        <div className=" max-w-7xl h-[45rem]  overflow-hidden  rounded-3xl  bg-white absolute my-auto mx-auto left-0 right-0  top-[55%] bottom-[50%]">
          <div className="flex flex-col justify-center my-44">
            <h1 className="text-2xl font-sans mb-10 text-center my-11">
              {langContent.AnotherPhoneScreen.enterEmail}
              <br></br>
              {/* {langContent.AnotherPhoneScreen.to_login_on_ezaha} */}
            </h1>
            <div className="pt-6 w-96 mx-auto">
              <Formik
                initialValues={{
                  email: "",
                }}
                onSubmit={sentEmail}
                validationSchema={registerSchema}
              >
                {(formik) => (
                  <Form onSubmit={formik.handleSubmit}>
                    <FormikInput
                      dir="ltr"
                      placeholder={langContent.AnotherPhoneScreen.email}
                      label={langContent.AnotherPhoneScreen.email}
                      name="email"
                    />
                    <span className="h-4 mt-1 text-red-600 text-left text-sm flex  ">
                      {lang === "en" ? error?.message?.en : error?.message?.ar}
                    </span>
                    <div className="flex justify-between">
                      {canSendResendRequest ? (
                        <Button
                          loading={isLoading}
                          className="w-full mx-auto py-4 font-sans  my-4 rounded-3xl text-white bg-maincolor "
                          type="submit"
                        >
                          {langContent.AnotherPhoneScreen.continue}
                        </Button>
                      ) : (
                        <Timer
                          className={
                            "flex justify-center w-full py-1 font-sans  px-20 mx-1 text-xl rounded-3xl text-white bg-maincolor  "
                          }
                          canSendResendRequest={canSendResendRequest}
                          setCanSendResendRequest={setCanSendResendRequest}
                        />
                      )}
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
      {openAcceptModal && (
        <Requestaneditmodel
          open={openAcceptModal}
          onClose={() => setOpenAcceptModal(false)}
          massg={lang === "en" ? sucssmsg?.en : sucssmsg?.ar}
        />
      )}
    </div>
  );
};

export default EmailphoneScreen;
