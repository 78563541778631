import React, { useEffect, useState } from "react";
import { BsBoxSeam } from "react-icons/bs";
import { FaShippingFast } from "react-icons/fa";
import { useHistory, useLocation } from "react-router-dom";
import { Segment, Table } from "semantic-ui-react";
import api from "../../api";
import { authAxios } from "../../config/axios-config";
import useAxios from "../../lib/use-axios";
import routes from "../../routes";
import Status from "../common/status/status";
import Dropdownlistorder from "./Dropdown-list-order";
import OrdersFilterModel from "./Active-Orders-Filter-Model";
import useFilter from "../../lib/use-filter";
import DropdownFilter from "../common/DropdownFilter";
import emtystat from "../../../src/assets/pic/emty-stat-orders.svg";
import { useLanguage } from "../../lib/language-context";
import { content } from "../../localization/content";
import Paginationtable from "../common/Pagination/Pagination";

const ActiveOrdersTable = () => {
  const [lang, setLang] = useLanguage("");
  const langContent = content[lang];
  const history = useHistory();
  const state_0 = "AWAITING_DELIVERY";
  const state_1 = "DELIVERED_ACTIVE";
  const { search, pathname } = useLocation();
  const [pagination, SetPagination] = useState();
  const [forceReload, setForceReload] = React.useState(false);
  const onReload = React.useCallback(() => setForceReload((p) => !p), []);
  const { run, isLoading, data, isError, error } = useAxios({});
  const [status] = useFilter("status");
  const [page] = useFilter("page");
  const [perPage] = useFilter("perPage");
  const [sort] = useFilter("sort");

  useEffect(() => {
    if (page && perPage && sort) {
      run(authAxios.get(`${api.Dashboard.orders.active}${search}`)).then(
        (res) => {
          SetPagination(res?.data?.pagination);
        }
      );
    }
  }, [run, search, forceReload, page, perPage, sort]);

  const transletContainerSiz = (size) => {
    if (size === "TINY") {
      return lang === "en" ? "Six yards" : " ستة ياردة";
    }
    if (size === "SMALL") {
      return lang === "en"
        ? "Ten or Twelve yards "
        : " عشرة او اثنتا عشر ياردة";
    }
    if (size === "MEDIUM") {
      return lang === "en" ? "Twenty yards" : " عشرون ياردة";
    }
    if (size === "LARGE") {
      return lang === "en" ? "Thrity yards" : " تلاثون ياردة";
    }
  };

  return (
    <div>
      <Segment
        className="border-none shadow-none p-0 m-0 -z-1"
        loading={isLoading}
      >
        <div className="flex gap-5 justify-end">
          <span className="text-xl text-maintext py-2.5 hidden md:flex">
            {langContent.Orders.Sort}
          </span>
          <div className="flex  mx-auto md:mx-0">
            <div>
              <Dropdownlistorder />
            </div>
            <div className="h-9 mx-2">
              <OrdersFilterModel
                status_one={"AWAITING_DELIVERY"}
                status_two={"DELIVERED_ACTIVE"}
                lable_one={langContent.Orders.Ontheway}
                lable_two={langContent.Orders.Arrived}
                onReload={onReload}
                cancel={`?`}
              />
            </div>
          </div>
        </div>
        <Table className="" basic="very">
          {data?.length === 0 ? (
            <div className="my-6">
              <img className="mx-auto " src={emtystat} alt="" />
              <p className="text-maincolor font-bold text-6xl text-center my-24">
                {lang === "en"
                  ? "There are no History orders"
                  : "لا يوجد طلبات نشطة الآن   "}
              </p>
            </div>
          ) : (
            <Table.Header className="hidden md:contents">
              <Table.Row>
                <Table.HeaderCell className=" text-center border-none text-hedertext font-medium">
                  {langContent.Orders.Order_ID}
                </Table.HeaderCell>
                <Table.HeaderCell className=" text-center border-none text-hedertext font-medium">
                  {langContent.Orders.Quantity}
                </Table.HeaderCell>
                <Table.HeaderCell className=" text-center border-none text-hedertext font-medium">
                  {langContent.Orders.Status}
                </Table.HeaderCell>
                <Table.HeaderCell className=" text-center border-none text-hedertext font-medium">
                  {langContent.Orders.contanersize}
                </Table.HeaderCell>
                <Table.HeaderCell className=" text-center border-none text-hedertext font-medium">
                  {langContent.Orders.deliverydate}
                </Table.HeaderCell>
                <Table.HeaderCell className=" text-center border-none text-hedertext font-medium">
                  {langContent.Orders.Regular_pickup_date}
                </Table.HeaderCell>
                <Table.HeaderCell className=" text-center border-none text-hedertext font-medium">
                  {langContent.Orders.Address}
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
          )}

          {data?.map((e) => (
            <Table.Body key={e?.id}>
              <div className="my-2"></div>
              <Table.Row
                className="active:bg-subcolor"
                onClick={() =>
                  history.push(
                    routes.Dashboard.Orders.DetailsActiveOrders(e?.id)
                  )
                }
              >
                <Table.Cell className="text-center py-8 ltr:border-l-2 rtl:border-r-2 ltr:rounded-l-xl rtl:rounded-r-xl border-y-2 border-border">
                  <div className="flex justify-between">
                    <h1 className="text-hedertext bg-gray-50 w-32 p-4 rounded-lg md:hidden flex justify-center  ">
                      {langContent.Orders.Order_ID}
                    </h1>
                    <div className="flex justify-center gap-4 mx-2 py-4 md:py-0">
                      <p className="text-maincolor ">
                        {e?.status === "AWAITING_DELIVERY" ? (
                          <FaShippingFast size={20} />
                        ) : (
                          <BsBoxSeam size={20} />
                        )}
                      </p>
                      <p>{e?.id}</p>
                    </div>
                  </div>
                </Table.Cell>
                <Table.Cell className="text-center py-8 border-y-2 border-border">
                  <div className="flex justify-between  md:block">
                    <h1 className="text-hedertext bg-gray-50 w-32 p-4 rounded-lg md:hidden flex justify-center  ">
                      {langContent.Orders.Quantity}
                    </h1>
                    <p className="py-4 md:py-0">{e?.numberOfContainers}</p>
                  </div>
                </Table.Cell>
                <Table.Cell className="text-center py-8 border-y-2 border-border">
                  <div className="flex justify-between  md:block">
                    <h1 className="text-hedertext bg-gray-50 w-32 p-4 rounded-lg md:hidden flex justify-center  ">
                      {langContent.Orders.Status}
                    </h1>
                    <p className="py-4 md:py-0">
                      <Status statusOrder={e?.status} />
                    </p>
                  </div>
                </Table.Cell>
                <Table.Cell className="text-center py-8 border-y-2 border-border">
                  <div className="flex justify-between  md:block">
                    <h1 className="text-hedertext bg-gray-50 w-32 p-4 rounded-lg md:hidden flex justify-center  ">
                      {langContent.Orders.contanersize}
                    </h1>
                    <p className="py-4 md:py-0">
                      {transletContainerSiz(e?.container?.size)}
                    </p>
                  </div>
                </Table.Cell>
                <Table.Cell className="text-center py-8 border-y-2 border-border">
                  <div className="flex justify-between  md:block">
                    <h1 className="text-hedertext bg-gray-50 w-32 p-4 rounded-lg md:hidden flex justify-center  ">
                      {langContent.Orders.deliverydate}
                    </h1>
                    <p className="py-4 md:py-0">
                      {new Date(e?.deliveryDate).toLocaleDateString("en-GB")}
                    </p>
                  </div>
                </Table.Cell>
                <Table.Cell className="text-center py-8 border-y-2 border-border">
                  <div className="flex justify-between  md:block">
                    <h1 className="text-hedertext bg-gray-50 w-32 p-4 rounded-lg md:hidden flex justify-center  ">
                      {langContent.Orders.Regular_pickup_date}
                    </h1>
                    <p className="py-4 md:py-0">
                      {new Date(e?.expairyDate).toLocaleDateString("en-GB")}
                    </p>
                  </div>
                </Table.Cell>
                <Table.Cell className="text-center py-8 ltr:border-r-2 rtl:border-l-2 ltr:rounded-r-xl rtl:rounded-l-xl  border-y-2 border-border">
                  <div className="flex justify-between  md:block">
                    <h1 className="text-hedertext bg-gray-50 w-32 p-4 rounded-lg md:hidden flex justify-center  ">
                      {langContent.Orders.Address}
                    </h1>
                    <p className="py-4 md:py-0">{e?.address?.area?.name}</p>
                  </div>
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          ))}
        </Table>
        <div
          className={`${
            data?.length === 0 && "hidden"
          } flex flex-row-reverse edit_for_Pagination`}
        >
          <Paginationtable totalPages={pagination?.totalPages} />
        </div>
      </Segment>
    </div>
  );
};
export default ActiveOrdersTable;
