import React from "react";
import { DiGitBranch } from "react-icons/di";
import { BiErrorAlt, BiLogOut } from "react-icons/bi";
import { MdOutlineNotifications, MdOutlineReviews } from "react-icons/md";
import { RiSpace } from "react-icons/ri";
import Containericon from "../../../../src/assets/pic/Container-icon-2.svg";
import { BsBoxSeam } from "react-icons/bs";
import MainLogo from "../../../../src/assets/pic/main-logo-text.svg";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import routes from "../../../routes";
import { useLanguage } from "../../../lib/language-context";
import { content } from "../../../localization/content";
import { Dropdown, Icon } from "semantic-ui-react";
import { TiUserOutline } from "react-icons/ti";
import Sidebarmob from "./Side-bar-mob";
import { GiHamburgerMenu } from "react-icons/gi";
import { MdClose } from "react-icons/md";
import { useAuthState } from "../../../context/auth-context";

const SideBar = () => {
  const [lang, setLang] = useLanguage("");
  const langContent = content[lang];
  const history = useHistory();
  const { pathname } = useLocation();

  const { logout } = useAuthState();

  const [sid, SetSid] = React.useState(false);
  const HandleSid = () => {
    SetSid(!sid);
  };
  return (
    <div>
      <div className=" lg:flex hidden   ">
        <div className=" flex flex-col justify-between h-screen z-50 bg-maincolor w-64 text-white fixed    ">
          <div className="  text-2xl  ">
            <div className=" pt-10 pb-2 px-2 border-b-2 border-selectedcolor/30 flex justify-start  ">
              <img className=" w-32 mx-2 " src={MainLogo} alt="MainLogo" />
            </div>
            <ul className=" ">
              <li
                className={`    ${
                  pathname.startsWith(routes.Dashboard.Branches.default)
                    ? "bg-selectedcolor/30 text-white ltr:border-l-2 rtl:border-r-2 border-white  "
                    : ""
                }  mt-16`}
              >
                <Link
                  to={routes.Dashboard.Branches.ActiveBranches}
                  className=" flex py-2 my-6 text-xl font-bold "
                >
                  <DiGitBranch className="   mx-4 text-2xl " />
                  <p>{langContent.SideBar.branches}</p>
                </Link>
              </li>
              {/*  */}
              <li
                className={`    ${
                  pathname.startsWith(routes.Dashboard.Notifications.default)
                    ? "bg-selectedcolor/30 text-white ltr:border-l-2 rtl:border-r-2 border-white "
                    : ""
                } `}
              >
                <Link
                  to={routes.Dashboard.Notifications.default}
                  className="flex py-2 my-6  text-xl font-bold "
                >
                  <MdOutlineNotifications className="mt-1 mx-4 text-2xl " />
                  {langContent.Notifications.Notifications}
                  <p> </p>
                </Link>
              </li>

              <li
                className={`    ${
                  pathname.startsWith(routes.Dashboard.Orders.default)
                    ? "bg-selectedcolor/30 text-white ltr:border-l-2 rtl:border-r-2 border-white "
                    : ""
                } `}
              >
                <Link
                  to={routes.Dashboard.Orders.TodayOrders}
                  className="flex py-2 my-6  text-xl font-bold "
                >
                  <BsBoxSeam className="mt-1 mx-4  text-2xl " />
                  <p>{langContent.SideBar.orders}</p>
                </Link>
              </li>
              {/* <li
                className={`    ${
                  pathname.startsWith(routes.Dashboard.Report.default)
                    ? "bg-selectedcolor/30 text-white ltr:border-l-2 rtl:border-r-2 border-white "
                    : ""
                } `}
              >
                <Link
                  to={routes.Dashboard.Report.default}
                  className="flex py-2 my-6  text-xl font-bold "
                >
                  <BiErrorAlt className="mt-1 mx-4 text-2xl " />
                  <p> Reports </p>
                </Link>
              </li> */}
              <li
                className={`    ${
                  pathname.startsWith(routes.Dashboard.Reviews.default)
                    ? "bg-selectedcolor/30 text-white ltr:border-l-2 rtl:border-r-2 border-white "
                    : ""
                } `}
              >
                <Link
                  to={routes.Dashboard.Reviews.default}
                  className="flex py-2 my-6  text-xl font-bold "
                >
                  <MdOutlineReviews className="mt-1 mx-4 text-2xl " />
                  <p> {langContent.Reviews.Reviews} </p>
                </Link>
              </li>
            </ul>
          </div>
          {/*  under side bare lang*/}
          <div className="px-4">
            <div className="border-b-2 border-selectedcolor/30 ">
              <Dropdown
                trigger={
                  <div className=" flex  px-2 text-xl  ">
                    <Icon name="world" />
                    <span className="capitalize  px-1 ">
                      {lang === "en" ? "Language" : "اللغة"}
                    </span>
                  </div>
                }
              >
                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={() => {
                      setLang("en");
                      window.location.reload();
                    }}
                  >
                    {lang === "en" ? "English" : "الانجليزية"}
                  </Dropdown.Item>
                  <hr />
                  <Dropdown.Item
                    onClick={() => {
                      setLang("ar");
                      window.location.reload();
                    }}
                  >
                    {lang === "en" ? "Arabic" : "العربية"}
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div
              className={`   ${
                pathname.startsWith(routes.Dashboard.Profile.default)
                  ? "text-[#B18200] "
                  : ""
              } mt-4 `}
            >
              <Link to={routes.Dashboard.Profile.default}>
                <div className=" py-2 flex  cursor-pointer ">
                  <TiUserOutline size={25} />
                  <p className="mt-1 mx-2  mb-5">
                    {langContent.Profile.Profile}
                  </p>
                </div>
              </Link>
            </div>
            <div>
              <Link to={routes.auth.SignIn}>
                <button
                  onClick={() => {
                    logout();
                  }}
                  className=" py-2 flex  cursor-pointer "
                >
                  <BiLogOut size={25} />
                  <p className="mt-1 mx-2  mb-10">
                    {langContent.Profile.logout}
                  </p>
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className=" w-full">
        <div
          className="  flex lg:hidden pt-2 bg-maincolor w-fit m-4 py-2 px-2 rounded-lg  cursor-pointer"
          onClick={HandleSid}
        >
          {sid ? (
            <MdClose size={25} className="text-white " />
          ) : (
            <GiHamburgerMenu size={25} className="text-white " />
          )}
        </div>
        <ul
          className={
            sid
              ? "fixed z-[100] ltr:left-0 top-0 rtl:right-0 w-full h-full ease-in-out duration-500"
              : "ease-in-out duration-500 fixed ltr:left-[-100%] rtl:right-[-100%]  "
          }
        >
          <div className="flex justify-between">
            <Sidebarmob
              handelSid={() => {
                HandleSid();
              }}
            />
            <button
              onClick={HandleSid}
              className="bg-white/90 w-full h-screen"
            ></button>
          </div>
        </ul>
      </div>
    </div>
  );
};

export default SideBar;
