import React from "react";

const Navlinkedit = ({ title, onClick, isActive }) => {
  return (
    <div
      onClick={onClick}
      className="w-[18.3rem] overflow-hidden cursor-pointer"
    >
      <div
        className={`${
          isActive
            ? "relative ltr:bg-gradient-to-r rtl:bg-gradient-to-l from-maincolor/50 px-11 text-maintext"
            : "px-11 text-textGray1"
        }`}
      >
        <div
          className={`${
            isActive
              ? "z-1 absolute ltr:-left-3 rtl:-right-3  h-full w-6 rounded-3xl bg-maincolor"
              : ""
          }`}
        ></div>
        <div className="flex gap-5 py-4">
          <p>{title}</p>
        </div>
      </div>
    </div>
  );
};

export default Navlinkedit;
