import React, { useState } from "react";
import { Formik } from "formik";
import { useHistory } from "react-router-dom";
import { Button, Dropdown, Form, Icon } from "semantic-ui-react";
import * as Yup from "yup";
import MainLogo from "../../../src/assets/pic/Main-Logo.svg";
import SignUpPic from "../../../src/assets/pic/Sign-Up-Pic.svg";
import api from "../../api";
import FormikInput from "../../component/common/formik/formik-input";
import { axios } from "../../config/axios-config";
import { useLanguage } from "../../lib/language-context";
import useAxios from "../../lib/use-axios";
import { content } from "../../localization/content";
import routes from "../../routes";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import KSA from "../../../src/assets/pic/saudi-arabia.png";

const SignUp = () => {
  const [lang, setLang] = useLanguage("");
  const langContent = content[lang];
  const [otpid, SetOtpd] = useState("");
  const history = useHistory();
  const { run, isLoading, isError, error, data, status } = useAxios();

  const success = () =>
    toast.success(" تم اعتماد هذا المستخدم", {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  const err = () =>
    toast.error(" تم رفض هذا المستخدم", {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const registerSchema = Yup.object({
    provider_name: Yup.string()
      .min(3, `${langContent.SignUpScreen.must_be_more_3}`)
      .max(25, `${langContent.SignUpScreen.name_must_be_20}`)
      .required(`${langContent.SignUpScreen.required}`),
    email: Yup.string()
      .email(`${langContent.SignUpScreen.email_not_valid}`)
      .required(`${langContent.SignUpScreen.required}`)
      .trim(),
    password: Yup.string()
      .min(8, `${langContent.SignUpScreen.password_must_be_8}`)
      .max(20, `${langContent.SignUpScreen.password_must_be_20}`)
      .required(`${langContent.SignUpScreen.required}`),
    confirmPassword: Yup.string()
      .oneOf(
        [Yup.ref("password"), null],
        `${langContent.SignUpScreen.not_match}`
      )
      .required(`${langContent.SignUpScreen.required}`),
    licenceNumber: Yup.string()
      .required(`${langContent.SignUpScreen.required}`)
      .min(3, `${langContent.SignUpScreen.must_be_3}`),
    commericalNumber: Yup.string()
      .required(`${langContent.SignUpScreen.required}`)
      .min(3, `${langContent.SignUpScreen.must_be_3}`),
    mainPhoneNumber: Yup.string()
      .required(`${langContent.SignUpScreen.you_must_start_with} + 966`)
      .min(3, `${langContent.SignUpScreen.must_be_3}`),
  });

  // const SentOTP = (values) => {
  //   const body = {
  //     phoneNumber: values.mainPhoneNumber,
  //   };
  //   history.push(routes.auth.AddAntherPhoneSignUP, {
  // OtpId: res.data.data.otpResponse.otp_id,
  // values,

  // history.push(routes.auth.OtpSignUP, {
  //   OtpId: res.data.data.otpResponse.otp_id,
  //   values,
  // });

  // run(axios.post(api.auth.SentOtp, body))
  //   .then((res) => {
  //   })
  //   .catch((err) => {});
  // };

  const register = (values) => {
    const body = {
      provider_name: values?.provider_name,
      email: values?.email,
      password: values?.password,
      licenceNumber: values?.licenceNumber,
      commericalNumber: values?.commericalNumber,
      mainPhoneNumber: values?.mainPhoneNumber,
    };
    run(axios.post(api.auth.SignUp, body))
      .then((res) => {
        history.push(routes.auth.SignIn, {
          // Id: res?.data?.data?.id,
          // name: res.data?.data?.name,
          // img: res?.data?.data?.image,
          // supplierCode: res?.data?.data?.supplierCode,
        });
      })
      .catch((err) => {});
  };

  return (
    // bg
    <div className="  mx-auto w-full h-full  text-maintext transition-all ease-in-out delay-100  bg-backgroundGray font-serif">
      {/* sign up  */}
      <ToastContainer />
      <div className="max-w-7xl  h-[45rem] absolute my-auto mx-auto left-0 right-0  top-[50%] bottom-[50%]">
        <img
          className="absolute z-10 md:-top-5 -top-12 md:left-[45%]  left-[36%] w-32 h-32   rounded-full mx-auto bg-white p-4"
          src={MainLogo}
          alt="logo"
        />
        <div className="max-w-7xl  md:h-[45rem] h-[50rem]  overflow-hidden  rounded-3xl  bg-white absolute my-auto mx-auto left-0 right-0  top-[55%] bottom-[50%]">
          {/* content in div left and right */}
          <div className=" flex justify-between  rtl:flex-col-reverse   mx-24 ">
            {/* left section */}
            <div className=" md:flex hidden ">
              <img
                className="max-w-4xl  absolute left-[-20%] top-[-16%]"
                src={SignUpPic}
                alt="authlogo"
              />
              <div className="absolute font-serif flex flex-col justify-center top-[30%] left-[25%] text-white">
                <h1 className=" py-6 font-serif  text-center mx-auto text-2xl font-bold">
                  {langContent.SignUpScreen.hello_friend}
                </h1>
                <p className="border-t-2 mx-8 "></p>
                <p className=" pt-6 text-center">
                  {langContent.SignUpScreen.already_have_an_account}
                </p>
                <p className="text-white pb-6 text-center">
                  {langContent.SignUpScreen.sign_in_to_continue}
                </p>
                <div className="flex gap-4 ">
                  <button
                    onClick={() => {
                      history.push(routes.auth.SignIn);
                    }}
                    className="  mx-auto border-2 w-28 py-2 rounded-3xl"
                  >
                    {langContent.SignUpScreen.sign_in}
                  </button>
                </div>
              </div>
            </div>
            {/* right section */}
            <div className="py-4  md:mt-0  mt-20 w-fit  mx-auto md:mx-0 ">
              <h1 className="text-2xl font-bold font-serif text-center ">
                {langContent.SignUpScreen.sign_up}
              </h1>
              <p className="text-sm text-center">
                {langContent.SignUpScreen.create_an_account_to_continue}
              </p>

              <Formik
                initialValues={{
                  provider_name: "",
                  email: "",
                  password: "",
                  confirmPassword: "",
                  licenceNumber: "",
                  commericalNumber: "",
                  mainPhoneNumber: "",
                }}
                onSubmit={register}
                validationSchema={registerSchema}
              >
                {(formik) => (
                  <Form onSubmit={formik.handleSubmit}>
                    <FormikInput
                      label={langContent.SignUpScreen.user_name}
                      placeholder={langContent.SignUpScreen.user_name}
                      name="provider_name"
                    />

                    <FormikInput
                      label={langContent.SignUpScreen.email}
                      placeholder={langContent.SignUpScreen.email}
                      name="email"
                    />
                    <FormikInput
                      label={langContent.SignUpScreen.password}
                      placeholder={langContent.SignUpScreen.password}
                      name="password"
                      type="password"
                    />

                    <FormikInput
                      label={langContent.SignUpScreen.confirm_password}
                      placeholder={langContent.SignUpScreen.confirm_password}
                      licnse_number
                      name="confirmPassword"
                      type="password"
                    />
                    <div className="flex gap-8">
                      <FormikInput
                        label={langContent.SignUpScreen.licnse_number}
                        placeholder={langContent.SignUpScreen.licnse_number}
                        name="licenceNumber"
                      />
                      <FormikInput
                        label={langContent.SignUpScreen.Commerical_number}
                        placeholder={langContent.SignUpScreen.Commerical_number}
                        name="commericalNumber"
                      />
                    </div>
                    <div className="flex ">
                      <img className="w-10 h-10 mt-8 mx-2 " src={KSA} alt="" />
                      <div className="w-full">
                        <FormikInput
                          dir="ltr"
                          label={langContent.SignUpScreen.phone_number}
                          placeholder={langContent.SignUpScreen.phone_number}
                          name="mainPhoneNumber"
                        />
                      </div>
                    </div>

                    <span className="h-4 mt-1 text-red-600 text-left text-sm flex  ">
                      {lang === "en" ? error?.message?.en : error?.message?.ar}
                    </span>
                    <span className="h-4 mt-1 text-red-600 text-left text-sm flex  ">
                      {lang === "en"
                        ? error?.message?.[1] || error?.message?.[0]
                        : error?.message?.ar}
                    </span>

                    <div className="flex justify-between ">
                      <Button
                        loading={isLoading}
                        className="w-full mx-auto py-4  my-4 rounded-3xl text-white bg-maincolor font-serif "
                        type="submit"
                      >
                        {langContent.SignUpScreen.next}
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
