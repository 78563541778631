import React from "react";
import { Modal } from "semantic-ui-react";

function Reviewcommentmodel({ msg }) {
  const [open, setOpen] = React.useState(false);
  const truncateString = (str, num) => {
    if (str?.length > num) {
      return str.slice(0, num) + "...";
    } else {
      return str;
    }
  };

  return (
    <Modal
      className="w-auto h-fitt bg-white rounded-2xl"
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      trigger={
        <div className="flex justify-center w-full ">
          {" "}
          {truncateString(msg, 15)}
        </div>
      }
    >
      {/* edit heeeeeeeeeeeeeeeeeeeer */}
      <div className="text-center p-16 ">{msg}</div>
    </Modal>
  );
}

export default Reviewcommentmodel;
