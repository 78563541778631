import React, { useState } from "react";
import { IoIosMail } from "react-icons/io";
import { useHistory } from "react-router-dom";
import { useLanguage } from "../../../lib/language-context";
import { content } from "../../../localization/content";
import useAxios from "../../../lib/use-axios";
import { authAxios } from "../../../config/axios-config";
import api from "../../../api";
import "react-toastify/dist/ReactToastify.css";
import { BiEditAlt } from "react-icons/bi";
import { TiUserOutline } from "react-icons/ti";
import { FaLock, FaPhoneAlt } from "react-icons/fa";
import Profile from "./Profile-old";
import { Segment } from "semantic-ui-react";
import Avatar from "../../../component/common/Avatar/Avater";
import { BsWallet2 } from "react-icons/bs";

const ProfileV2 = () => {
  const [lang, setLang] = useLanguage("");
  const langContent = content[lang];
  const history = useHistory();
  const [step, setStep] = useState(1);

  const handelStep = () => {
    setStep(2);
  };

  const { run, isLoading, data, isError, error } = useAxios({});

  React.useEffect(() => {
    run(authAxios.get(api.Dashboard.profile.getProfile)).then((res) => {});
  }, [run]);
  return (
    <div>
      <div>
        <Segment
          className="border-none shadow-none bg-transparent  -z-1 p-0 m-0"
          loading={isLoading}
        >
          {/* view profile olnyyyy */}
          {step === 1 && (
            <div className=" mx-auto max-w-4xl ">
              <div className="  ">
                <h1 className=" text-maincolor text-3xl font-bold py-10 px-8 font-serif">
                  {langContent.Profile.Provider_details}
                </h1>
              </div>
              {/* img view  */}
              <div className=" grid md:grid-cols-3  grid-cols-2 gap-5 mx-2 bg-white p-4 rounded-xl shadow  ">
                <div className="border-maincolor border-2 border-dashed md:w-[13rem] md:h-[13rem]  w-[10rem] h-[10rem]  rounded-full bg-white">
                  <div className=" flex justify-center mx-auto   ">
                    {data?.image && data?.image?.length === 0 ? (
                      <div className="  ">
                        {/* {langContent.Profile.Upload_Provide_image}
                        <HiOutlinePhotograph size={35} className="mb-1" /> */}
                        <Avatar
                          name={data?.name}
                          styles={
                            " flex justify-center w-full py-20 h-full rounded-full text-7xl text-maincolor bg-white  cursor-pointer"
                          }
                        />
                      </div>
                    ) : (
                      <div className="md:w-[12rem] md:h-[12rem]   w-[9rem] h-[9rem]  mx-auto my-1">
                        <img
                          className="w-full h-full object-cover border-2  rounded-full"
                          src={data?.image && data?.image[0]?.link}
                          alt=""
                        />
                      </div>
                    )}
                  </div>
                </div>
                <div className="text-3xl hidden md:flex items-center ">
                  {data?.name}
                </div>
                <div className="flex flex-col justify-between items-end">
                  <div className="flex justify-end">
                    <div
                      onClick={() => {
                        handelStep();
                      }}
                      className="bg-maincolor cursor-pointer text-white rounded-full p-1.5 flex justify-end mx-2 my-2 w-[10rem] "
                    >
                      <p className=" mx-3  mt-1 ">
                        {langContent.Profile.edit_account}
                      </p>
                      <BiEditAlt className="" size={25} />
                    </div>
                  </div>
                  <div className="p-1.5 mx-2 py-4 w-[10rem]  flex flex-col justify-end  ">
                    <p className="text-subtext text-xl py-4">
                      {langContent.Profile.total_balance}
                    </p>
                    <div className="flex">
                      <BsWallet2 className="text-maincolor" size={25} />
                      <p className="px-4 text-xl">
                        {data?.wallet?.totalBalance} <span></span>
                      </p>
                    </div>
                  </div>
                </div>
                <div></div>
              </div>
              {/* basic infooo */}
              <div className="ltr:ml-8 rtl:mr-8">
                <div className="py-10 cursor-not-allowed md:hidden block">
                  <h1 className="text-xl text-subtext">
                    {langContent.SignUpScreen.user_name}
                  </h1>
                  <div className="flex py-6">
                    <TiUserOutline size={20} className="text-maincolor mt-1" />
                    <p className="text-xl mx-4">{data?.name}</p>
                  </div>
                  <p className="border-b-2 border-border"></p>
                </div>
                {/* email & pass */}
                <div className="grid md:grid-cols-2 grid-cols-1 gap-20 my-2">
                  {/* left */}
                  <div className="py-4 cursor-not-allowed ">
                    <h1 className="text-xl text-subtext font-serif">
                      {langContent.SignUpScreen.email}
                    </h1>
                    <div className="flex py-6">
                      <IoIosMail size={20} className="text-maincolor mt-1" />
                      <p className="text-xl  mx-4">{data?.email}</p>
                    </div>
                    <p className="border-b-2 border-border"></p>
                  </div>
                  {/* right */}
                  <div className="py-4 cursor-not-allowed">
                    <h1 className="text-xl text-subtext font-serif">
                      {" "}
                      {langContent.SignUpScreen.password}
                    </h1>
                    <div className="flex py-6">
                      <FaLock size={20} className="text-maincolor mt-1 mx-2" />
                      <input
                        type="password"
                        className="text-xl bg-transparent outline-none  cursor-not-allowed "
                        value="************"
                        disabled
                      ></input>
                    </div>
                    <p className="border-b-2 border-border"></p>
                  </div>
                </div>
                {/* four info basic */}
                <div className="grid md:grid-cols-2 grid-cols-1 gap-20 my-10">
                  {/* left */}
                  <div>
                    <div className="py-4 cursor-not-allowed ">
                      <h1 className="text-xl text-subtext font-serif">
                        {langContent.Profile.Main_phone_number}
                      </h1>
                      <div className="flex py-6">
                        <FaPhoneAlt size={20} className="text-maincolor mt-1" />
                        <p dir="ltr" className="text-xl   mx-4">
                          {data?.mainPhoneNumber}
                        </p>
                      </div>
                      <p className="border-b-2  border-border"></p>
                    </div>
                    {/*  */}
                    <div className="py-4 cursor-not-allowed">
                      <h1 className="text-xl text-subtext font-serif">
                        {langContent.SignUpScreen.licnse_number}
                      </h1>
                      <div className="flex py-6">
                        <p className="text-xl   mx-4">{data?.licenceNumber}</p>
                      </div>
                      <p className="border-b-2 border-border"></p>
                    </div>
                  </div>
                  {/* right */}
                  <div>
                    <div className="py-4 cursor-not-allowed">
                      <h1 className="text-xl text-subtext font-serif">
                        {langContent.Profile.another_phone_number}
                      </h1>
                      <div className="flex py-6">
                        <FaPhoneAlt size={20} className="text-maincolor mt-1" />
                        <p dir="ltr" className="text-xl   mx-4">
                          {data?.otherPhoneNumber}
                        </p>
                      </div>
                      <p className="border-b-2 border-border"></p>
                    </div>
                    {/*  */}
                    <div className="py-4 cursor-not-allowed">
                      <h1 className="text-xl text-subtext font-serif">
                        {langContent.SignUpScreen.Commerical_number}
                      </h1>
                      <div className="flex py-6">
                        <p className="text-xl   mx-4">
                          {data?.commericalNumber}
                        </p>
                      </div>
                      <p className="border-b-2 border-border"></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {step === 2 && <Profile />}
        </Segment>
      </div>
    </div>
  );
};

export default ProfileV2;
