import React from "react";
import { useLocation } from "react-router-dom";
import { Segment, Table } from "semantic-ui-react";
import { useLanguage } from "../../../lib/language-context";
import { content } from "../../../localization/content";
import api from "../../../api";
import { authAxios } from "../../../config/axios-config";
import useAxios from "../../../lib/use-axios";
import RatingExampleHeart from "../../../component/common/Rate/Rate";
import Reviewcommentmodel from "./Review-comment-model";
import revEmty from "../../../../src/assets/pic/reviews-emty-stat.svg";

const Reviews = () => {
  const [lang, setLang] = useLanguage("");
  const langContent = content[lang];
  const { pathname, state } = useLocation();

  const { run, isLoading, data, isError, error, totalPages } = useAxios({});

  React.useEffect(() => {
    run(authAxios.get(api.Dashboard.reviews.default));
  }, [run]);

  return (
    <div className="max-w-7xl mx-auto">
      <div>
        <div className=" pt-10">
          <div className="text-4xl my-4">{langContent.Reviews.Reviews}</div>
          {/* <div className="text-subtext rtl:hidden">
            {pathname.split("/").join(" > ").slice(2)}
          </div> */}
        </div>
      </div>
      <Segment
        className="border-none shadow-none p-0 m-0 -z-1  rounded-2xl"
        loading={isLoading}
      >
        {data?.length === 0 ? (
          <div className="my-6 py-32">
            <img className="mx-auto " src={revEmty} alt="" />
            <p className="text-maincolor font-bold text-6xl text-center my-24">
              {lang === "en" ? "No Review yet " : "لا مراجعات حتى الآن"}
            </p>
          </div>
        ) : (
          <Table className=" px-10 py-8 rounded-2xl bg-white my-2" basic="very">
            <Table.Header className="hidden md:contents">
              <Table.Row>
                <Table.HeaderCell className=" text-center border-none text-hedertext ">
                  {langContent.Reviews.Requestid}
                </Table.HeaderCell>
                <Table.HeaderCell className=" text-center border-none text-hedertext ">
                  {langContent.Reviews.Pickupdate}
                </Table.HeaderCell>
                <Table.HeaderCell className=" text-center border-none text-hedertext ">
                  {langContent.Reviews.Customer}
                </Table.HeaderCell>
                <Table.HeaderCell className=" text-center border-none text-hedertext ">
                  {langContent.Reviews.Rate}
                </Table.HeaderCell>
                <Table.HeaderCell className=" text-center border-none text-hedertext ">
                  {langContent.Reviews.Comments}
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            {data?.map((e) => (
              <Table.Body key={e.id}>
                <div className="my-2"></div>
                <Table.Row>
                  <Table.Cell className="text-center py-8 ltr:border-l-2 rtl:border-r-2 ltr:rounded-l-xl rtl:rounded-r-xl border-y-2 border-border">
                    <div className="flex md:contents justify-between mx-2 ">
                      <h1 className="text-hedertext bg-gray-50 w-32 p-4 rounded-lg md:hidden flex justify-center  ">
                        {langContent.Reviews.Requestid}
                      </h1>
                      <p className="pt-4">{e?.order?.id}</p>
                    </div>
                  </Table.Cell>
                  <Table.Cell className="text-center py-8 border-y-2 border-border">
                    <div className="flex md:contents justify-between mx-2  ">
                      <h1 className="text-hedertext bg-gray-50  w-32 p-4  rounded-lg md:hidden flex justify-center">
                        {langContent.Reviews.Pickupdate}
                      </h1>
                      <p className="pt-4">
                        {new Date(e?.order?.expairyDate).toLocaleDateString(
                          "en-GB"
                        )}
                      </p>
                    </div>
                  </Table.Cell>
                  <Table.Cell className="text-center py-8 border-y-2 border-border">
                    <div className="flex md:contents justify-between mx-2  ">
                      <h1 className="text-hedertext bg-gray-50  w-32 p-4  rounded-lg md:hidden flex justify-center">
                        {langContent.Reviews.Customer}
                      </h1>
                      <p className="pt-4">{e?.order?.user?.name}</p>
                    </div>
                  </Table.Cell>
                  <Table.Cell className="text-center py-8 border-y-2 border-border">
                    <div className="flex md:contents justify-between mx-2  ">
                      <h1 className="text-hedertext bg-gray-50  w-32 p-4  rounded-lg md:hidden flex justify-center">
                        {langContent.Reviews.Rate}
                      </h1>
                      <p className="pt-4">
                        <RatingExampleHeart max={e?.rate} />
                      </p>
                    </div>
                  </Table.Cell>
                  <Table.Cell className="text-center py-8 ltr:border-r-2 rtl:border-l-2 ltr:rounded-r-xl rtl:rounded-l-xl  border-y-2 border-border cursor-pointer">
                    <div className="flex md:contents justify-between mx-2  ">
                      <h1 className="text-hedertext bg-gray-50  w-32 p-4  rounded-lg md:hidden flex justify-center">
                        {langContent.Reviews.Rate}
                      </h1>
                      <p className="pt-4">
                        <Reviewcommentmodel msg={e?.review} />
                      </p>
                    </div>
                  </Table.Cell>
                </Table.Row>
                <div className="my-2"></div>
              </Table.Body>
            ))}
          </Table>
        )}
      </Segment>
    </div>
  );
};

export default Reviews;
