import React, { useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import "./Input-form.css";

const InputForm = ({ type, placeholder, label, width, value, ...props }) => {
  return (
    <div className="  flex flex-col-reverse ">
      <input
        className={` ${width} rounded-xl border-2 px-2 py-2 outline-none rtl:text-right ltr:text-left  font-serif`}
        type={type}
        placeholder={placeholder}
        value={value}
        {...props}
      />
      <label className="my-2 w-fit  transition-all ease-out">{label}</label>
    </div>
  );
};

export default InputForm;
