import { Form, Formik } from "formik";
import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Button, Dropdown, Grid } from "semantic-ui-react";
import api from "../../../api";
import BranchGoogleMaps from "../../../component/Branches-Component/Braches-Google-map";
import BrachesGooglemap from "../../../component/Branches-Component/Braches-Google-map";
import BranchesName from "../../../component/Branches-Component/Branches-Name";
import DropdownBranches from "../../../component/Branches-Component/Drop-down-Branches";
import FormikInput from "../../../component/common/formik/formik-input";
import { authAxios } from "../../../config/axios-config";
import { useLanguage } from "../../../lib/language-context";
import useAxios from "../../../lib/use-axios";
import { content } from "../../../localization/content";
import routes from "../../../routes";
import * as Yup from "yup";
import FormikDropdown from "../../../component/common/formik/formik-dropdown";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AddBraches = () => {
  const [lang, setLang] = useLanguage("");
  const langContent = content[lang];
  const history = useHistory();
  const { pathname } = useLocation();
  const [branchname, SetBrachname] = useState("");
  const [latlong, Setlatlong] = useState({});
  const [step, setStep] = useState(1);
  const [formikData, setFormikData] = useState({
    province: "",
    city: "",
    subArea: "",
    street: "",
    building: "",
    landmarkes: "",
    description: "",
  });

  // Provinces and Areas
  const [provincesOptions, setProvincesOptions] = useState([]);
  const [citiesOptions, setCitiesOptions] = useState([]);
  const [nearestArea, setNearestArea] = useState();

  const onAddBranchName = (branchname) => {
    SetBrachname(branchname);
    setStep(2);
  };
  const { run, isLoading, data, isError, error } = useAxios({});
  const {
    run: getCities,
    isLoading: isGettingCiites,
    data: cities,
  } = useAxios({});
  const {
    run: getProvinces,
    isLoading: isGettingProvinces,
    data: Provinces,
  } = useAxios({});

  const {
    run: getnearestArea,
    isLoading: isnearestArea,
    data: datanearestArea,
  } = useAxios({});

  const registerSchema = Yup.object({
    city: Yup.string().required(`${langContent.SignUpScreen.required}`),
    subArea: Yup.string().required(`${langContent.SignUpScreen.required}`),
    street: Yup.string().required(`${langContent.SignUpScreen.required}`),
    building: Yup.string().required(`${langContent.SignUpScreen.required}`),
    // description: Yup.string().required(`${langContent.SignUpScreen.required}`),
  });

  const AddBranches = (value) => {
    const body = {
      name: branchname,
      latitude: `${latlong.lat}`,
      longitude: `${latlong.lng}`,
      areaId: value.city,
      subArea: value.subArea,
      streetName: value.street,
      floor: value.building,
      landmark: value.landmarkes,
      description: value.description,
    };

    run(
      authAxios
        .post(api.Dashboard.AddBranches, body)
        .then((res) => {
          history.push(routes.Dashboard.Branches.AddContainers, {
            id: res?.data?.data?.id,
            branchname: branchname,
            addtost: true,
          });
        })
        .catch(() => {})
    );
  };

  React.useEffect(() => {
    if (step === 2) {
      // Get provinces
      getProvinces(
        authAxios.get(
          `${api.Dashboard.GetProvinces}?latitude=${latlong?.lat}&longitude=${latlong?.lng}`
        )
      ).then(({ data }) => {
        const provincesOptions = data?.data?.map((e) => ({
          key: e.id,
          value: e.id,
          text: e.nameAr,
        }));
        setProvincesOptions(provincesOptions);
      });
    }
  }, [getProvinces, latlong?.lat, latlong?.lng, step]);

  React.useEffect(() => {
    // Get nearest area
    if (provincesOptions.length > 0 && step === 2) {
      getnearestArea(
        authAxios.get(
          `${api.Dashboard.GetNearestArea}?latitude=${latlong?.lat}&longitude=${latlong?.lng}`
        )
      ).then(({ data }) => {
        setNearestArea(data?.data);
      });
    }
  }, [
    getnearestArea,
    latlong?.lat,
    latlong?.lng,
    provincesOptions.length,
    step,
  ]);

  React.useEffect(() => {
    if (step === 2) {
      getCities(
        authAxios.get(
          `${api.Dashboard.GetCity}?latitude=${latlong?.lat}&longitude=${latlong?.lng}&provinceId=${nearestArea?.provinceId}`
        )
      ).then(({ data }) => {
        console.log({ data });
        const citiesOptions = data?.data?.map((e) => ({
          key: e.id,
          value: e.id,
          text: e.name,
        }));
        setCitiesOptions(citiesOptions);
      });
      setFormikData((prev) => ({
        ...prev,
        province: nearestArea?.provinceId,
        city: nearestArea?.id,
      }));
    }
  }, [
    getCities,
    latlong?.lat,
    latlong?.lng,
    nearestArea?.id,
    nearestArea?.provinceId,
    step,
  ]);

  // Handle branch location from google maps
  const handleBranchLocation = (value) => {
    // filter results where route address types = ['route']
    const routeAddress = value?.results?.filter((o) =>
      o?.types.includes("route")
    );

    // Filter the administrative area level from routeAddress
    const routeArea = routeAddress?.[0]?.address_components?.filter((o) =>
      /sublocality/.test(o?.types[1])
    );

    const Neighborhood = routeAddress?.[0]?.address_components?.filter((o) =>
      /administrative_area_level/.test(o?.types[0])
    );

    // Filter the routeAddress to locate the street name where types = ['route']
    const routeStreet = routeAddress?.[0]?.address_components?.filter((o) =>
      o?.types.includes("route")
    );

    // Format data and update formik with the new records
    setFormikData((prev) => ({
      ...prev,
      subArea:
        (routeArea && routeArea[0]?.short_name) ||
        (Neighborhood && Neighborhood[0]?.short_name),
      street: routeStreet && routeStreet[0]?.short_name,
    }));
  };

  return (
    <div className="p-0">
      {step === 1 && <BranchesName SetBrachname={onAddBranchName} />}
      {step === 2 && (
        <div className="p-0 w-full md:relative  grid grid-cols-1 ">
          <BranchGoogleMaps
            Setlatlong={Setlatlong}
            docs={handleBranchLocation}
          />
          <div className=" bg-white/70 py-4 px-4 rounded-2xl md:absolute  w-full  md:w-80   top-32 right-20 z-10">
            <Formik
              initialValues={formikData}
              onSubmit={AddBranches}
              validationSchema={registerSchema}
              enableReinitialize
            >
              {(formik) => (
                <Form onSubmit={formik.handleSubmit}>
                  <FormikDropdown
                    name="province"
                    label={langContent.Branches.province}
                    search
                    options={provincesOptions}
                    loading={isGettingProvinces}
                  />
                  <FormikDropdown
                    name="city"
                    label={langContent.Branches.City}
                    search
                    options={citiesOptions}
                    loading={isGettingCiites}
                  />
                  <FormikInput
                    label={langContent.Branches.area}
                    placeholder={langContent.Branches.area}
                    name="subArea"
                  />

                  <FormikInput
                    label={langContent.Branches.street_name}
                    placeholder={langContent.Branches.street_name}
                    name="street"
                  />
                  <FormikInput
                    label={langContent.Branches.Address}
                    placeholder={langContent.Branches.Address}
                    name="building"
                  />

                  {/* <FormikInput
                    label={langContent.Branches.landmark}
                    placeholder={langContent.Branches.landmark}
                    name="landmarkes"
                  /> */}

                  <FormikInput
                    label={langContent.Branches.discribstion}
                    placeholder={langContent.Branches.discribstion}
                    name="description"
                  />

                  <Button
                    loading={isLoading}
                    className="w-full mx-auto py-4  my-4 rounded-3xl text-white bg-maincolor font-serif"
                    type="submit"
                  >
                    {langContent.SignUpScreen.next}
                  </Button>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      )}
    </div>
  );
};

export default AddBraches;
