import { useCallback, useEffect, useRef, useState } from "react";
// import { useState } from "react";
// import { Loader, Modal } from "semantic-ui-react";
// import { useStateContext } from "../../context/stateContext";
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";
import Geocode from "react-geocode";
// import mapStyles from "./mapStyles";
// import MapsSearch from "./mapsSearch";
import "@reach/combobox/styles.css";
import { getLatLng, getGeocode } from "use-places-autocomplete";
import { Button, Loader } from "semantic-ui-react";
// import mapStyles from "./map-styles";
import { BiCurrentLocation } from "react-icons/bi";
import MapSearch from "./map-search";

Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAPS_API_KEY);

const libraries = ["places"];
const mapContainerStyle = {
  width: "100%",
  height: "700px",
  borderRadius: "16px",
  // borderBottomLeftRadius: "16px",
};

const options = {
  styles: "mapStyles",
  disableDefaultUI: true,
  zoomControl: true,
};

const BranchGoogleMaps = ({ Setlatlong, docs }) => {
  /*global google*/
  const [markers, setMarkers] = useState({ lat: "", lng: "" });
  const [center, setCenter] = useState({ lat: 26.420209, lng: 50.092674 });
  const [mapErrors, setMapErrors] = useState("");
  const [selectedAddress, setSelectedAddress] = useState("");
  const [currentPosition, setCurrentPosition] = useState({ lat: "", lng: "" });
  const [lat, setLat] = useState("");
  const [lng, setLng] = useState("");

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    if (!("geolocation" in navigator)) {
      onError();
    }

    navigator.geolocation.getCurrentPosition(onSuccess, onError);
    Geocode.setLanguage("ar");

    // try {
    //   const results = await getGeocode({address})
    //   const {lat, lng} = await getLatLng(results[0])

    //   setCenter({
    //     lat: lat,
    //     lng: lng,
    //   })

    //   setMarkers({
    //     ...markers,
    //     lat: lat,
    //     lng: lng,
    //   })

    //   setSelectedAddress(address)
    // } catch (e) {
    //   console.log(e)
    // }
    // }
    // eslint-disable-next-line no-use-before-define
  }, []);

  // On Error in google maps location navigator
  const onError = () => {
    setMapErrors("Goelocation not supported");
  };

  // On Success callback through google maps navigator
  const onSuccess = (loc) => {
    console.log(loc.coords.latitude);
    // Set location center for map
    setCenter({
      lat: loc.coords.latitude,
      lng: loc.coords.longitude,
    });

    Setlatlong({
      lat: loc.coords.latitude,
      lng: loc.coords.longitude,
    });

    // Set marker on map
    setMarkers({
      lat: loc.coords.latitude,
      lng: loc.coords.longitude,
    });

    setCurrentPosition({ lat: loc.coords.latitude, lng: loc.coords.longitude });

    setGeoCoderAddress({ lat: loc.coords.latitude, lng: loc.coords.longitude });
  };

  //  Set address text by geoCode coords
  const setGeoCoderAddress = async ({ lat, lng }) => {
    const result = await Geocode.fromLatLng(lat, lng);
    console.log(result);
    docs(result);
    setLat(result.results[0]?.geometry?.location?.lat);
    setLng(result.results[0]?.geometry?.location?.lng);
    setSelectedAddress(result.results[0].formatted_address);
  };

  // callback on map loading
  const mapRef = useRef();
  const onMapLoad = useCallback((map) => {
    mapRef.current = map;
  }, []);

  // On click on map
  const onMapClick = useCallback((e) => {
    setMarkers({ lat: e.latLng.lat(), lng: e.latLng.lng() });

    setMarkers({
      lat: e.latLng.lat(),
      lng: e.latLng.lng(),
    });

    Setlatlong({
      lat: e.latLng.lat(),
      lng: e.latLng.lng(),
    });

    setGeoCoderAddress({ lat: e.latLng.lat(), lng: e.latLng.lng() });
  }, []);

  // Set marker on selecting from search input by google places
  const panTo = useCallback(({ lat, lng }) => {
    mapRef.current.panTo({ lat, lng });
    mapRef.current.setZoom(16);
    setMarkers({ lat: lat, lng: lng });
    setGeoCoderAddress({ lat: lat, lng: lng });

    Setlatlong({
      lat: lat,
      lng: lng,
    });
  }, []);

  // Set loading to google maps
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries,
  });

  // Set current position again in google maps
  const getCurrentPosition = () => {
    setMarkers({
      lat: currentPosition.lat,
      lng: currentPosition.lng,
    });

    setCenter({
      lat: currentPosition.lat,
      lng: currentPosition.lng,
    });

    setGeoCoderAddress({ lat: currentPosition.lat, lng: currentPosition.lng });
  };

  if (loadError) return "Error loading maps";
  if (!isLoaded) return <Loader />;

  return (
    <div className="relative z-0 ">
      <MapSearch
        selectedLocation={center}
        address={selectedAddress}
        confirm={(v) => {
          // mapAddress(selectedAddress);
          // removeModal();
        }}
        panTo={panTo}
        animation={1}
      />
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        zoom={16}
        center={center}
        options={options}
        onClick={onMapClick}
        onLoad={onMapLoad}
        animation={1}
      >
        <Button
          icon={() => <BiCurrentLocation size={30} />}
          className="text-maincolor absolute bottom-32 rounded-full right-0 bg-white font-serif "
          onClick={getCurrentPosition}
        />
        {markers && (
          <Marker
            position={{ lat: markers.lat, lng: markers.lng }}
            icon={{
              url: `${process.env.REACT_APP_BASE_PATH || ""}/location-pin.svg`,
              scaledSize: new window.google.maps.Size(100, 100),
              origin: new window.google.maps.Point(0, 0),
            }}
            animation={1}
          />
        )}
      </GoogleMap>
    </div>
  );
};

export default BranchGoogleMaps;
