import React from "react";
import { Link } from "react-router-dom";
import WaitApprovalLogo from "../../../src/assets/pic/Wait-Approval-Logo.svg";
import { useAuthState } from "../../context/auth-context";
import { useLanguage } from "../../lib/language-context";
import { content } from "../../localization/content";
import routes from "../../routes";

const WaitAdminApproval = () => {
  const [lang, setLang] = useLanguage("");
  const langContent = content[lang];
  const { logout } = useAuthState();
  return (
    <div className="mx-auto  w-full h-screen relative transition-all ease-in-out delay-100  bg-backgroundGray">
      <div className="bg-white rounded-3xl w-[45rem] h-[30rem] flex flex-col absolute  my-auto mx-auto left-0 right-0  top-[50%] bottom-[50%] text-center items-center ">
        <img className=" mt-20 w-32 h-32" src={WaitApprovalLogo} alt="" />
        <h1 className="text-3xl py-6">
          {langContent.WaitAdminApproval.youll_be_able_to_enter_your}
          <br></br>
          {langContent.WaitAdminApproval.dashboard_soon}
        </h1>
        <p>{langContent.WaitAdminApproval.just_wait_admin_approval}</p>
        <Link to={routes.auth.SignIn}>
          <button
            className="bg-maincolor text-white py-2 px-4 rounded-2xl my-8  text-xl font-serif"
            onClick={() => logout()}
          >
            {lang === "en" ? "Go to log in" : "اذهب إلي تسجيل الدخول"}
          </button>
        </Link>
      </div>
    </div>
  );
};

export default WaitAdminApproval;
