import React, { useState } from "react";
import Rejectmodel from "./Reject-model";
import SentPickupmodel from "./Sent-Pickup-model";
import SetAscompletedmodel from "./Set-As-completed-model";
import SetAsdeliveymodel from "./Set-As-delivey-model";
import Setoffermodel from "./Set-offer-model";

// PENDING_PROVIDER_OFFER => (setoffer)
// AWAITING_DELIVERY => (set as deliver)
// EARLY_PICK_UP_REQUEST || FINAL_DATE_REACHED => (two button set as completes || send pickup date  )

// DELIVERED_ACTIVE => (close)
// PENDING_USER_PAYMENT => (close)
// COMPLETED => (close)
// USER_CANCELLED_ORDER => (close)
// USER_REJECTED_OFFER => (close)

const DynamicButtonsOrderdetails = ({
  buttonstatus,
  orderid,
  onReload,
  requestId,
  setSuccesstostset,
  setSuccesstostreject,
  setSuccesstostcomlete,
  setSuccesstostpickdate,
  setSuccesstostdelver,
}) => {
  console.log(buttonstatus);
  return (
    <div>
      <div>
        {buttonstatus === "PENDING_PROVIDER_OFFER" && (
          <div className="grid md:grid-cols-2 grid-cols-1  gap-0 md:gap-5">
            <Setoffermodel
              requestId={requestId}
              onReload={onReload}
              setSuccesstostset={setSuccesstostset}
            />
            <Rejectmodel
              onReload={onReload}
              requestId={requestId}
              setSuccesstostreject={setSuccesstostreject}
            />
          </div>
        )}
        {buttonstatus === "AWAITING_DELIVERY" && (
          <div>
            <SetAsdeliveymodel
              onReload={onReload}
              orderid={orderid}
              setSuccesstostdelver={setSuccesstostdelver}
            />
          </div>
        )}
        {buttonstatus === "EARLY_PICK_UP_REQUEST" && (
          <div className="grid md:grid-cols-2 grid-cols-1  gap-0 md:gap-5 ">
            <SetAscompletedmodel
              orderid={orderid}
              onReload={onReload}
              setSuccesstostcomlete={setSuccesstostcomlete}
            />
            <SentPickupmodel
              orderid={orderid}
              onReload={onReload}
              setSuccesstostpickdate={setSuccesstostpickdate}
            />
          </div>
        )}
        {buttonstatus === "FINAL_DATE_REACHED " && (
          <div className="grid md:grid-cols-2 grid-cols-1 gap-0 md:gap-5 ">
            <SetAscompletedmodel
              orderid={orderid}
              onReload={onReload}
              setSuccesstostcomlete={setSuccesstostcomlete}
            />
            <SentPickupmodel
              orderid={orderid}
              onReload={onReload}
              setSuccesstostpickdate={setSuccesstostpickdate}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default DynamicButtonsOrderdetails;
