import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Segment } from "semantic-ui-react";
import api from "../../../api";
import Avatar from "../../../component/common/Avatar/Avater";
import { authAxios } from "../../../config/axios-config";
import { useLanguage } from "../../../lib/language-context";
import useAxios from "../../../lib/use-axios";
import { content } from "../../../localization/content";
import notificationemtystate from "../../../../src/assets/pic/notification-emty-state.svg";
import routes from "../../../routes";

const Notification = () => {
  const [lang, setLang] = useLanguage("");
  const langContent = content[lang];
  const history = useHistory();
  const { pathname } = useLocation();
  const { run, isLoading, data, isError, error } = useAxios({});

  useEffect(() => {
    run(authAxios.get(api.Dashboard.Notification.default)).then(() => {});
  }, [run]);
  return (
    <div className="max-w-7xl mx-auto ">
      <div className=" pt-10 ">
        <div className="text-4xl my-4 mt-16 ">
          {langContent.Notifications.Notifications}
        </div>
        {/* <div className="text-subtext rtl:hidden mb-8">
          {pathname.split("/").join(" > ").slice(2)}
        </div> */}
      </div>
      {data?.length === 0 ? (
        <div className="my-6 py-32">
          <img className="mx-auto " src={notificationemtystate} alt="" />
          <p className="text-maincolor font-bold text-6xl text-center my-24">
            {lang === "en" ? "No notifications yet " : "لا إخطارات حتى الآن"}
          </p>
        </div>
      ) : (
        <Segment
          className="border-none shadow-none p-0 m-0 -z-1 rounded-2xl"
          loading={isLoading}
        >
          <div className="w-full  bg-white h-auto rounded-2xl py-8 ">
            {data?.map((e) => (
              <div
                onClick={() =>
                  history.push(
                    routes.Dashboard.Orders.DetailsTodayOrders(e?.orderId)
                  )
                }
                key={e?.id}
                className="border-2 border-border py-4 flex gap-8 md:px-10 px-2 rounded-2xl mx-8 my-8 active:bg-subcolor cursor-pointer  "
              >
                <div className="block md:flex">
                  <Avatar
                    styles={
                      "text-3xl w-20 h-20 py-6 text-white text-center mt-2 rounded-full"
                    }
                    name={e?.senderUserName}
                  />
                  <div className="py-4 md:px-6 px-0  ">
                    <h1 className="text-xl text-maintext font-bold break-all">
                      {lang === "en" ? e?.enTitle : e?.arTitle}
                    </h1>
                    <p>{lang === "en" ? e?.enBody : e?.arBody}</p>
                    <p className="text-sm font-medium text-subtext py-1">
                      {new Date(e?.createdAt).toLocaleDateString("en-GB")}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </Segment>
      )}
    </div>
  );
};

export default Notification;
