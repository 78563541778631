import React from "react";
import videoSuccess from "../../../src/assets/pic/Success.mp4";
import { useLanguage } from "../../lib/language-context";
import { content } from "../../localization/content";

const Success = () => {
  const [lang, setLang] = useLanguage("");
  return (
    <div className="w-full md:w-[40rem] object-cover center-abs ">
      <video autoPlay muted loop className="w-full h-full object-fill">
        <source
          className="w-full h-full object-fill"
          src={videoSuccess}
          type="video/mp4"
        ></source>
      </video>
      <h1 className="text-3xl font-medium text-center py-2">
        {lang === "en" ? "payment successful" : "تم الدفع بنجاح"}
      </h1>
      <p className="text-xl  text-center text-subtext py-2 ">
        {lang === "en"
          ? "your payment has been successfully processed"
          : "تمت معالجة الدفع الخاص بك بنجاح"}
      </p>
    </div>
  );
};

export default Success;
