import React from "react";
import { Route } from "react-router-dom";
import { Switch } from "react-router-dom";
import Failed from "../pages/payment-screen/Failed";
import Success from "../pages/payment-screen/Success";
import routes from "../routes";

const Paymentlayouts = () => {
  return (
    <div>
      <Switch>
        <Route path={routes.payment.paymentSuccessful} component={Success} />
        <Route path={routes.payment.paymentFailed} component={Failed} />
      </Switch>
    </div>
  );
};

export default Paymentlayouts;
