export const content = {
  en: {
    SignUpScreen: {
      // left side
      hello_friend: " Hello, friend!",
      already_have_an_account: "Already have an account? ",
      sign_in_to_continue: "Sign in to continue.",
      sign_in: "Sign in",
      // right side

      sign_up: "Sign up",
      create_an_account_to_continue: "Create an account to continue",
      user_name: "Company name",
      email: "Email",
      password: "password",
      confirm_password: "Confirm password",
      licnse_number: "license number",
      Commerical_number: "Commerical number",
      phone_number: "Phone number",
      next: "Next",
      required: "required",
      you_must_start_with: "you must start with",
      must_be_more_than: "must be more than 3 characters",
      email_not_valid: "Email not valid",
      password_must_be_8: "password must be more than 8 character",
      must_be_3: " must be more than 8 character",
      must_be_20: " must be more than 20 character",
      password_must_be_20: "password must be less than 20 characters",
      name_must_be_20: "It must be less than 20 characters",
      not_match: "not match",
      branch_name: "Branch name",
    },
    SignInScreen: {
      // left section
      hello: "Hello!",
      not_a_member: "Not a member?",
      create_an_account: "Create an account",
      to_continue: "to continue",
      sign_up: "Sign up",
      // right side
      sign_in: "Sign in",
      lets_get_start_now: "Let's get start now!",
      phone_number: "Phone number",
      password: "password",
      i_cant_remember_my_password: "I can't remember my password",
    },

    auth: {
      Resend: "Resend Code",
      Submit: "Submit OTP",
      PleaseSet: "Please set your new password",
      newPassword: "New password",
      Renter: "Re-enter Password",
      Reset: "Reset your password",
      firstName: "First name",
      secoundName: "Secound name",
      enterFName: "Enter your first name",
      enterSName: "Enter your Secound name",
      enterPass: "Enter your password ",
      reEnterPass: "Re-enter your password ",
      notMatch: "Your password is not match",
      enterPhone: "Enter your phone number ",
      firstMustbe3: "The first name must be more than 3 letters ",
      firstMustbe20: "The first name must be less than 20 letters ",
      secoundMustbe3: "The secound name must be more than 3 letters ",
      secoundMustbe20: "The secound name must be less than 20 letters ",
      enterPassmustbe8: "Password must be 8 characters or more",
      enterPassmustbe20: "Password must be 20 characters or less",
      enterPhonemustbe11: "The phone number must be 11 numbers or more",
      enterPhonemustbe20: "The phone number must be 20 numbers or less",
      Adminname: "Enter your Admin name ",
      Adminnamemustne6: "The first name must be more than 6 letters",
      Adminnamemustne20: "The first name must be less than 20 letters",
      confirm: "Confirm",
    },

    criticalInfo: {
      editPersonal: "Edit personal details",
      changeName: "Change name",
      changePassword: "Change password",
      changePhoneNumber: "Change phone number",
      login: "Login",
      saveChanges: " Save changes",
      confirmNewNumber: "Confirm new number",
    },

    CompletedProfile: {
      You_Should: "You Should complete your profile first !",
      to_continue:
        "To continue using Naqla you must complete your profile first",
      go_to: "Go to profile",
      Upload_photo: "Upload photo",
      // bank data
      Bank_Data: "Bank Data",
      Bank_ID: "Bank ID",
      Bank_account: "Bank account",
      holder_name: "Bank account holder name",
      Iban: "Iban",
      //button
      Upload_files: "Upload files",
      back: "back",
      // type of  objects
      Civil_ID: "Civil ID",
      Commercial_License: "Commercial License",
      Articles_of_Association: "Articles of Association",
      Signture_Authorization: "Signture Authorization",
      Other: "Other",
      Civil_ID_Back: "Civil ID Back",
      Instagram: "Instagram",
      Civil_IDs_Of_all_Owners: "Civil IDs Of all Owners",
      Civil_ID_Of_manager: "Civil ID Of manager",
      Commercial_Register: "Commercial Register",
      Bank_Account_Letter: "Bank Account Letter",
      Website: "Website",
      rd_patties: "3rd patties",
      // PDF
      send: "send",
      clear: "clear",
      Select_a_file_to_attach: "Select a file to attach",
      Type: "Type",
    },

    ForgetPasswordScreen: {
      forget_password: "Forget password",
      please_enter_your_email:
        "Please enter your email or phone number to receive a verification number",
      phone_number: "Phone number",
      send: "send",
    },

    OTPScreen: {
      otp: "You will get a OTP via phone number",
      didnt_receive_a_otp: "Didn't receive a OTP ?",
      resend_otp: "Resend OTP",
      verify: "Verify",
    },

    AnotherPhoneScreen: {
      another_phone_number: "Please provider another phone number",
      enterEmail: "Please enter your E-mail",
      to_login_on_ezaha: "to login on naqla",
      add_another_phone_number: "Add Another Phone number",
      continue: "Continue",
      email: "E-mail",
    },

    WaitAdminApproval: {
      youll_be_able_to_enter_your: "You'll be able to enter your",
      dashboard_soon: "dashboard soon!",
      just_wait_admin_approval: "just wait admin approval...",
    },

    CreateNewPassword: {
      create_new_password: "Create new password",
      your_new_password: "Your new password must be different from",
      previously_used: "previously used password",
      new_password: "New password",
      confirm_password: "Confirm password",
      next: "Next",
    },

    SideBar: {
      //  Branches
      branches: "Branches",
      add_branches: "Add Branches",
      all_branches: "View Branches",
      //  Branches
      containers: "Containers",
      add_containers: "Add Containers",
      update_containers: "Update Containers",
      // Orders
      orders: "Orders",
      today_orders: "Today Orders",
      active_orders: "Today Orders",
      pending_orders: "Pending Orders",
      history_orders: "History",
    },

    Branches: {
      // add_branches
      add_branches: "Add Branches",
      branch_name: "Branch Name",
      area: "Neighborhood",
      City: "City",
      street_name: "Street Name",
      building_floor: "Building/floor",
      landmark: "landmark",
      discribstion: "Description",
      submit: "Submit",
      Please_branch: "Please enter your branch data",
      active: "Active",
      archive: "Archive",
      branchـname: "Branch name",
      Location: "Location",
      Container: "Container",
      Branshes_Details: "Branches Details",
      Address: "building",
      Location_on_map: "Area",
      Container_Sizes: "Container Sizes",
      Number_of_containers: "Number of containers",
      province: "Province",
    },

    Container: {
      // data side
      add_container: "Add Container",
      sizes_in_yards: "Sizes in yards",
      purpose: "Purpose",
      number_Containers: "Number of Containers",
      size_in_cm: "Size in cm",
      length: "Length",
      width: "Width",
      high: "Height",
      holds_wight: "Holds Wight",
      kg: "Kg",
      // img side
      uplad_img_s: "Upload container image",
      uplad_img_m:
        "Upload 3 images with different sides. pdf .png .jpg with max file 2MB.",
      save: "Save",
      cancel: "Cancel",
    },
    Notifications: {
      Notifications: "Notifications",
    },

    Orders: {
      // Orders Tabs
      today: "Today's",
      active: "Active",
      pending: "Pending",
      history: "History",
      PickupRequests: "Pickup Requests",
      // labol name
      today_orders: "Today Orders",
      active_orders: "Today Orders",
      pending_orders: "Pending Orders",
      history_orders: "History",
      // tables
      Sort: "Sort",
      Order_ID: "Order ID ",
      Quantity: "Quantity",
      Status: "Status",
      contanersize: " Contaner size",
      Regular_pickup_date: "Regular pickup date",
      Address: "Address",
      deliverydate: "delivery date",
      Offer: "Offer",
      // status
      Ontheway: "On the way",
      Arrived: "Arrived",
      Requested: "Requested",
      Waitingfortheuser: "Waiting for the user",
      Completed: "Completed",
      Canceled: "Canceled",
      Rejected: "Rejected",
      Earlypickuprequest: "Early pick up request",
      Regularpickupdate: "Regular pick up date",
      // order details
      OrderDetails: "Order Details",
      ReceivedDate: "Received Date",
      Productdetails: "Product details",
      Payment: "Payment",
      Paymentmethod: "Payment method:",
      Shippedto: "Shipped to",
      Pickup: "Pickup",
    },

    TodayOrdersTable: {
      orders_id: "Orders ID",
      contaner_size: "Container Size",
      status: "Status",
      quantity: "Quantity",
      address: "Address",
      amount: "Amount",
      regular_pickup_date: "Regular Pickup Date",
    },

    OrdersFilterModel: {
      filter: "Filter",
      date: "Date",
      apply: " Apply",
      cancel: "Cancel",
    },

    Reviews: {
      Reviews: "Reviews",
      Requestid: "Request id	",
      Pickupdate: "Pickup date	",
      Customer: "Customer",
      Rate: "Rate",
      Comments: "Comments",
      Pickup: "Pickup",
    },

    Profile: {
      Profile: "Profile",
      logout: "logout",
      Provider_details: "Provider details",
      basic_info: "basic info",
      Change_password: "Change password",
      submit: "submit",
      another_phone_number: " Another Phone number",
      Main_phone_number: "Main phone number",
      Old_password: "Old password",
      New_password: "New password",
      Confirm_new_password: "Confirm new password",
      Upload_Provide_image: "Upload Provide image",
      edit_account: "Edit account",
      total_balance: "Total balance",
    },
  },

  ar: {
    SignUpScreen: {
      // left side
      hello_friend: " مرحبا  !",
      already_have_an_account: "هل لديك حساب؟ ",
      sign_in_to_continue: "تسجيل الدخول للمتابعة.",
      sign_in: "تسجيل الدخول",
      // right side

      sign_up: "إنشاء حساب",
      create_an_account_to_continue: "إنشاء حساب للمتابعة",
      user_name: "اسم الشركة",
      email: "  البريد الإلكتروني",
      password: "  كلمة المرور",
      confirm_password: "تأكيد كلمة المرور",
      licnse_number: "الترخيص",
      Commerical_number: "رقم تجاري",
      phone_number: "رقم الهاتف",
      next: " التالي",
      required: "مطلوب",
      you_must_start_with: "يجب أن يبدأ ب",
      must_be_more_than: "يجب أن يكون أكثر من 3 أحرف",
      email_not_valid: "البريد الإلكتروني غير صالح",
      password_must_be_8: "يجب أن تكون كلمة المرور أكثر من 8 أحرف",
      must_be_3: "يجب أن تكون  اكثر من 3 أحرف",
      must_be_20: "يجب أن تكون  اقل من 20 أحرف",
      password_must_be_20: "يجب أن تكون كلمة المرور أقل من 20 حرفًا",
      name_must_be_20: "يجب أن تكون أسم المستخدم أقل من 20 حرفًا",
      not_match: "لا يتطابق",
      branch_name: "اسم الفرع",
    },

    auth: {
      Resend: "أعد إرسال الرمز",
      Submit: "إرسال OTP",
      PleaseSet: "الرجاء تعيين كلمة المرور الجديدة",
      newPassword: "كلمة مرور جديدة",
      Renter: "أعد إدخال كلمة المرور",
      Reset: "إعادة تعيين كلمة المرور الخاصة بك",
      firstName: "الاسم الأول",
      secoundName: "الاسم الثاني",
      enterFName: "اكتب اسمك الاول",
      enterSName: "اكتب اسمك الثاني",
      enterPass: "اكتب كلمة المرور",
      reEnterPass: "اعد كتابه كلمة المرور",
      notMatch: "كلمة المرور غير متطابقة",
      enterPhone: "اكتب رقم الهاتف الخاص بك",
      firstMustbe3: "يجب أن يكون الاسم الأول أكثر من ٣ أحرف ",
      firstMustbe20: "يجب أن يكون الاسم الأول اقل من ٢٠ أحرف ",
      secoundMustbe3: "يجب أن يكون الاسم الثاني أكثر من ٣ أحرف ",
      secoundMustbe20: "يجب أن يكون الاسم الثاني اقل من ٢٠ أحرف",
      enterPassmustbe8: "يجب أن تكون كلمة المرور  ٨ أحرف او اكثر",
      enterPassmustbe20: "يجب أن تكون كلمة المرور  ٢٠ أحرف او اقل",
      enterPhonemustbe11: "يجب أن يكون رقم الهاتف ١١ رقمًا أو أكثر",
      enterPhonemustbe20: "يجب أن يكون رقم الهاتف ٢٠ رقمًا أو أقل",
      Adminname: "أدخل اسم المسؤول",
      Adminnamemustne6: "يجب أن يكون الاسم الأول أكثر من ٦ أحرف",
      Adminnamemustne20: "يجب أن يكون الاسم الأول أقل من ٢٠ حرفًا",
      confirm: "تأكيد",
    },

    SignInScreen: {
      // left section
      hello: "أهلا!",
      not_a_member: "لست عضوا؟",
      create_an_account: "إنشاء حساب",
      to_continue: "للمتابعة",
      sign_up: "إنشاء حساب",
      // right side
      sign_in: "تسجيل الدخول",
      lets_get_start_now: "فلنبدأ الآن!",
      phone_number: "رقم الهاتف",
      password: "كلمة المرور",
      i_cant_remember_my_password: "لا أستطيع تذكر كلمة المرور الخاصة بي",
    },

    criticalInfo: {
      editPersonal: "تحرير التفاصيل الشخصية",
      changeName: "تغيير الاسم",
      changePassword: "تغيير كلمة المرور",
      changePhoneNumber: "تغيير رقم الهاتف",
      login: "تسجيل الدخول",
      saveChanges: " حفظ التغييرات",
      confirmNewNumber: "تأكيد الرقم الجديد",
    },

    CompletedProfile: {
      You_Should: "يجب عليك إكمال ملف التعريف الخاص بك أولا!",
      to_continue:
        "لمواصلة استخدام نقلة ، يجب عليك إكمال ملف التعريف الخاص بك أولاً",
      go_to: "انتقل إلى الملف الشخصي",
      Upload_photo: "حمل الصورة",
      // bank data
      Bank_Data: "بيانات البنك",
      Bank_ID: "رقم البنك",
      Bank_account: "حساب مصرفي",
      holder_name: "اسم صاحب الحساب المصرفي",
      Iban: "رقم الحساب المصرفي الدولي",
      //button
      Upload_files: "تحميل الملفات",
      back: "رجوع",
      // type of  objects
      Civil_ID: "صورة بطاقة الهوية الأمامية ",
      Commercial_License: " الرخصة التجارية",
      Articles_of_Association: " عقد تأسيس",
      Signture_Authorization: "إذن التوقيع",
      Other: "أخرى",
      Civil_ID_Back: "صورة بطاقة الهوية الخلفية ",
      Instagram: "Instagram",
      Civil_IDs_Of_all_Owners: "صورة بطاقة هوية الملاك ",
      Civil_ID_Of_manager: "بطاقة الهوية للمدير",
      Commercial_Register: "السجل الضريبي",
      Bank_Account_Letter: "  شهادة الحساب البنكي",
      Website: "موقع إلكتروني فعال",
      rd_patties: "شركاء طرف تالت",
      // PDF
      send: "إرسال",
      clear: "مسح",
      Select_a_file_to_attach: "حدد ملفًا لإرفاقه",
      Type: "النوع",
    },

    ForgetPasswordScreen: {
      forget_password: "نسيت كلمة المرور",
      please_enter_your_email:
        "الرجاء إدخال بريدك الإلكتروني أو رقم هاتفك لتلقي رقم التحقق",
      phone_number: "رقم الهاتف",
      send: "إرسال",
    },

    OTPScreen: {
      otp: "ستحصل على OTP عبر رقم الهاتف",
      didnt_receive_a_otp: "ألم تستلم OTP",
      resend_otp: "إعادة إرسال OTP",
      verify: "تحقق",
    },

    AnotherPhoneScreen: {
      enterEmail: "برجاء كتابة البريد الاكتروني الخاص بك ",
      another_phone_number: "يرجى تقديم رقم هاتف آخر",
      to_login_on_ezaha: "لتسجيل الدخول على نقلة",
      add_another_phone_number: "أضف رقم هاتف آخر",
      continue: "متابعة",
      email: "البريد الإلكتروني",
    },

    WaitAdminApproval: {
      youll_be_able_to_enter_your: "ستتمكن من الدخول إلى",
      dashboard_soon: "لوحة التحكم قريبًا!",
      just_wait_admin_approval: "فقط انتظر موافقة المسؤول ...",
    },

    CreateNewPassword: {
      create_new_password: "إنشاء كلمة مرور جديدة",
      your_new_password: " يجب أن تكون كلمة مرورك الجديدة مختلفة عن",
      previously_used: "كلمة المرور المستخدمة سابقًا",
      new_password: "كلمة مرور الجديدة",
      confirm_password: "تأكيد كلمة المرور",
      next: "التالي",
    },

    SideBar: {
      //  Branches
      branches: "الفروع",
      add_branches: "إضافة فروع",
      all_branches: "كل الفروع",
      //  Branches
      containers: " حاويات",
      add_containers: "ضافة حاويات",
      update_containers: "تحديث الحاويات",
      // Orders
      orders: " الطلبات",
      today_orders: "طلبات اليوم",
      active_orders: "طلبات اليوم",
      pending_orders: "الطلبات المعلقة",
      history_orders: "السجلات",
      Pickup: "يلتقط",
    },

    Branches: {
      add_branches: "إضافة فروع",
      branch_name: "اسم الفرع",
      area: "الحي",
      City: "المدينة",
      street_name: "اسم الشارع",
      building_floor: "مبنى / طابق",
      landmark: "مَعلم",
      discribstion: "الوصف",
      submit: "يُقدِّم",
      Please_branch: "الرجاء إدخال بيانات الفرع الخاص بك",
      active: "النشطة",
      archive: "مؤرشفة",
      branchـname: "اسم الفرع",
      Location: "الموقع",
      Container: "الحاويات",
      Branshes_Details: "تفاصيل الفرع",
      Address: " البناية",
      Location_on_map: " المنطقة ",
      Container_Sizes: "أحجام الحاويات",
      Number_of_containers: "عدد الحاويات",
      province: "المنطقة",
    },

    Container: {
      // data side
      add_container: "إضافة حاوية",
      sizes_in_yards: "المقاسات بالياردات",
      purpose: "الغرض",
      number_Containers: "عدد الحاويات",
      size_in_cm: "الحجم بال سم",
      length: "الطول",
      width: "العرض",
      high: "الأرتفاع",
      holds_wight: "يحمل الوزن",
      kg: "كجم",
      // img side
      uplad_img_s: "تحميل صورة الحاوية",
      uplad_img_m:
        "تحميل 3 صور بجوانب مختلفة. pdf .png .jpg بحد أقصى ملف 2 ميجابايت",
      save: "حفظ",
      cancel: "إلغاء",
    },

    Notifications: {
      Notifications: "الإشعارات",
    },

    Orders: {
      // Orders Tabs
      today: "اليوم",
      active: "النشطة",
      pending: "المعلقة",
      history: "السجلات",
      PickupRequests: "طلبات الاستلام",
      // labol name
      today_orders: "طلبات اليوم",
      active_orders: "طلبات نشطة",
      pending_orders: "الطلبات المعلقة",
      history_orders: "السجلات",
      // tables
      Sort: "رتب من حيث",
      Order_ID: "رقم الطلب",
      Quantity: "الكمية",
      Status: "الحالة",
      contanersize: "حجم الحاوية",
      Regular_pickup_date: "تاريخ الإستلام العادي",
      Address: "العنوان",
      deliverydate: "تاريخ الطلب",
      Offer: "عرض السعر",
      // status
      Ontheway: "في الطريق",
      Arrived: "وصل",
      Requested: "مطلوب",
      Waitingfortheuser: " في انتظار المستخدم",
      Completed: "مكتمل",
      Canceled: "ألغيت",
      Rejected: "مرفوض",
      Earlypickuprequest: "طلب الإستلام المبكر",
      Regularpickupdate: " تاريخ الإستلام العادي",
      // OrderDetails
      OrderDetails: "تفاصيل الطلب",
      ReceivedDate: " تاريخ الإستلام",
      Productdetails: "   تفاصيل المنتج",
      Payment: "المدفوعات",
      Paymentmethod: " طريقة الدفع",
      Shippedto: "يتم الشحن إلى",
    },

    TodayOrdersTable: {
      orders_id: "رقم الطلبات",
      contaner_size: "حجم الحاوية",
      status: " الحالة",
      quantity: "الكمية",
      address: "عنوان",
      amount: "المبلغ",
      regular_pickup_date: "تاريخ التسليم",
    },

    OrdersFilterModel: {
      filter: "تصفية",
      date: "التاريخ",
      apply: " تطبيق",
      cancel: "إلغاء",
    },
    Reviews: {
      Reviews: "المراجعات",
      Requestid: " رقم الطلب	",
      Pickupdate: " تاريخ الإستلام	",
      Customer: "اسم العميل",
      Rate: "التقييم",
      Comments: "التعليقات",
      Pickup: " يلتقط",
    },

    Profile: {
      Profile: "الملف الشخصي",
      logout: " تسجيل الخروج",
      Provider_details: "تفاصيل الموزع",
      basic_info: "المعلومات الأساسية",
      Change_password: "تغيير كلمة المرور",
      submit: "حفظ",
      another_phone_number: "رقم هاتف آخر",
      Main_phone_number: "رقم الهاتف الرئيسي",
      Old_password: "كلمة المرور القديمة",
      New_password: "كلمة المرور جديدة",
      Confirm_new_password: "تأكيد كلمة المرور الجديدة",
      Upload_Provide_image: "تحميل صورة الموزع",
      edit_account: "تعديل الحساب",
      total_balance: "إجمالي الرصيد",
    },
  },
};
