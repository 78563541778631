import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import MainLogo from "../../../src/assets/pic/Main-Logo.svg";
import InputForm from "../common/input-form/Input-Form";
import routes from "../../routes";
import { useLanguage } from "../../lib/language-context";
import { content } from "../../localization/content";
import useAxios from "../../lib/use-axios";
import * as Yup from "yup";
import api from "../../api";
import { axios } from "../../config/axios-config";
import FormikInput from "../common/formik/formik-input";
import { Form, Formik } from "formik";
import { Button } from "semantic-ui-react";

const CreateNewPassword = () => {
  const [lang, setLang] = useLanguage("");
  const langContent = content[lang];
  const history = useHistory();
  const { pathname, ...location } = useLocation();

  const { run, isLoading, isError, error, data, status } = useAxios();

  const registerSchema = Yup.object({
    password: Yup.string()
      .min(8, `${langContent.SignUpScreen.password_must_be_8}`)
      .max(20, `${langContent.SignUpScreen.password_must_be_20}`)
      .required(`${langContent.SignUpScreen.required}`),
    confirmPassword: Yup.string()
      .oneOf(
        [Yup.ref("password"), null],
        `${langContent.SignUpScreen.not_match}`
      )
      .required(`${langContent.SignUpScreen.required}`),
  });

  const ResetPassword = (values) => {
    const body = {
      expiredToken: location.state.expiredToken,
      password: values.password,
    };
    run(axios.post(api.auth.ResetPassword, body))
      .then(() => {
        history.push(routes.auth.SignIn);
      })
      .catch((err) => {});
  };

  return (
    // bg
    <div className="mx-auto w-full h-full fixed  transition-all ease-in-out delay-100  bg-backgroundGray">
      {/* CreateNewPassword  */}
      <div className="max-w-7xl  h-[45rem] absolute my-auto mx-auto left-0 right-0  top-[50%] bottom-[50%]">
        <img
          className="absolute z-10 -top-5 left-[45%]  w-32 h-32  rounded-full mx-auto bg-white p-4"
          src={MainLogo}
          alt="logo"
        />
        <div className=" max-w-7xl h-[45rem]  overflow-hidden  rounded-3xl  bg-white absolute my-auto mx-auto left-0 right-0  top-[55%] bottom-[50%] text-center ">
          <div className="flex flex-col justify-center my-32">
            <h1 className="text-4xl font-sans my-2 ">
              {langContent.CreateNewPassword.create_new_password}
            </h1>
            <p className="text-sm py-3">
              {langContent.CreateNewPassword.your_new_password}

              <br></br>
              {langContent.CreateNewPassword.previously_used}
            </p>
            <div className="pt-6 w-96 mx-auto">
              <Formik
                initialValues={{
                  password: "",
                  confirmPassword: "",
                }}
                onSubmit={ResetPassword}
                validationSchema={registerSchema}
              >
                {(formik) => (
                  <Form onSubmit={formik.handleSubmit}>
                    <FormikInput
                      placeholder={langContent.CreateNewPassword.new_password}
                      label={langContent.CreateNewPassword.new_password}
                      name="password"
                      type="password"
                    />
                    <FormikInput
                      placeholder={
                        langContent.CreateNewPassword.confirm_password
                      }
                      label={langContent.CreateNewPassword.confirm_password}
                      name="confirmPassword"
                      type="password"
                    />
                    <span className="h-4 mt-1 text-red-600 text-left text-sm flex  ">
                      {lang === "en" ? error?.message?.en : error?.message?.ar}
                    </span>
                    <div className="flex justify-between">
                      <Button
                        loading={isLoading}
                        className="w-full mx-auto py-4 font-sans my-4 rounded-3xl text-white bg-maincolor "
                        type="submit"
                      >
                        {langContent.CreateNewPassword.next}
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateNewPassword;
