import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useLanguage } from "../../../lib/language-context";
import { content } from "../../../localization/content";
import * as Yup from "yup";
import Progresspar from "./Progress-par";
import { Form, Formik } from "formik";
import FormikInput from "../../../component/common/formik/formik-input";
import { Button } from "semantic-ui-react";
import useAxios from "../../../lib/use-axios";
import api from "../../../api";
import { authAxios } from "../../../config/axios-config";
import SuplyerFileSection from "./suplyer-file-section";
import routes from "../../../routes";
import AddPicModelCompletProfile from "./AddPic-Model-Complet-Profile";
import { FiAlertTriangle } from "react-icons/fi";

const CompleteProfile = () => {
  const [lang, setLang] = useLanguage("");
  const langContent = content[lang];
  const history = useHistory();
  const { pathname, ...location } = useLocation();
  const image = location?.state?.img;
  const [step, setStep] = useState(1);

  console.log("====================================");
  console.log(location);
  console.log("====================================");

  React.useEffect(() => {
    if (!location?.state?.Id || location?.state?.Id === "undefined") {
      history.push(routes.auth.SignIn);
    }
  }, [history, location?.state?.Id]);

  const [pdfCounter, setPdfCounter] = useState(0);

  const { run, isLoading, data, isError, error } = useAxios({});

  const postBankData = (value) => {
    const body = {
      // BankId: parseInt(value?.Bank_ID),
      BankAccountHolderName: value?.Bank_account,
      BankAccount: value?.Bankaccountholdername,
      Iban: value?.Iban,
    };

    run(authAxios.post(api.auth.createsupplier, body)).then((res) => {
      setStep(step + 1);
    });
  };

  if (location?.state?.supplierCode > 0 && step !== 3) {
    setStep(3);
  } else if (image && image?.length === 1 && step !== 2 && step !== 3) {
    setStep(2);
  }

  const BanckSchema = Yup.object({
    // Bank_ID: Yup.string()
    //   .min(
    //     5,
    //     `${
    //       lang === "en"
    //         ? "you must be min 5 character "
    //         : "يجب أن تكون اكثر من ٨ خانات"
    //     }`
    //   )
    //   .max(
    //     20,
    //     `${
    //       lang === "en"
    //         ? "you must be max 20 character "
    //         : "يجب ان تكون اقل من ٢٠ خانة "
    //     }`
    //   )
    //   .required(`${langContent.SignUpScreen.required}`),
    Bank_account: Yup.string()
      // .min(
      //   8,
      //   `${
      //     lang === "en"
      //       ? "you must be min 8 character "
      //       : "يجب أن تكون اكثر من ٨ خانات"
      //   }`
      // )
      // .max(
      //   20,
      //   `${
      //     lang === "en"
      //       ? "you must be max 20 character "
      //       : "يجب ان تكون اقل من ٢٠ خانة "
      //   }`
      // )
      .required(`${langContent.SignUpScreen.required}`),
    Bankaccountholdername: Yup.string()
      // .min(
      //   8,
      //   `${
      //     lang === "en"
      //       ? "you must be min 8 character "
      //       : "يجب أن تكون اكثر من ٨ خانات"
      //   }`
      // )
      // .max(
      //   20,
      //   `${
      //     lang === "en"
      //       ? "you must be max 20 character "
      //       : "يجب ان تكون اقل من ٢٠ خانة "
      //   }`
      // )
      .required(`${langContent.SignUpScreen.required}`),
    Iban: Yup.string()
      .matches(
        /(^s+a+[0-9]*$)/gi,
        `${
          lang === "en"
            ? "you must start with AS "
            : "يجب علي هذا الحقل ان يبدأ  SA "
        }`
      )
      .length(
        24,
        `${
          lang === "en"
            ? "you must be enter 24 character"
            : "يجب عل هذا الحقل ان يحتوي علي ٢٤ خانة فقط لا غير "
        }`
      )

      .required(`${langContent.SignUpScreen.required}`),
  });

  const types = [
    {
      name: `${langContent.CompletedProfile.Commercial_License}`,
      value: 2,
    },
    {
      name: `${langContent.CompletedProfile.Civil_ID}`,
      value: 1,
    },
    {
      name: `${langContent.CompletedProfile.Civil_ID_Back}`,
      value: 6,
    },
    {
      name: `${langContent.CompletedProfile.Civil_ID_Of_manager}`,
      value: 17,
    },
    {
      name: `${langContent.CompletedProfile.Bank_Account_Letter}`,
      value: 21,
      nots: (
        <p className="text-lg text-red-500 flex">
          <FiAlertTriangle size={20} className="mt-0.5 mx-2" />
          {lang === "en"
            ? "The author's name must be written in English"
            : "يجب ان يكون اسم صاحب الخطاب مكتوب باللغة الانجليزية"}
        </p>
      ),
    },
    {
      name: `${langContent.CompletedProfile.Articles_of_Association}`,
      value: 3,
    },
    {
      name: `${langContent.CompletedProfile.Commercial_Register}`,
      value: 20,
    },
    {
      name: `${langContent.CompletedProfile.Civil_IDs_Of_all_Owners}`,
      value: 16,
    },
    {
      name: `${langContent.CompletedProfile.Other}`,
      value: 5,
    },
    {
      name: `${langContent.CompletedProfile.Signture_Authorization}`,
      value: 4,
    },

    {
      name: `${langContent.CompletedProfile.Instagram}`,
      value: 7,
    },
    {
      name: `${langContent.CompletedProfile.Website}`,
      value: 25,
    },
    {
      name: `${langContent.CompletedProfile.rd_patties}`,
      value: 26,
    },
  ];

  return (
    <div className="max-w-7xl mx-auto  ">
      <div className="flex flex-col relative md:top-20 top-14">
        <div className="bg-white shadow py-8 px-8 rounded-xl w-full">
          <Progresspar step={step} />
        </div>
        <div className="bg-white w-full shadow rounded-xl my-4 h-[40rem]  overflow-y-auto scrollbar-hide">
          {step === 1 && (
            <div className="mx-4 my-4">
              <h1 className="text-maintext text-4xl m-4  ">
                <p>{langContent.CompletedProfile.Upload_photo}</p>
              </h1>
              <div className="flex justify-center my-8">
                <AddPicModelCompletProfile
                  id={location?.state?.Id}
                  name={location?.state?.name}
                  img={image && image[0]?.link}
                  imglength={image && image?.length}
                  setStep={setStep}
                />
              </div>
            </div>
          )}
          {step === 2 && (
            <div>
              <div className="w-full mx-auto">
                <div className="border-b-2 mx-8 text-3xl p-4">
                  {langContent.CompletedProfile.Bank_Data}
                </div>
                <div className=" items-end">
                  <Formik
                    initialValues={{
                      // Bank_ID: "",
                      Bank_account: "",
                      Bankaccountholdername: "",
                      Iban: "",
                    }}
                    onSubmit={postBankData}
                    validationSchema={BanckSchema}
                    enableReinitialize
                  >
                    {(formik) => (
                      <Form onSubmit={formik.handleSubmit}>
                        <div className="grid md:grid-cols-2 grid-cols-1 mx-10 my-14  gap-10">
                          <div className="w-full ">
                            <div className="my-10">
                              {/* <FormikInput
                                label={langContent.CompletedProfile.Bank_ID}
                                placeholder={
                                  langContent.CompletedProfile.Bank_ID
                                }
                                name="Bank_ID"
                              /> */}
                            </div>
                            <div className="my-10">
                              <FormikInput
                                label={
                                  langContent.CompletedProfile.Bank_account
                                }
                                placeholder={
                                  langContent.CompletedProfile.Bank_account
                                }
                                name="Bank_account"
                              />
                            </div>
                          </div>
                          <div className="w-full">
                            <div className="my-10">
                              <FormikInput
                                label={langContent.CompletedProfile.holder_name}
                                placeholder={
                                  langContent.CompletedProfile.holder_name
                                }
                                name="Bankaccountholdername"
                              />
                            </div>
                            <div className="my-10">
                              <FormikInput
                                label={langContent.CompletedProfile.Iban}
                                placeholder={langContent.CompletedProfile.Iban}
                                name="Iban"
                              />
                            </div>
                          </div>
                        </div>
                        <span className="h-4 mt-1 text-red-600 text-left text-sm  flex justify-end mx-10  ">
                          {lang === "en"
                            ? error?.message?.[0]
                            : error?.message?.[1]}
                          {error?.message?.[0]}
                        </span>
                        <div className=" flex justify-end mx-8">
                          <Button
                            loading={isLoading}
                            className="  text-white bg-maincolor rounded-full py-2 px-8 my-4 text-3xl shadow shadow-maincolor font-serif "
                            type="submit"
                          >
                            {langContent.Profile.submit}
                          </Button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          )}
          {step === 3 && (
            <div className="max-w-7xl ">
              <div className="sticky top-0  w-full max-w-7xl rounded-t-xl mx-auto p-8 bg-white  border-b-2 h-24 text-3xl z-50 flex justify-between ">
                <p>{langContent.CompletedProfile.Upload_files}</p>
                <p className="text-lg text-red-500 flex">
                  <FiAlertTriangle size={20} className="mt-0.5 mx-2" />
                  {lang === "en"
                    ? "Please send at least 6 documents"
                    : "برجاء ارسال  6 مستندات علي الاقل"}
                </p>
              </div>
              <div className="p-4 flex flex-col gap-y-5  ">
                {types.map((e) => (
                  <SuplyerFileSection
                    valuetype={e?.value}
                    file={e?.name}
                    setPdfCounter={setPdfCounter}
                    pdfCounter={pdfCounter}
                    nots={e?.nots}
                  />
                ))}
              </div>
            </div>
          )}
        </div>
        {step === 3 && (
          <div className="bg-white  py-2 rounded-xl shadow-md   ">
            <div className="flex justify-end mx-4 items-end gap-5  ">
              <button
                onClick={() => {
                  setStep(step - 1);
                }}
                className="bg-maincolor text-white py-2 px-8 rounded-full text-2xl "
              >
                {langContent.CompletedProfile.back}
              </button>
              <Button
                onClick={() => {
                  if (pdfCounter >= 6) {
                    setStep(step + 1);
                    history.push(routes.auth.WaitSignUP);
                  }
                }}
                className="bg-maincolor text-white py-2 px-6 rounded-full text-2xl "
                disabled={pdfCounter < 6}
              >
                {langContent.AnotherPhoneScreen.continue}
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CompleteProfile;
