import React from "react";
import { Button, Header, Image, Modal } from "semantic-ui-react";
import api from "../../../../api";
import { authAxios } from "../../../../config/axios-config";
import { useLanguage } from "../../../../lib/language-context";
import useAxios from "../../../../lib/use-axios";
import { content } from "../../../../localization/content";

function SentPickupmodel({ orderid, onReload, setSuccesstostpickdate }) {
  const [lang, setLang] = useLanguage("");
  const langContent = content[lang];
  const [open, setOpen] = React.useState(false);
  const [pickupDate, SetPickupDate] = React.useState(Number);

  const { run, isLoading, data, isError, error } = useAxios({});

  const SentPickupDate = () => {
    const body = {
      orderId: orderid,
      pickupDate: pickupDate,
    };
    run(authAxios.put(api.Dashboard.orders.sentpickup, body)).then(() => {
      onReload();
      setOpen(false);
      setSuccesstostpickdate(true);
    });
  };

  return (
    <Modal
      className="md:w-[30rem] md:h-[15rem] w-[25rem] h-[15rem] bg-white rounded-2xl"
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      closeIcon
      trigger={
        <button className="w-56 h-14 py-2  md:my-10 my-2 rounded-full text-white bg-maincolor ">
          {lang === "en" ? " Send pickup date" : "تاريخ الاستلام"}
        </button>
      }
    >
      {/* edit heeeeeeeeeeeeeeeer */}
      <div>
        <div className="flex  flex-col  items-center">
          <h1 className="text-left text-maintext text-xl py-2">
            {lang === "en" ? " Send pickup date" : "تاريخ الاستلام"}
          </h1>
          <input
            className="border-2 rounded-2xl w-80 h-16 text-maincolor my-4 px-2 outline-none"
            type="date"
            onChange={(e) => SetPickupDate(e?.target?.value)}
          />
          <span className="h-4 mt-1 text-red-600  flex justify-center text-sm   ">
            {/* {lang === "en" ? error?.message?.en : error?.message?.ar} */}
          </span>
          <Button
            loading={isLoading}
            onClick={() => {
              SentPickupDate();
            }}
            className=" w-48 h-12 py-2  rounded-full text-white bg-maincolor font-serif"
          >
            {lang === "en" ? " Send the pickup date " : "أرسل تاريخ الإستلام"}
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default SentPickupmodel;
