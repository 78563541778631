import React, { useState } from "react";
import axios from "axios";
import { Button, Modal } from "semantic-ui-react";
import api from "../../../../api";
import useAxios from "../../../../lib/use-axios";
import { authAxios } from "../../../../config/axios-config";
import { useLanguage } from "../../../../lib/language-context";
import { content } from "../../../../localization/content";

function SetAscompletedmodel({ orderid, onReload, setSuccesstostcomlete }) {
  const [lang, setLang] = useLanguage("");
  const langContent = content[lang];
  const [open, setOpen] = useState(false);
  const [completed, setcomplited] = useState(false);

  const { run, isLoading, data, isError, error } = useAxios({});
  const SentCompleted = () => {
    const body = {
      orderId: orderid,
    };
    run(authAxios.post(api.Dashboard.orders.setcomplited, body)).then(() => {
      setOpen(false);
      onReload();
      setSuccesstostcomlete(true);
      setcomplited(true);
    });
  };

  return (
    <Modal
      className="md:w-[30rem] md:h-[15rem] w-[25rem] h-[15rem] bg-white rounded-2xl"
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      closeIcon
      trigger={
        <button
          onClick={() => {
            setOpen(false);
          }}
          className={`${
            completed === true ? "hidden" : ""
          } w-56 h-14  py-2  md:my-10 my-2 rounded-full text-white bg-green-500`}
        >
          {lang === "en" ? "Set as completed" : "تعيين كمكتمل"}
        </button>
      }
    >
      {/* edit heeeeeeeeeeeeeeeeeeeeer  */}
      <div className="">
        <div className="">
          <h1 className="text-2xl text-maintext text-center md:py-6  py-10">
            {lang === "en"
              ? " Are you sure set this order request is complete ?"
              : "هل أنت متأكد من تعيين طلب الطلب هذا كاملاً؟"}
          </h1>
          <span className="h-4 mt-1 text-red-600  flex justify-center text-sm   ">
            {lang === "en" ? error?.message?.en : error?.message?.ar}
          </span>
          <div className="flex justify-between mx-8 md:my-8 my-0">
            <Button
              loading={isLoading}
              onClick={() => {
                SentCompleted();
              }}
              className=" w-48 h-12 py-2  rounded-full text-white bg-green-500 font-serif"
            >
              {lang === "en" ? "Yes" : "نعم"}
            </Button>
            <button
              onClick={() => {
                setOpen(false);
              }}
              className="w-48 h-12 py-2  rounded-full text-maincolor border-2 border-maincolor"
            >
              {lang === "en" ? "close" : "أغلاق"}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default SetAscompletedmodel;
