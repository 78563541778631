import React from "react";
import { RiSendPlaneFill } from "react-icons/ri";
import { BsCheckLg } from "react-icons/bs";
import { Button, Message } from "semantic-ui-react";
import api from "../../../api";
import PdfAttachment from "../../../component/shared/pdf-attachment";
import { authAxios } from "../../../config/axios-config";
import { useLanguage } from "../../../lib/language-context";
import useAxios from "../../../lib/use-axios";
import { content } from "../../../localization/content";

const SuplyerFileSection = ({
  file,
  valuetype,
  setPdfCounter,
  pdfCounter,
  nots,
}) => {
  const [lang, setLang] = useLanguage("");
  const langContent = content[lang];
  const [attachmentData, setAttachmentData] = React.useState(null);

  const {
    run: sendingPdf,
    isLoading: loadingSendingPdf,
    error: errorSendingPdf,
    data,
  } = useAxios({});

  const submitPdf = () => {
    const formData = new FormData();
    if (attachmentData) {
      formData.append("FileUpload", attachmentData);
      formData.append("FileType", valuetype);
      formData.append("counter", pdfCounter + 1);
      // for (var pair of formData.entries()) {
      //   console.log(pair[0] + ", " + pair[1]);
      // }
      sendingPdf(authAxios.put(api.auth.supplierDocuments, formData)).then(
        ({ data }) => {
          setPdfCounter((c) => c + 1);
        }
      );
    }
  };

  return (
    <div>
      <div className=" shadow-md rounded-xl p-4 -z-10 ">
        {errorSendingPdf && (
          <Message
            error
            content={
              errorSendingPdf?.message?.[0] ||
              errorSendingPdf?.message?.[1] ||
              errorSendingPdf?.message?.en
            }
          ></Message>
        )}
        <div className="flex w-full justify-between">
          <div className=" w-[22rem] flex items-center  bg-[#D9D9D9B3]/50 rounded-lg px-2 py-1">
            {/* <p className="text-2xl text-subtext bg-[#D9D9D9B3"> */}
            {/* {langContent.CompletedProfile.Type} */}
            {/* </p> */}
            <p className="text-maintext text-xl px-8">{file}</p>
          </div>
          <div className="border-2 border-border rounded-lg mx-2 px-8 py-1 w-full">
            <PdfAttachment
              id={valuetype}
              setAttachmentData={setAttachmentData}
            />
          </div>
        </div>
        <p></p>
        <div className=" flex justify-between">
          <p className="mt-3">{nots}</p>
          {data?.IsSuccess === true ? (
            <div className=" flex justify-end mt-3">
              <p className="flex bg-transparent text-green-500 text-xl mt-1 ">
                {lang === "en" ? "Upload Done" : "تم رفع الصوره بنجاح"}
              </p>
              <BsCheckLg size={20} className="mt-2 mx-4 text-green-500 " />
            </div>
          ) : (
            <Button
              loading={loadingSendingPdf}
              className={` 
            flex bg-transparent text-maincolor text-xl font-serif `}
              type="submit"
              onClick={() => submitPdf()}
            >
              {langContent.CompletedProfile.send}
              <RiSendPlaneFill size={20} className="mt-1 mx-2" />
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default SuplyerFileSection;
