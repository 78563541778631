import React from "react";
import { Segment, Table } from "semantic-ui-react";
import Imagecontaner from "../../../src/assets/pic/Image-contaner-branch-table.png";
import { useHistory, useLocation } from "react-router-dom";
import routes from "../../routes";
import useAxios from "../../lib/use-axios";
import api from "../../api";
import { authAxios } from "../../config/axios-config";
import { useLanguage } from "../../lib/language-context";
import { content } from "../../localization/content";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import emity from "../../../src/assets/pic/emity-state-branch.svg";
import Loadingnaqla from "../common/Loading-eco-sys/Loading-naqla";

const BranchesActiveTable = () => {
  const [lang, setLang] = useLanguage("");
  const langContent = content[lang];
  const history = useHistory();
  const { pathname, ...location } = useLocation();

  const { run, isLoading, data, isError, error } = useAxios({});
  const isArchived = false;

  React.useEffect(() => {
    const err = (content) =>
      toast.error(content, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    if (location?.state?.redtost === true) {
      err(
        lang === "en" ? "This branch has been archived" : "تم أرشفة هذا الفرع "
      );
    }
  }, [lang, location?.state?.redtost]);
  React.useEffect(() => {
    run(authAxios.get(`${api.Dashboard.ViewBraches}?isArchived=${isArchived}`));
  }, [isArchived, run]);

  return (
    <div>
      {data?.length === 0 ? (
        <div className="my-4">
          <img className="mx-auto  " src={emity} alt="" />
          <p className="text-maincolor font-bold text-5xl text-center my-10">
            {lang === "en" ? "There are no  branches" : "لا توجد فروع بعد  "}
          </p>
        </div>
      ) : (
        <Segment
          className="border-none shadow-none p-0 m-0 -z-1"
          loading={isLoading}
        >
          <ToastContainer />
          {/* <Loadingnaqla load={isLoading} /> */}
          <Table className="" basic="very">
            <Table.Header className="hidden md:contents">
              <Table.Row>
                <Table.HeaderCell className=" text-center border-none text-maintext ">
                  {langContent.Branches.branchـname}
                </Table.HeaderCell>
                <Table.HeaderCell className=" text-center border-none text-maintext ">
                  {langContent.Branches.Location}
                </Table.HeaderCell>
                <Table.HeaderCell className=" text-center border-none text-maintext ">
                  {langContent.Branches.Container}
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            {data?.map((e) => (
              <Table.Body key={e._id}>
                <div className="my-3 mt-3 w-full"></div>
                <Table.Row
                  onClick={() =>
                    history.push(
                      routes.Dashboard.Branches.BranchesDetailsActive(e?.id)
                    )
                  }
                  className="active:bg-subcolor  "
                >
                  <Table.Cell className="text-center py-8   ltr:border-l-2 rtl:border-r-2 ltr:rounded-l-xl rtl:rounded-r-xl border-y-2 border-border w">
                    <div className="flex md:contents justify-between mx-2 ">
                      <h1 className="text-hedertext bg-gray-50 w-32 p-4 rounded-lg md:hidden flex justify-center  ">
                        {langContent.Branches.branchـname}
                      </h1>
                      <p className="pt-4 text-maintext">{e?.name}</p>
                    </div>
                  </Table.Cell>
                  <Table.Cell className="text-center py-8 border-y-2 border-border ">
                    <div className="flex md:contents justify-between mx-2  ">
                      <h1 className="text-hedertext bg-gray-50  w-32 p-4  rounded-lg md:hidden flex justify-center">
                        {langContent.Branches.Location}
                      </h1>
                      <p className="pt-4 text-maintext">{e?.area.name}</p>
                    </div>
                  </Table.Cell>
                  <Table.Cell className="text-center py-8   ltr:border-r-2 rtl:border-l-2 ltr:rounded-r-xl rtl:rounded-l-xl  border-y-2 border-border ">
                    <div className="flex md:contents justify-between mx-2 ">
                      <h1 className="text-hedertext bg-gray-50 w-32 p-4  rounded-lg md:hidden flex justify-center">
                        {langContent.Branches.Container}
                      </h1>
                      <div className="flex justify-center gap-3 pt-4 ">
                        <img className="w-24" src={Imagecontaner} alt="" />
                        <p className="pt-2 text-maintext"> {e?.containers}</p>
                      </div>
                    </div>
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            ))}
          </Table>
        </Segment>
      )}
    </div>
  );
};
export default BranchesActiveTable;
