import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import MainLogo from "../../../src/assets/pic/Main-Logo.svg";
import OTPLogo from "../../../src/assets/pic/OTP-Logo.svg";
import api from "../../api";
import { axios } from "../../config/axios-config";
import { useLanguage } from "../../lib/language-context";
import useAxios from "../../lib/use-axios";
import { content } from "../../localization/content";
import routes from "../../routes";
import { Button } from "semantic-ui-react";
import { getTimeFromSeconds } from "../../utils/time";

const OtpScreen = () => {
  const [remaining, setRemaining] = useState(0);
  const [allTimeData, setAllTimeData] = useState({});
  const [showResendBtn, setShowResendBtn] = useState(false);
  const [lang, setLang] = useLanguage("");
  const langContent = content[lang];

  const tabChange = function (val) {
    const ele = document.querySelectorAll("input");
    if (ele[val - 1]?.value !== "") {
      ele[val]?.focus();
    } else if (ele[val - 1]?.value === "") {
      ele[val - 2]?.focus();
    }
  };
  const [err, setEror] = useState();

  const [numone, SetNumOne] = useState("");
  const [numtwo, SetNumTwo] = useState("");
  const [numthree, SetNumThree] = useState("");
  const [numfour, SetNumFour] = useState("");
  const [numfive, SetNumFive] = useState("");
  const [numsix, SetNumSix] = useState("");
  const AllOtp = numone + numtwo + numthree + numfour + numfive + numsix;

  const history = useHistory();
  const { pathname, ...location } = useLocation();

  const { run, isLoading, isError, error, data } = useAxios();
  // ------------------------------------------------------------- //
  // timer
  React.useEffect(() => {
    if (!window.localStorage.getItem("endTime")) {
      if (!showResendBtn) {
        createEndTime();
      }
    } else {
      setInterval(function () {
        var remaining = localStorage.endTime - new Date();
        if (remaining >= 0) {
          setRemaining(remaining);
        } else {
          localStorage.endTime = "";
          window.localStorage.removeItem("endTime");
          setShowResendBtn(true);
        }
      }, 100);
    }
  }, [showResendBtn]);

  React.useEffect(() => {
    if (remaining > 0) {
      let tdata = getTimeFromSeconds(Number(remaining / 1000).toFixed(0));
      setAllTimeData(tdata);
    }
  }, [remaining]);

  const createEndTime = () => {
    localStorage.endTime = +new Date() + 120000;

    setInterval(function () {
      var remaining = localStorage.endTime - new Date();
      if (remaining >= 0) {
        setRemaining(remaining);
      } else {
        localStorage.endTime = "";
        window.localStorage.removeItem("endTime");
        setShowResendBtn(true);
      }
    }, 100);
  };
  // ------------------------------------------------------------- //

  const VerifyOTP = () => {
    const body = {
      otp_id:
        location?.state?.OtpId ||
        data?.otpResponse?.otp_id ||
        location?.state.otp_id,
      otp_code: AllOtp,
    };
    run(axios.post(api.auth.verifyOtp, body))
      .then((res) => {
        if (res.data.data.otpResponse === "failed") {
          setEror(true);
        } else {
          pathname.startsWith(routes.auth.SignUp)
            ? history.push(routes.auth.AddAntherPhoneSignUP, {
                provider_name: location.state.values.provider_name,
                email: location.state.values.email,
                password: location.state.values.password,
                licenceNumber: location.state.values.licenceNumber,
                commericalNumber: location.state.values.commericalNumber,
                mainPhoneNumber: location.state.values.mainPhoneNumber,
              })
            : history.push(routes.auth.CreateNewPass, {
                expiredToken: location.state.expiredToken,
              });
        }
      })
      .catch((err) => {});
  };

  // const resendOtp = () => {
  //   const body = {
  //     otp_id:
  //       location?.state?.OtpId ||
  //       data?.otpResponse?.otp_id ||
  //       location?.state.otp_id,
  //   };
  //   run(axios.post(api.auth.ResendOtp, body))
  //     .then((res) => {
  //       createEndTime();
  //     })
  //     .catch(() => {});
  // };

  return (
    // bg
    <div className="mx-auto w-full h-full fixed   transition-all ease-in-out delay-100  bg-backgroundGray">
      {/* sign up  */}
      <div className="max-w-7xl  h-[45rem] absolute my-auto mx-auto left-0 right-0  top-[50%] bottom-[50%]">
        <img
          className="absolute z-10 -top-5 left-[45%]  w-32 h-32  rounded-full mx-auto bg-white p-4"
          src={MainLogo}
          alt="logo"
        />
        <div className=" max-w-7xl h-[45rem]  overflow-hidden  rounded-3xl  bg-white absolute my-auto mx-auto left-0 right-0  top-[55%] bottom-[50%] text-center ">
          <div className="flex flex-col justify-center my-40">
            <img className="w-32 mx-auto" src={OTPLogo} alt="OTPLogo" />
            <p className="py-8">{langContent.OTPScreen.otp}</p>
            <div>
              <input
                onChange={(e) => SetNumOne(e.target.value)}
                className="w-12 otp mx-2 text-2xl pl-4 rtl:pr-4 outline-none border-b-2 focus:border-maincolor"
                type="tel"
                onKeyUp={() => tabChange(1)}
                maxLength="1"
              />
              <input
                onChange={(e) => SetNumTwo(e.target.value)}
                className="w-12 mx-2 text-2xl pl-4 rtl:pr-4 outline-none border-b-2 focus:border-maincolor"
                type="tel"
                onKeyUp={() => tabChange(2)}
                maxLength="1"
              />
              <input
                onChange={(e) => SetNumThree(e.target.value)}
                className="w-12 mx-2 text-2xl pl-4 rtl:pr-4 outline-none border-b-2 focus:border-maincolor"
                type="tel"
                onKeyUp={() => tabChange(3)}
                maxLength="1"
              />
              <input
                onChange={(e) => SetNumFour(e.target.value)}
                className="w-12 mx-2 text-2xl pl-4 rtl:pr-4 outline-none border-b-2 focus:border-maincolor"
                type="tel"
                onKeyUp={() => tabChange(4)}
                maxLength="1"
              />
              <input
                onChange={(e) => SetNumFive(e.target.value)}
                className="w-12 mx-2 text-2xl pl-4 rtl:pr-4 outline-none border-b-2 focus:border-maincolor"
                type="tel"
                onKeyUp={() => tabChange(5)}
                maxLength={1}
              />
              <input
                onChange={(e) => SetNumSix(e.target.value)}
                className="w-12 mx-2 text-2xl pl-4 rtl:pr-4 outline-none border-b-2 focus:border-maincolor"
                type="tel"
                onKeyUp={() => tabChange(6)}
                maxLength="1"
              />
            </div>
            <Button
              onClick={VerifyOTP}
              loading={isLoading}
              className="w-96 py-3 mt-16 font-sans rounded-3xl text-white bg-maincolor  mx-auto"
            >
              {langContent.OTPScreen.verify}
            </Button>
            <span className="h-4 mt-1 text-red-600 mx-auto text-left text-sm flex  ">
              {lang === "en" ? error?.message?.en : error?.message?.ar}
              {err === true
                ? lang === "en"
                  ? "Wrong OTP try again "
                  : "خطأ في ال OTP حاول مرة"
                : ""}
            </span>
            <p className="my-4 text-sm flex justify-center">
              {langContent.OTPScreen.didnt_receive_a_otp}
              <div>
                {showResendBtn ? (
                  <Button
                    loading={isLoading}
                    onClick={() => {
                      // resendOtp();
                      setShowResendBtn(false);
                    }}
                    className="text-maincolor  bg-transparent p-0 text-sm mx-2"
                  >
                    {langContent.OTPScreen.resend_otp}
                  </Button>
                ) : (
                  <div className="flex mx-auto justify-center text-maincolor  px-2">
                    <p>{allTimeData?.minutes}</p> :<p>{allTimeData?.seconds}</p>
                  </div>
                )}
              </div>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OtpScreen;
