import React from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { ImLocation2 } from "react-icons/im";
import { BiEditAlt } from "react-icons/bi";
import Containersize from "../../../src/assets/pic/Container-size.svg";
import Containericon from "../../../src/assets/pic/Container-icon-size.svg";
import routes from "../../routes";
import { useLanguage } from "../../lib/language-context";
import { content } from "../../localization/content";
import useAxios from "../../lib/use-axios";
import { authAxios } from "../../config/axios-config";
import api from "../../api";
import { Segment } from "semantic-ui-react";
import addcontainericon from "../../../src/assets/pic/add-container-icon.svg";
import UnarchiveModel from "./Un-Archive-Alert-model";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const BranshesDetails = ({ backpath }) => {
  const [lang, setLang] = useLanguage("");
  const langContent = content[lang];
  const history = useHistory();
  const { pathname, state } = useLocation();
  const { branchesdDtailsId } = useParams();
  const [containers, SetContainers] = React.useState(0);
  const [checkcontainers, SetCheckContainers] = React.useState(true);
  const { run, isLoading, data, isError, error } = useAxios({});

  if (error?.message?.en === "You are not authenticated") {
    history.push(routes.auth.SignIn);
    // window.setToken({ newAccessToken: "", newRefreshToken: "" });
  }
  React.useEffect(() => {
    const success = (content) =>
      toast.success(content, {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    // if (state?.addtostcontainer === true) {
    //   success(
    //     lang === "en"
    //       ? "This container has been added successfully"
    //       : " تمت إضافة هذه الحاوية بنجاح"
    //   );
    // }
    // if (state?.ubdattostcontainer === true) {
    //   success(
    //     lang === "en"
    //       ? "This container has been added successfully"
    //       : " تمت التعديل علي هذه الحاوية بنجاح"
    //   );
    // }
    run(authAxios.get(api.Dashboard.BranchesDetails(branchesdDtailsId))).then(
      () => {
        SetCheckContainers(false);
      }
    );
  }, [branchesdDtailsId, run]);

  return (
    <div className="max-w-7xl mx-auto">
      <ToastContainer />
      <Segment
        className="border-none shadow-none bg-transparent  p-0 m-0 -z-1"
        loading={isLoading}
      >
        <div className=" pt-10">
          <div className="text-4xl my-2">
            {langContent.Branches.Branshes_Details}
          </div>
          {/* <div className="text-subtext rtl:hidden">
            {pathname.split("/").join(" > ").slice(2)}
          </div> */}
        </div>
        {/* Branshes Name  */}
        <div className="flex justify-between flex-wrap px-8 gap-20 bg-white rounded-3xl w-full py-16 my-8 font-serif ">
          <div className="flex">
            <div className="text-hedertext pt-1.5 px-8 font-serif">
              {langContent.Branches.branch_name}
            </div>
            <div className="text-2xl px-4"> {data?.name}</div>
          </div>
          <div className=" flex justify-end text-maincolor ">
            <UnarchiveModel dataa={data?.isArchived} />
          </div>
        </div>
        {/* address and location and city */}
        <div className=" flex justify-between  flex-wrap bg-white rounded-3xl w-full py-8 my-8">
          <div className=" flex-col">
            <h1 className="text-hedertext pt-1.5 px-8 py-8 font-serif">
              {langContent.Branches.Location_on_map}
            </h1>
            <p className=" flex text-xl px-5">
              <p className="text-hedertext mt-1 mx-2">
                <ImLocation2 />
              </p>
              <p>{data?.area?.administrativeArea}</p>
            </p>
          </div>
          <div className=" flex-col">
            <h1 className="text-hedertext pt-1.5 px-8 py-8 font-serif">
              {langContent.Branches.City}
            </h1>
            <p className=" text-xl px-8">{data?.area?.name}</p>
          </div>
          <div className=" flex-col">
            <h1 className="text-hedertext pt-1.5 px-8 py-8 font-serif">
              {langContent.Branches.area}
            </h1>
            <p className=" text-xl px-8">{data?.address?.subArea}</p>
          </div>
          <div className=" flex-col">
            <h1 className="text-hedertext pt-1.5 px-8 py-8 font-serif">
              {langContent.Branches.street_name}
            </h1>
            <p className=" text-xl px-8">{data?.address?.streetName}</p>
          </div>
          <div className=" flex-col">
            <h1 className="text-hedertext pt-1.5 px-8 py-8 font-serif">
              {langContent.Branches.Address}
            </h1>
            <p className=" text-xl px-8 flex gap-5">
              <p>{data?.address?.floor}</p>
            </p>
          </div>
        </div>
        {/* contaner sizes  */}
        <div className=" rounded-3xl w-full max-w-7xl h-auto  my-8   bg-white ">
          <div
            className={`${
              checkcontainers === false ? "text-border" : "text-maincolor "
            } flex justify-end `}
          >
            <button
              onClick={
                checkcontainers === false
                  ? ""
                  : () =>
                      history.push(routes.Dashboard.Branches.UpdateContainers, {
                        id: data.id,
                        branchname: data.name,
                        TINY: data?.containers?.TINY?.doesExist,
                        SMALL: data?.containers?.SMALL?.doesExist,
                        MEDIUM: data?.containers?.MEDIUM?.doesExist,
                        LARGE: data?.containers?.LARGE?.doesExist,
                        TINY_ID: data?.containers?.TINY?.id,
                        SMALL_ID: data?.containers?.SMALL?.id,
                        MEDIUM_ID: data?.containers?.MEDIUM?.id,
                        LARGE_ID: data?.containers?.LARGE?.id,
                      })
              }
            >
              <BiEditAlt className="my-6 mx-8" size={35} />
            </button>
          </div>
          <div className=" grid lg:grid-cols-2 grid-cols-1   py-10 pb-16  ">
            <div className=" w-full ">
              <p className="flex gap-3 pb-8 lg:mx-20 mx-2 px-6 ">
                <img src={Containersize} alt=""></img>
                <p className=""> {langContent.Branches.Container_Sizes}</p>
              </p>
              <div className="flex justify-center gap-10  md:mx-20 mx-4 ">
                <button
                  onClick={() => {
                    SetContainers(6);
                    SetCheckContainers(data?.containers.TINY.doesExist);
                  }}
                  className={` ${
                    containers === 6
                      ? "bg-maincolor text-white "
                      : "text-maincolor"
                  }   border-maincolor border-2 w-24 h-16 rounded-2xl`}
                >
                  6
                </button>
                <button
                  onClick={() => {
                    SetContainers(10);
                    SetCheckContainers(data?.containers.SMALL.doesExist);
                  }}
                  className={` ${
                    containers === 10
                      ? "bg-maincolor text-white "
                      : "text-maincolor"
                  }   border-maincolor border-2 w-24 h-16 rounded-2xl`}
                >
                  10-12
                </button>
                <button
                  onClick={() => {
                    SetContainers(20);
                    SetCheckContainers(data?.containers.MEDIUM.doesExist);
                  }}
                  className={` ${
                    containers === 20
                      ? "bg-maincolor text-white "
                      : "text-maincolor "
                  }  r border-2 border-maincolor w-24 h-16 rounded-2xl`}
                >
                  20
                </button>
                <button
                  onClick={() => {
                    SetContainers(30);
                    SetCheckContainers(data?.containers.LARGE.doesExist);
                  }}
                  className={` ${
                    containers === 30
                      ? "bg-maincolor text-white "
                      : "text-maincolor"
                  }   border-maincolor border-2 w-24 h-16 rounded-2xl`}
                >
                  30
                </button>
              </div>
            </div>
            <div className="">
              <div className=" h-16 my-8 lg:my-0 lg:mx-20 px-8  ">
                <p className="flex gap-3  pb-6 ">
                  <img className="text-black" src={Containericon} alt="" />
                  <p className="">
                    {langContent.Branches.Number_of_containers}
                  </p>
                </p>
                <p className=" flex border-2 border-border rounded-2xl ">
                  <p className=" w-full h-16  text-center py-5 ">
                    {containers === 6
                      ? data?.containers?.TINY?.amount
                      : containers === 10
                      ? data?.containers?.SMALL?.amount
                      : containers === 20
                      ? data?.containers?.MEDIUM?.amount
                      : containers === 30
                      ? data?.containers?.LARGE?.amount
                      : ""}
                  </p>
                  <button
                    onClick={() =>
                      history.push(routes.Dashboard.Branches.AddContainers, {
                        id: data.id,
                        branchname: data.name,
                        TINY: data?.containers?.TINY?.doesExist,
                        SMALL: data?.containers?.SMALL?.doesExist,
                        MEDIUM: data?.containers?.MEDIUM?.doesExist,
                        LARGE: data?.containers?.LARGE?.doesExist,
                      })
                    }
                    className={`${
                      checkcontainers === false ? "" : " hidden"
                    }  px-4  `}
                  >
                    <img src={addcontainericon} alt="" />
                  </button>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-end ">
          <button
            onClick={() => {
              history.push(backpath);
            }}
            className="w-64 py-4  my-10 rounded-3xl text-white bg-maincolor "
          >
            {lang === "en" ? "close" : " إغلاق"}
          </button>
        </div>
      </Segment>
    </div>
  );
};

export default BranshesDetails;
