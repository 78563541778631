import React, { useState } from "react";
import { Tab } from "semantic-ui-react";
import { useLocation } from "react-router-dom";
import routes from "../../../routes";
import useTab from "../../../hooks/use-tab";
import { useLanguage } from "../../../lib/language-context";
import { content } from "../../../localization/content";
import BranchesActiveTable from "../../../component/Branches-Component/Branches-Active-Table";
import BrachesArchiveTable from "../../../component/Branches-Component/Braches-Archive-Table";

const ViewBranchesTabs = () => {
  const [lang, setLang] = useLanguage("");
  const langContent = content[lang];
  const { pathname } = useLocation();

  const panes = [
    {
      menuItem: `${langContent.Branches.active}`,
      route: routes.Dashboard.Branches.ActiveBranches,
      render: () => (
        <div>
          <Tab.Pane className="border-none w-full h-full  rounded-2xl">
            <BranchesActiveTable />
          </Tab.Pane>
        </div>
      ),
    },
    {
      menuItem: `${langContent.Branches.archive}`,
      route: routes.Dashboard.Branches.Archive,
      render: () => (
        <div>
          <Tab.Pane className="border-none w-full h-full  rounded-2xl">
            <BrachesArchiveTable />
          </Tab.Pane>
        </div>
      ),
    },
  ];
  const { activeIndex, onTabChange } = useTab({ panes });

  return (
    <div className="">
      <div className="mx-auto h-auto rounded-3xl max-w-7xl  mt-8 edit-For-view-branches-tab ">
        <Tab
          menu={{
            secondary: true,
            pointing: true,
            className: "flex flex-wrap ",
          }}
          activeIndex={activeIndex}
          onTabChange={onTabChange}
          panes={panes}
        />
      </div>
    </div>
  );
};

export default ViewBranchesTabs;
