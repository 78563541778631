import React from "react";
import { MdFilterAlt } from "react-icons/md";
import { useHistory } from "react-router-dom";
import { Button, Modal } from "semantic-ui-react";
import { useLanguage } from "../../lib/language-context";
import useFilter from "../../lib/use-filter";
import { content } from "../../localization/content";
import ButtonFilter from "../common/Buttons/Button-filter";
import MultiButtonFilter from "../common/Buttons/MultiButtonFilter";

// early pickup request: EARLY_PICKUP_REQUEST
// regular date: FINAL_DATE_REACHED

function ActiveOrdersFilterModel({
  onReload,
  status_one,
  status_two,
  status_three,
  // status_four,
  history,
  lable_one,
  lable_two,
  lable_three,
  cancel,
}) {
  const [lang, setLang] = useLanguage("");
  const langContent = content[lang];
  const [open, setOpen] = React.useState(false);
  const historyy = useHistory();

  const [ontheway, setOntheway] = React.useState(true);
  const [arrived, setArrivedy] = React.useState(true);

  const [datefrom, setDatefrom] = useFilter("fromDate");
  const [dateto, setDateto] = useFilter("toDate");

  const [filter, setFilter] = useFilter("name", "");

  return (
    <Modal
      className="md:w-[32rem] md:h-[20rem] rounded-3xl w-[30rem]"
      onClose={() => {
        historyy.replace(cancel);
        setOpen(false);
      }}
      onOpen={() => {
        setOpen(true);
      }}
      closeIcon
      open={open}
      trigger={
        <Button className="py-0 px-0 rounded-xl ">
          <div className="flex py-2.5 px-4">
            <p>{langContent.OrdersFilterModel.filter}</p>
            <p className="px-1">
              <MdFilterAlt size={20} />
            </p>
          </div>
        </Button>
      }
    >
      {/* edit and data  heare  */}
      <div className="  px-8 py-8  ">
        <div className="flex justify-between my-2 mt-8 ">
          <label className="py-4 text-xl text-subtext">
            {langContent.OrdersFilterModel.date}
          </label>
          <input
            className="py-4 border-border  border-2 px-4 mx-2 rounded-xl outline-none"
            type="date"
            onChange={(e) => setDatefrom(e.target.value)}
            value={datefrom}
          />
          <input
            className="py-4 border-border  border-2 px-4 mx-2 rounded-xl outline-none "
            type="date"
            onChange={(e) => setDateto(e.target.value)}
            value={dateto}
          />
        </div>
        <div className={`py-4 flex justify-between`}>
          <label className="py-4 text-xl text-subtext">
            {langContent.TodayOrdersTable.status}
          </label>
          <MultiButtonFilter
            name="status"
            values={[
              {
                name: lable_one,
                value: status_one,
              },
              {
                name: lable_two,
                value: status_two,
              },
              history && {
                name: `${lable_three}`,
                value: `${status_three}`,
              },
            ].filter(Boolean)}
            history={history}
          />
        </div>
        <div className="flex justify-end py-4  ">
          <Button
            onClick={() => {
              setOpen(false);
              onReload();
            }}
            className="bg-green-600 text-white w-32 h-12 rounded-full mx-4 "
          >
            {langContent.OrdersFilterModel.apply}
          </Button>
          <button
            onClick={() => {
              setOpen(false);
              historyy.replace(cancel);
            }}
            className=" bg-white border-2 border-maincolor text-maincolor  border-y-2 w-32 h-12 rounded-full mx-4 "
          >
            {langContent.OrdersFilterModel.cancel}
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default ActiveOrdersFilterModel;
