import React, { useState } from "react";
import { Tab } from "semantic-ui-react";
import TodayOrdersTable from "../../../component/orders-component/Today-Orders-Table";
import { useLocation } from "react-router-dom";
import routes from "../../../routes";
import useTab from "../../../hooks/use-tab";
import PendingOrdersTable from "../../../component/orders-component/Pending-Orders-Table";
import HistoryOrdersTable from "../../../component/orders-component/History-Orders-Table";
import ActiveOrdersTable from "../../../component/orders-component/Active-Orders-Table";
import PickupRequestsTable from "../../../component/orders-component/Pickup-Order-Table";
import { useLanguage } from "../../../lib/language-context";
import { content } from "../../../localization/content";

const OrderTabs = () => {
  const [lang, setLang] = useLanguage("");
  const langContent = content[lang];
  const { pathname } = useLocation();

  const panes = [
    {
      menuItem: `${langContent.Orders.today}`,
      route: routes.Dashboard.Orders.TodayOrders,
      render: () => (
        <div>
          {/* <div className="text-subtext rtl:hidden mb-10">
            {pathname.split("/").join(" > ").slice(2)}
          </div> */}
          <Tab.Pane className="border-none w-full h-full  rounded-2xl">
            <TodayOrdersTable />
          </Tab.Pane>
        </div>
      ),
    },
    {
      menuItem: `${langContent.Orders.active}`,
      route: routes.Dashboard.Orders.ActiveOrders,
      render: () => (
        <div>
          {/* <div className="text-subtext rtl:hidden mb-10">
            {pathname.split("/").join(" > ").slice(2)}
          </div> */}
          <Tab.Pane className="border-none w-full h-full rounded-2xl">
            <ActiveOrdersTable />
          </Tab.Pane>
        </div>
      ),
    },
    {
      menuItem: `${langContent.Orders.pending}`,
      route: routes.Dashboard.Orders.PendingOrders,
      render: () => (
        <div>
          {/* <div className="text-subtext rtl:hidden mb-10">
            {pathname.split("/").join(" > ").slice(2)}
          </div> */}
          <Tab.Pane className="border-none w-full h-full rounded-2xl">
            <PendingOrdersTable />
          </Tab.Pane>
        </div>
      ),
    },
    {
      menuItem: `${langContent.Orders.history}`,
      route: routes.Dashboard.Orders.History,
      render: () => (
        <div>
          {/* <div className="text-subtext rtl:hidden mb-10">
            {pathname.split("/").join(" > ").slice(2)}
          </div> */}
          <Tab.Pane className="border-none w-full h-full rounded-2xl">
            <HistoryOrdersTable />
          </Tab.Pane>
        </div>
      ),
    },
    {
      menuItem: `${langContent.Orders.PickupRequests}`,
      route: routes.Dashboard.Orders.PickupRequests,
      render: () => (
        <div>
          {/* <div className="text-subtext rtl:hidden mb-10">
            {pathname.split("/").join(" > ").slice(2)}
          </div> */}
          <Tab.Pane className="border-none w-full h-full rounded-2xl">
            <PickupRequestsTable />
          </Tab.Pane>
        </div>
      ),
    },
  ];
  const { activeIndex, onTabChange } = useTab({ panes });

  return (
    <div className="">
      <div className="mx-auto h-auto rounded-3xl max-w-7xl  px-4  mt-14  edit-For-order-tab ">
        <Tab
          menu={{
            secondary: true,
            className: "flex flex-wrap",
          }}
          activeIndex={activeIndex}
          onTabChange={onTabChange}
          panes={panes}
        />
      </div>
    </div>
  );
};

export default OrderTabs;
