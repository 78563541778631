import React from "react";
import { AiOutlineClockCircle } from "react-icons/ai";
import { BsCheckLg } from "react-icons/bs";
import { FiGitBranch } from "react-icons/fi";
import { GiCancel } from "react-icons/gi";
import { useLanguage } from "../../../lib/language-context";
import { content } from "../../../localization/content";
//  AWAITING_DELIVERY =>   On the way
//  DELIVERED_ACTIVE =>   Arrived
//  PENDING_PROVIDER_OFFER =>   Requested
//  PENDING_USER_PAYMENT =>   Waiting for the user
//  COMPLETED =>   Completed
//  USER_CANCELLED_ORDER || PAYMENT_TIMED_OUT =>   Cancelled
//  USER_REJECTED_OFFER =>   Rejected
//  EARLY_PICK_UP_REQUEST =>  Early pickup request
//  FINAL_DATE_REACHED =>  Regular pickup date

const Status = ({ statusOrder }) => {
  const [lang, setLang] = useLanguage("");
  const langContent = content[lang];

  return (
    <div>
      {statusOrder === "AWAITING_DELIVERY" && (
        <div className="bg-maincolor py-2 rounded-full w-fit mx-auto px-4 text-white ">
          {langContent.Orders.Ontheway}
        </div>
      )}
      {statusOrder === "DELIVERED_ACTIVE" && (
        <div className="bg-statsyllow py-2 rounded-full w-fit mx-auto px-6 text-maincolor ">
          {langContent.Orders.Arrived}
        </div>
      )}
      {statusOrder === "PENDING_PROVIDER_OFFER" && (
        <div className="bg-pendingblue py-2 rounded-full w-fit mx-auto px-4 text-white flex ">
          <span className="mb-1 px-1 rotate-180">
            <FiGitBranch />
          </span>
          <p>{langContent.Orders.Requested}</p>
        </div>
      )}
      {statusOrder === "PENDING_USER_PAYMENT" && (
        <div className="bg-pendingyllow py-2 rounded-full w-fit mx-auto px-3 text-white flex">
          <span className="mt-1 px-1">
            <AiOutlineClockCircle />
          </span>
          <p>{langContent.Orders.Waitingfortheuser}</p>
        </div>
      )}
      {statusOrder === "COMPLETED" && (
        <div className="bg-green-500 py-2 rounded-full w-fit mx-auto px-4 text-white flex ">
          <span className="pt-1 px-1">
            <BsCheckLg />
          </span>
          <p> {langContent.Orders.Completed}</p>
        </div>
      )}
      {statusOrder === "USER_CANCELLED_ORDER" ||
      statusOrder === "PAYMENT_TIMED_OUT" ? (
        <div className="bg-statsubusred py-2 rounded-full w-fit mx-auto px-4 text-statusred flex ">
          <span className="pt-1 px-1">
            <GiCancel />
          </span>
          <p> {langContent.Orders.Canceled}</p>
        </div>
      ) : (
        ""
      )}
      {statusOrder === "USER_REJECTED_OFFER" && (
        <div className="bg-red-600 py-2 rounded-full w-fit mx-auto px-4 text-white flex ">
          <span className="pt-1 px-1">
            <GiCancel />
          </span>
          <p> {langContent.Orders.Rejected}</p>
        </div>
      )}
      {statusOrder === "EARLY_PICK_UP_REQUEST" && (
        <div className="bg-pendingblue py-2 rounded-full w-fit mx-auto px-4 text-white flex ">
          {langContent.Orders.Earlypickuprequest}
        </div>
      )}
      {statusOrder === "FINAL_DATE_REACHED" && (
        <div className="bg-statusgreen py-2 rounded-full w-fit mx-auto px-6 text-white flex ">
          {langContent.Orders.Regularpickupdate}
        </div>
      )}
    </div>
  );
};

export default Status;
