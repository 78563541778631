import axios from "axios";
import React from "react";
import { Button, Modal } from "semantic-ui-react";
import api from "../../../../api";
import { authAxios } from "../../../../config/axios-config";
import { useLanguage } from "../../../../lib/language-context";
import useAxios from "../../../../lib/use-axios";
import { content } from "../../../../localization/content";

function Setoffermodel({ requestId, onReload, setSuccesstostset }) {
  const [lang, setLang] = useLanguage("");
  const langContent = content[lang];
  const [open, setOpen] = React.useState(false);
  const [offer, setoffer] = React.useState();

  const number = (t) => /^(?:[1-9]\d*)$/.test(t);

  const { run, isLoading, data, isError, error } = useAxios({});

  const SentOffer = () => {
    const body = {
      requestId: requestId,
      price: parseInt(offer),
    };
    run(authAxios.post(api.Dashboard.orders.sentoffer, body)).then(() => {
      setSuccesstostset(true);
      setOpen(false);
      onReload();
    });
  };

  return (
    <div>
      <Modal
        className="md:w-[30rem] md:h-[15rem] w-[25rem] h-[15rem] bg-white rounded-2xl "
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
        closeIcon
        trigger={
          <button className=" w-56 h-14  py-2   md:my-10 my-2 rounded-full text-white bg-maincolor ">
            {lang === "en" ? " Send the offer" : " أرسل العرض"}
          </button>
        }
      >
        {/* edit heeeeeeeeeeeeeeeeeeeeer */}
        <div>
          <div className="flex  flex-col  items-center">
            <h1 className="text-left text-maintext text-xl py-2">
              {lang === "en" ? " Set the offered price" : " حدد السعر المعروض"}
            </h1>
            <input
              className="border-2 rounded-2xl w-80 h-16 text-maincolor my-0 px-2 outline-none"
              type="tel"
              onChange={(e) => {
                number(e?.target?.value);
                setoffer(e?.target?.value);
              }}
              placeholder=""
            />
            <span className="h-4 my-4 text-red-600  flex flex-col justify-center text-sm   ">
              <p>{lang === "en" ? error?.message?.en : error?.message?.ar}</p>
              {number(offer) === true
                ? ""
                : lang === "en"
                ? "Please make sure you enter the number correctly."
                : "من فضلك تأكد من ادخال الرقم بالشكل الصحيح"}
            </span>
            <Button
              loading={isLoading}
              onClick={number(offer) ? SentOffer : ""}
              className=" w-48 h-12 py-2 rounded-full text-white bg-maincolor my-2 font-serif"
            >
              {lang === "en" ? " Send the offer" : " أرسل العرض"}
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default Setoffermodel;
