import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import MainLogo from "../../../src/assets/pic/Main-Logo.svg";
import InputForm from "../common/input-form/Input-Form";
import routes from "../../routes";
import { useLanguage } from "../../lib/language-context";
import { content } from "../../localization/content";
import useAxios from "../../lib/use-axios";
import { axios } from "../../config/axios-config";
import api from "../../api";
import { Button } from "semantic-ui-react";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import FormikInput from "../common/formik/formik-input";

const AnotherPhoneScreen = () => {
  const [lang, setLang] = useLanguage("");
  const langContent = content[lang];
  const history = useHistory();
  const { pathname, ...location } = useLocation();

  const { run, isLoading, isError, error, data } = useAxios();
  const [otherPhoneNumber, SetOtherPhoneNumber] = React.useState("");

  const registerSchema = Yup.object({
    otherPhoneNumber: Yup.string()
      .required(`${langContent.SignUpScreen.you_must_start_with} + 966`)
      .min(3, `${langContent.SignUpScreen.must_be_3}`),
  });

  const register = (values) => {
    const body = {
      provider_name: location?.state?.values?.provider_name,
      email: location?.state?.values?.email,
      password: location?.state?.values?.password,
      licenceNumber: location?.state?.values?.licenceNumber,
      commericalNumber: location?.state?.values?.commericalNumber,
      mainPhoneNumber: location?.state?.values?.mainPhoneNumber,
      otherPhoneNumber: values.otherPhoneNumber,
    };
    run(axios.post(api.auth.SignUp, body))
      .then((res) => {
        history.push(routes.auth.SignIn, {
          // Id: res?.data?.data?.id,
          // name: res.data?.data?.name,
          // img: res?.data?.data?.image,
          // supplierCode: res?.data?.data?.supplierCode,
        });
      })
      .catch((err) => {});
  };
  return (
    // bg
    <div className="mx-auto w-full h-full fixed  transition-all ease-in-out delay-100  bg-backgroundGray">
      {/* AnotherPhoneScreen  */}
      <div className="max-w-7xl  h-[45rem] absolute my-auto mx-auto left-0 right-0  top-[50%] bottom-[50%]">
        <img
          className="absolute z-10 -top-5 left-[45%]  w-32 h-32  rounded-full mx-auto bg-white p-4"
          src={MainLogo}
          alt="logo"
        />
        <div className=" max-w-7xl h-[45rem]  overflow-hidden  rounded-3xl  bg-white absolute my-auto mx-auto left-0 right-0  top-[55%] bottom-[50%]">
          <div className="flex flex-col justify-center my-44">
            <h1 className="text-xl font-sans mb-10 text-center my-11">
              {langContent.AnotherPhoneScreen.another_phone_number}
              <br></br>
              {langContent.AnotherPhoneScreen.to_login_on_ezaha}
            </h1>
            <div className="pt-6 w-96 mx-auto">
              <Formik
                initialValues={{
                  otherPhoneNumber: "",
                }}
                onSubmit={register}
                validationSchema={registerSchema}
              >
                {(formik) => (
                  <Form onSubmit={formik.handleSubmit}>
                    <FormikInput
                      dir="ltr"
                      placeholder={
                        langContent.AnotherPhoneScreen.add_another_phone_number
                      }
                      label={
                        langContent.AnotherPhoneScreen.add_another_phone_number
                      }
                      name="otherPhoneNumber"
                    />
                    <span className="h-4 mt-1 text-red-600 text-left text-sm flex  ">
                      {lang === "en" ? error?.message?.en : error?.message?.ar}
                    </span>
                    <div className="flex justify-between">
                      <Button
                        loading={isLoading}
                        className="w-full mx-auto py-4 font-sans  my-4 rounded-3xl text-white bg-maincolor "
                        type="submit"
                      >
                        {langContent.AnotherPhoneScreen.continue}
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnotherPhoneScreen;
