import React, { useEffect, useState } from "react";
import { BsBoxSeam, BsCheckLg } from "react-icons/bs";
import { FaShippingFast } from "react-icons/fa";
import { useHistory, useLocation } from "react-router-dom";
import { Segment, Table } from "semantic-ui-react";
import api from "../../api";
import { authAxios } from "../../config/axios-config";
import { useLanguage } from "../../lib/language-context";
import useAxios from "../../lib/use-axios";
import { content } from "../../localization/content";
import routes from "../../routes";
import Status from "../common/status/status";
import ActiveOrdersFilterModel from "./Active-Orders-Filter-Model";
import Dropdownlistorder from "./Dropdown-list-order";
import emtystat from "../../../src/assets/pic/emty-stat-orders.svg";
import loading from "../../../src/assets/pic/loading.gif";
import Paginationtable from "../common/Pagination/Pagination";
import useFilter from "../../lib/use-filter";

const HistoryOrdersTable = () => {
  const [lang, setLang] = useLanguage("");
  const langContent = content[lang];
  const history = useHistory();

  const state_0 = "COMPLETED";
  const state_1 = "USER_CANCELLED_ORDER";
  const state_2 = "USER_REJECTED_OFFER";
  // const state_3 = "PAYMENT_TIMED_OUT";

  const [pagination, SetPagination] = useState();

  const { search, pathname } = useLocation();
  // const query = new URLSearchParams(search);
  // const searchedName = query.get("datefrom");

  const [forceReload, setForceReload] = React.useState(false);
  const onReload = React.useCallback(() => setForceReload((p) => !p), []);
  const { run, isLoading, data, isError, error } = useAxios({});

  const [status] = useFilter("status");
  const [page] = useFilter("page");
  const [perPage] = useFilter("perPage");
  const [sort] = useFilter("sort");

  // useEffect(() => {
  //   history.replace(
  //     `?status[]=${state_0}&status[]=${state_1}&status[]=${state_2}`
  //   );
  // }, []);
  useEffect(() => {
    if (page && perPage && sort) {
      run(authAxios.get(`${api.Dashboard.orders.history}${search}`)).then(
        (res) => {
          SetPagination(res?.data?.pagination);
        }
      );
    }
  }, [run, forceReload, search, page, perPage, sort]);

  const transletContainerSiz = (size) => {
    if (size === "TINY") {
      return lang === "en" ? "Six yards" : " ستة ياردة";
    }
    if (size === "SMALL") {
      return lang === "en"
        ? "Ten or Twelve yards "
        : " عشرة او اثنتا عشر ياردة";
    }
    if (size === "MEDIUM") {
      return lang === "en" ? "Twenty yards" : " عشرون ياردة";
    }
    if (size === "LARGE") {
      return lang === "en" ? "Thrity yards" : " تلاثون ياردة";
    }
  };

  return (
    <div>
      <Segment
        className="border-none shadow-none p-0 m-0 -z-1"
        loading={isLoading}
      >
        {/* {isLoading === true && (
            <div className="max-w-7xl h-screen bg-white/50    ">
              <img
                className="w-48 top-[50%] left-[42%] absolute   "
                src={loading}
                alt=""
              />
            </div>
          )} */}
        <div className="flex gap-5 justify-end">
          <span className="text-xl text-maintext py-2.5 hidden md:flex">
            {langContent.Orders.Sort}
          </span>
          <div className="flex  mx-auto md:mx-0">
            <div>
              <Dropdownlistorder />
            </div>
            <div className="h-9 mx-2">
              <ActiveOrdersFilterModel
                status_one={"COMPLETED"}
                status_two={"USER_CANCELLED_ORDER"}
                status_three={"USER_REJECTED_OFFER"}
                lable_one={langContent.Orders.Completed}
                lable_two={langContent.Orders.Canceled}
                lable_three={langContent.Orders.Rejected}
                history={true}
                onReload={onReload}
                cancel={"?"}
              />
            </div>
          </div>
        </div>

        <Table className="" basic="very">
          {data?.length === 0 ? (
            <div className="my-6">
              <img className="mx-auto " src={emtystat} alt="" />
              <p className="text-maincolor font-bold text-6xl text-center my-24">
                {lang === "en"
                  ? "There are no History orders"
                  : "لا يوجد طلبات في السجلات الآن   "}
              </p>
            </div>
          ) : (
            <Table.Header className="hidden md:contents">
              <Table.Row>
                <Table.HeaderCell className=" text-center border-none text-hedertext font-medium">
                  {langContent.Orders.Order_ID}
                </Table.HeaderCell>
                <Table.HeaderCell className=" text-center border-none text-hedertext font-medium">
                  {langContent.Orders.Status}
                </Table.HeaderCell>
                <Table.HeaderCell className=" text-center border-none text-hedertext font-medium">
                  {langContent.Orders.Quantity}
                </Table.HeaderCell>
                <Table.HeaderCell className=" text-center border-none text-hedertext font-medium">
                  {langContent.Orders.contanersize}
                </Table.HeaderCell>
                {/* <Table.HeaderCell className=" text-center border-none text-hedertext font-medium">
                  delivery date
                </Table.HeaderCell> */}
                {/* <Table.HeaderCell className=" text-center border-none text-hedertext font-medium">
                  Regular pickup date
                </Table.HeaderCell> */}
                <Table.HeaderCell className=" text-center border-none text-hedertext font-medium">
                  {langContent.Orders.Offer}
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
          )}

          {data?.map((e) => (
            <Table.Body key={e?.id}>
              <div className="my-2"></div>
              <Table.Row
                className="active:bg-subcolor"
                onClick={() =>
                  history.push(routes.Dashboard.Orders.DetailsHistory(e?.id))
                }
              >
                <Table.Cell className="text-center py-8 ltr:border-l-2 rtl:border-r-2 ltr:rounded-l-xl rtl:rounded-r-xl border-y-2 border-border">
                  <div className="flex justify-between  md:block">
                    <h1 className="text-hedertext bg-gray-50 w-32 p-4 rounded-lg md:hidden flex justify-center  ">
                      {langContent.Orders.Order_ID}
                    </h1>
                    <div className="flex justify-center gap-4 mx-2 py-4 md:py-0">
                      <p className="text-maincolor">
                        <BsBoxSeam size={20} />
                      </p>
                      <p>{e?.id}</p>
                    </div>
                  </div>
                </Table.Cell>
                <Table.Cell className="text-center py-8 border-y-2 border-border">
                  <div className="flex justify-between  md:block">
                    <h1 className="text-hedertext bg-gray-50 w-32 p-4 rounded-lg md:hidden flex justify-center  ">
                      {langContent.Orders.Status}
                    </h1>
                    <Status statusOrder={e?.status} />
                  </div>
                </Table.Cell>
                <Table.Cell className="text-center py-8 border-y-2 border-border">
                  <div className="flex justify-between  md:block">
                    <h1 className="text-hedertext bg-gray-50 w-32 p-4 rounded-lg md:hidden flex justify-center  ">
                      {langContent.Orders.Quantity}
                    </h1>
                    <p className="py-4 md:py-0">{e?.numberOfContainers}</p>
                  </div>
                </Table.Cell>
                <Table.Cell className="text-center py-8 border-y-2 border-border">
                  <div className="flex justify-between  md:block">
                    <h1 className="text-hedertext bg-gray-50 w-32 p-4 rounded-lg md:hidden flex justify-center  ">
                      {langContent.Orders.contanersize}
                    </h1>
                    <p className="py-4 md:py-0">
                      {transletContainerSiz(e?.container?.size)}
                    </p>
                  </div>
                </Table.Cell>
                {/* <Table.Cell className="text-center py-8 border-y-2 border-border">
                    {new Date(e?.deliveryDate).toLocaleDateString("en-GB")}
                  </Table.Cell>
                  <Table.Cell className="text-center py-8 border-y-2 border-border">
                    {new Date(e?.expairyDate).toLocaleDateString("en-GB")}
                  </Table.Cell> */}
                <Table.Cell className="text-center py-8 ltr:border-r-2 rtl:border-l-2 ltr:rounded-r-xl rtl:rounded-l-xl  border-y-2 border-border">
                  <div className="flex justify-between  md:block">
                    <h1 className="text-hedertext bg-gray-50 w-32 p-4 rounded-lg md:hidden flex justify-center  ">
                      {langContent.Orders.Offer}
                    </h1>
                    <p className="py-4 md:py-0 flex justify-center">
                      <p className="py-4 md:py-0 flex  gap-x-2">
                        {e?.amount || "---"}
                        <p className="text-maincolor">
                          {lang === "en" ? "SAR" : "ريال"}
                        </p>
                      </p>
                    </p>
                  </div>
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          ))}
        </Table>
        <div
          className={`${
            data?.length === 0 && "hidden"
          } flex flex-row-reverse edit_for_Pagination edit_for_Pagination`}
        >
          <Paginationtable totalPages={pagination?.totalPages} />
        </div>
      </Segment>
    </div>
  );
};
export default HistoryOrdersTable;
