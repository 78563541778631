import React, { useState } from "react";
import { GiPowerButton } from "react-icons/gi";
import { HiOutlinePhotograph } from "react-icons/hi";
import { FiUpload } from "react-icons/fi";
import { useHistory } from "react-router-dom";
import { useLanguage } from "../../../lib/language-context";
import { content } from "../../../localization/content";
import { Button, Segment } from "semantic-ui-react";
import { Form, Formik } from "formik";
import FormikInput from "../../../component/common/formik/formik-input";
import useAxios from "../../../lib/use-axios";
import { authAxios } from "../../../config/axios-config";
import api from "../../../api";
import * as Yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useAuthState } from "../../../context/auth-context";
import { BiLogInCircle } from "react-icons/bi";
import { AiOutlineCloudUpload } from "react-icons/ai";
import EditPicModel from "./Edit-Pic-Model";

const Profile = () => {
  const [lang, setLang] = useLanguage("");
  const langContent = content[lang];

  const profileSchema = Yup.object({
    name: Yup.string()
      .min(3, `${langContent.SignUpScreen.must_be_more_3}`)
      .max(25, `${langContent.SignUpScreen.name_must_be_20}`),
    email: Yup.string()
      .email(`${langContent.SignUpScreen.email_not_valid}`)
      .trim(),
    mainPhoneNumber: Yup.string().min(
      3,
      `${langContent.SignUpScreen.you_must_start_with} + 20`
    ),
    // .min(3, `${langContent.SignUpScreen.must_be_3}`),
  });

  const passSchema = Yup.object({
    Old_password: Yup.string()
      .min(8, `${langContent.SignUpScreen.password_must_be_8}`)
      .max(20, `${langContent.SignUpScreen.password_must_be_20}`)
      .required(`${langContent.SignUpScreen.required}`),
    New_password: Yup.string()
      .min(8, `${langContent.SignUpScreen.password_must_be_8}`)
      .max(20, `${langContent.SignUpScreen.password_must_be_20}`)
      .required(`${langContent.SignUpScreen.required}`),
    Confirm_password: Yup.string().oneOf(
      [Yup.ref("New_password"), null],
      `${langContent.SignUpScreen.not_match}`
    ),
  });

  var retrievedObject = localStorage.getItem("providerId");
  const { run, isLoading, data, isError, error } = useAxios({});
  const {
    run: runputProfile,
    isLoading: isLoadingputProfile,
    data: dataputProfile,
    error: errorputProfile,
  } = useAxios({});

  const {
    run: runputPass,
    isLoading: isLoadingputPass,
    data: dataputPass,
    error: errorputPass,
  } = useAxios({});

  const success = (content) =>
    toast.success(content, {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  const err = (content) =>
    toast.error(content, {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const [forceReload, setForceReload] = React.useState(false);
  const onReload = React.useCallback(() => setForceReload((p) => !p), []);

  React.useEffect(() => {
    run(authAxios.get(api.Dashboard.profile.getProfile)).then((res) => {});
  }, [run, forceReload]);

  const putProfile = (value) => {
    const body = {
      provider_name: value?.name,
      email: value?.email,
      otherPhoneNumber: value?.otherPhoneNumber,
    };
    runputProfile(
      authAxios.put(api.Dashboard.profile.putProfile(retrievedObject), body)
    )
      .then((res) => {
        success(
          lang === "en"
            ? "basic info added successfully"
            : " تمت إضافة المعلومات الأساسية بنجاح"
        );
      })
      .catch(() => {
        err(
          lang === "en"
            ? errorputProfile?.message?.en
            : errorputProfile?.message?.ar
        ) ||
          err(
            lang === "en"
              ? errorputProfile.message[0]
              : "خطأ في بيانات المستخدم"
          );
      });
  };

  const putPass = (value) => {
    const body = {
      oldCredential: value?.Old_password,
      newCredential: value?.New_password,
    };
    runputPass(authAxios.put(api.Dashboard.profile.putpass, body))
      .then((res) => {
        success(
          lang === "en"
            ? "Password changed successfully"
            : " تم تغيير كلمة المرور بنجاح"
        );
      })
      .catch(() => {
        err(lang === "en" ? errorputPass.message.en : errorputPass.message.ar);
      });
  };
  return (
    <div className=" max-w-7xl mx-auto">
      <Segment
        className="border-none shadow-none bg-transparent -z-1  p-0 m-0"
        loading={isLoading}
      >
        <ToastContainer />
        {/* nest side bare layaot */}
        <div className="">{/* left section */}</div>
        {/* right section */}
        <div className="   ">
          <div className="">
            <h1 className=" text-maincolor text-3xl font-bold py-10 px-8 font-serif">
              {langContent.Profile.Provider_details}
            </h1>
            {/* img section */}
            <EditPicModel
              img={data?.image && data?.image[0]?.link}
              imglength={data?.image && data?.image?.length}
              id={retrievedObject}
              onReload={onReload}
              successs={success}
              name={data?.name}
            />
            {/* basic info  */}
            <h1 className=" text-maincolor text-3xl font-bold py-8 mx-8  font-serif  ">
              {langContent.Profile.basic_info}
            </h1>
            <div className="bg-white   py-8 rounded-lg  shadow-maincolor/10 shadow-md">
              <div className="">
                <Formik
                  initialValues={{
                    name: data?.name || "",
                    email: data?.email || "",
                    otherPhoneNumber: data?.otherPhoneNumber || "",
                    mainPhoneNumber: data?.mainPhoneNumber || "",
                    licenceNumber: data?.licenceNumber || "",
                    commericalNumber: data?.commericalNumber || "",
                  }}
                  onSubmit={putProfile}
                  validationSchema={profileSchema}
                  enableReinitialize
                >
                  {(formik) => (
                    <Form onSubmit={formik.handleSubmit}>
                      <div className="grid md:grid-cols-3 grid-cols-1 mx-5  gap-5">
                        <div className="w-full ">
                          <FormikInput
                            label={langContent.SignUpScreen.user_name}
                            placeholder={langContent.SignUpScreen.user_name}
                            name="name"
                          />
                          <FormikInput
                            label={langContent.SignUpScreen.email}
                            placeholder={langContent.SignUpScreen.email}
                            name="email"
                          />
                          <FormikInput
                            dir="ltr"
                            className={"text-right"}
                            label={langContent.Profile.another_phone_number}
                            placeholder={
                              langContent.Profile.another_phone_number
                            }
                            name="otherPhoneNumber"
                          />
                        </div>
                        <div className="w-full text-subtext/70    cursor-not-allowed">
                          <FormikInput
                            dir="ltr"
                            label={langContent.Profile.Main_phone_number}
                            placeholder={langContent.Profile.Main_phone_number}
                            name="mainPhoneNumber"
                            disabled
                          />
                          <FormikInput
                            label={langContent.SignUpScreen.licnse_number}
                            placeholder={langContent.SignUpScreen.licnse_number}
                            name="licenceNumber"
                            disabled
                          />
                          <FormikInput
                            label={langContent.SignUpScreen.Commerical_number}
                            placeholder={
                              langContent.SignUpScreen.Commerical_number
                            }
                            name="commericalNumber"
                            disabled
                          />
                        </div>
                        <div className=" flex items-end">
                          <span className="h-4 mt-1 text-red-600 text-left text-sm flex  ltr::ml-8 rtl:mr-8  ">
                            {lang === "en"
                              ? error?.message?.en
                              : error?.message?.ar}
                          </span>
                          <div className="flex mx-auto ">
                            <Button
                              loading={isLoadingputProfile}
                              className="flex  text-white bg-maincolor rounded-full py-2 px-8 my-4 text-2xl shadow shadow-maincolor font-serif"
                              type="submit"
                            >
                              {langContent.Profile.submit}
                              <BiLogInCircle
                                size={22}
                                className="mt-1 mx-1.5"
                              />
                            </Button>
                          </div>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
            {/* Change password */}
            <div className="w-full max-w-7xl py-10">
              <h1 className=" text-maincolor text-3xl font-bold py-8 mx-8 font-serif  ">
                {langContent.Profile.Change_password}
              </h1>
              <div className="w-full bg-white rounded-lg shadow-md shadow-maincolor/10  ">
                <Formik
                  initialValues={{
                    Old_password: "",
                    New_password: "",
                    Confirm_password: "",
                  }}
                  onSubmit={putPass}
                  validationSchema={passSchema}
                >
                  {(formik) => {
                    return (
                      <Form onSubmit={formik.handleSubmit}>
                        <div className="grid md:grid-cols-3 grid-cols-1  mx-5 gap-5">
                          <div>
                            <FormikInput
                              label={langContent.Profile.Old_password}
                              placeholder={langContent.Profile.Old_password}
                              name="Old_password"
                              type="password"
                            />
                          </div>

                          <div>
                            <FormikInput
                              label={langContent.Profile.New_password}
                              placeholder={langContent.Profile.New_password}
                              name="New_password"
                              type="password"
                            />
                          </div>
                          <div>
                            <FormikInput
                              label={langContent.Profile.Confirm_new_password}
                              placeholder={
                                langContent.Profile.Confirm_new_password
                              }
                              name="Confirm_password"
                              type="password"
                            />
                          </div>
                        </div>
                        <div className="flex md:justify-end  justify-center my-4 mx-6">
                          <Button
                            loading={isLoadingputPass}
                            className="flex   text-white bg-maincolor rounded-full py-2 px-8 my-4 text-2xl font-serif "
                            type="submit"
                          >
                            {langContent.Profile.submit}
                            <BiLogInCircle size={22} className="mt-1 mx-1.5" />
                          </Button>
                        </div>
                      </Form>
                    );
                  }}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </Segment>
    </div>
  );
};

export default Profile;
