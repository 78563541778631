import React from "react";
import { RiUploadCloud2Line, RiDeleteBin7Fill } from "react-icons/ri";
import { useLanguage } from "../../lib/language-context";
import { content } from "../../localization/content";

function PdfAttachment({ id, label, setAttachmentData }) {
  const [lang, setLang] = useLanguage("");
  const langContent = content[lang];
  const [file, setFile] = React.useState(null);

  const handleOnSelectedFile = (e) => {
    setFile(e.target.files[0]);
    setAttachmentData(e.target.files[0]);
  };

  const clearAttachment = () => {
    setFile(null);
    setAttachmentData(null);
  };

  return (
    <>
      {file ? (
        <div className="flex justify-between my-4">
          <span className="break-all">
            <strong>{"File name"}</strong>: {file.name}
          </span>
          <div
            className="text-red-500 flex items-center justify-center cursor-pointer"
            onClick={clearAttachment}
          >
            <RiDeleteBin7Fill size="16" className="ltr:mr-3 rtl:ml-3" />

            {langContent.CompletedProfile.clear}
          </div>
        </div>
      ) : (
        <>
          <input
            type="file"
            id={id}
            accept="*/*"
            className="hidden"
            onChange={handleOnSelectedFile}
          />
          <label htmlFor={id} className="text-primary flex my-4">
            <div className="flex items-center justify-center cursor-pointer">
              <RiUploadCloud2Line size="24" className="ltr:mr-3 rtl:ml-3" />
              <span>
                {label ||
                  `${langContent.CompletedProfile.Select_a_file_to_attach}`}
              </span>
            </div>
          </label>
        </>
      )}
    </>
  );
}

export default PdfAttachment;
