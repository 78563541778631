import React from "react";
import { Route } from "react-router-dom";
import { Switch } from "react-router-dom";
import BranshesDetails from "../component/Branches-Component/Branshes-Details";
import SideBar from "../component/common/Side-bare/Side-Bar";
import OrderDetails from "../component/orders-component/Order-Details";
import BranchesTabs from "../pages/app/Branches/Branches-Tabs";
import AddContainer from "../pages/app/Containers/Add-Container";
import ContainerTabs from "../pages/app/Containers/Container-Tabs";
import UpdateContainer from "../pages/app/Containers/Update-Container";
import Notification from "../pages/app/Notifcations/Notification";
import OrderTabs from "../pages/app/orders/Orders-Tabs";
import Profile from "../pages/app/Profile/Profile.js";
import Reviews from "../pages/app/Reviews/Reviews";
import routes from "../routes";
const AppLayouts = () => {
  return (
    <div className="">
      <div className=" ">
        <SideBar />
      </div>
      <div className="mx-auto lg:rtl:mr-[16rem]   lg:ltr:ml-[16rem] ">
        <Switch>
          <Route
            path={routes.Dashboard.Branches.AddContainers}
            component={AddContainer}
          />
          <Route
            path={routes.Dashboard.Branches.UpdateContainers}
            component={UpdateContainer}
          />
          <Route
            path={routes.Dashboard.Branches.BranchesDetailsActive()}
            component={() => (
              <BranshesDetails
                backpath={routes.Dashboard.Branches.ActiveBranches}
              />
            )}
          />
          <Route
            path={routes.Dashboard.Branches.BranchesDetailsArchive()}
            component={() => (
              <BranshesDetails backpath={routes.Dashboard.Branches.Archive} />
            )}
          />
          <Route
            path={routes.Dashboard.Branches.default}
            component={BranchesTabs}
          />

          <Route
            path={routes.Dashboard.Orders.DetailsTodayOrders()}
            component={() => (
              <OrderDetails backpath={routes.Dashboard.Orders.TodayOrders} />
            )}
          />
          <Route
            path={routes.Dashboard.Orders.DetailsActiveOrders()}
            component={() => (
              <OrderDetails backpath={routes.Dashboard.Orders.ActiveOrders} />
            )}
          />
          <Route
            path={routes.Dashboard.Orders.DetailsPendingOrders()}
            component={() => (
              <OrderDetails backpath={routes.Dashboard.Orders.PendingOrders} />
            )}
          />
          <Route
            path={routes.Dashboard.Orders.DetailsHistory()}
            component={() => (
              <OrderDetails backpath={routes.Dashboard.Orders.History} />
            )}
          />
          <Route
            path={routes.Dashboard.Orders.DetailsPickupRequests()}
            component={() => (
              <OrderDetails backpath={routes.Dashboard.Orders.PickupRequests} />
            )}
          />

          <Route path={routes.Dashboard.Orders.default} component={OrderTabs} />

          <Route
            path={routes.Dashboard.Notifications.default}
            component={Notification}
          />
          <Route path={routes.Dashboard.Reviews.default} component={Reviews} />
          <Route path={routes.Dashboard.Profile.default} component={Profile} />
        </Switch>
      </div>
    </div>
  );
};

export default AppLayouts;
