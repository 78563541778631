import React from "react";
import { MdArchive, MdUnarchive } from "react-icons/md";
import { useHistory, useParams } from "react-router-dom";
import { Button, Modal } from "semantic-ui-react";
import api from "../../api";
import { authAxios } from "../../config/axios-config";
import { useLanguage } from "../../lib/language-context";
import useAxios from "../../lib/use-axios";
import { content } from "../../localization/content";
import routes from "../../routes";

function UnarchiveModel({ dataa }) {
  const [lang, setLang] = useLanguage("");
  const langContent = content[lang];
  const [open, setOpen] = React.useState(false);

  const history = useHistory();
  const { branchesdDtailsId } = useParams();

  const {
    run: runarchive,
    isLoading: isLoadingarchive,
    data: dataarchive,
    isError: isErrorarchive,
    error: errorarchive,
  } = useAxios({});
  const {
    run: rununarchive,
    isLoading: isLoadingunarchive,
    data: dataunarchive,
    isError: isErrorunarchive,
    error: errorunarchive,
  } = useAxios({});

  const archive = () => {
    runarchive(
      authAxios.patch(
        `${api.Dashboard.BranchesDetails(branchesdDtailsId)}/archive`
      )
    ).then((res) => {
      history.push(routes.Dashboard.Branches.ActiveBranches, {
        redtost: true,
      });
    });
  };
  const unarchive = () => {
    rununarchive(
      authAxios.patch(
        `${api.Dashboard.BranchesDetails(branchesdDtailsId)}/unarchive`
      )
    ).then((res) => {
      history.push(routes.Dashboard.Branches.Archive, {
        greentost: true,
      });
    });
  };

  return (
    <Modal
      className={`${
        dataa === false ? "w-[35rem] h-[20rem] " : "w-[30rem] h-[15rem]"
      } rounded-3xl`}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      closeIcon
      open={open}
      trigger={
        <Button className="py-0 px-0 bg-transparent ">
          <div className="flex py-1 px-4">
            {dataa === false ? (
              <button className="bg-transparent hover:bg-transparent   text-maincolor text-4xl p-0 m-0 ">
                <MdArchive />
              </button>
            ) : (
              <button className="bg-transparent hover:bg-transparent  text-maincolor text-4xl  p-0 m-0">
                <MdUnarchive />
              </button>
            )}
          </div>
        </Button>
      }
    >
      {/* edit and data  heare  */}
      <div className="  px-8 py-4  ">
        <div className="text-center ">
          <h1 className="text-2xl font-bold mx-4 py-2 font-serif">
            {dataa === false
              ? lang === "en"
                ? "Are you sure you want to archive this branch ? "
                : "هل أنت متأكد أنك تريد أرشفة هذا الفرع؟"
              : lang === "en"
              ? "Are you sure you want to un archive this branch ? "
              : "هل أنت متأكد أنك تريد تنشيط هذا الفرع؟"}
          </h1>
          <ul
            className={`${
              dataa === false ? "" : "hidden"
            } py-8 mx-8 font-serif `}
          >
            <li className="text-start list-disc font-serif">
              {lang === "en"
                ? "Active requests will remain on branch containers"
                : "ستظل الطلبات النشطة على حاويات الفرع "}
            </li>
            <li className="text-start list-disc py-4">
              {lang === "en"
                ? "will not receive any new orders on containers in this branch"
                : "لن تتلقى أي طلبات جديدة على الحاويات في هذا الفرع"}
            </li>
          </ul>
        </div>
        <div
          className={`${
            dataa === false ? "" : "py-8"
          } flex justify-center mt-8 `}
        >
          <Button
            loading={isLoadingarchive || isLoadingunarchive}
            onClick={
              dataa === false
                ? () => {
                    archive();
                  }
                : () => {
                    unarchive();
                  }
            }
            className={`${
              dataa === false ? " bg-red-600" : "bg-green-600"
            } w-48 h-12 rounded-full mx-4 border-2   text-white  font-serif`}
          >
            {dataa === false
              ? lang === "en"
                ? "Archive"
                : " أرشفة "
              : lang === "en"
              ? "Un Archive"
              : " تنشيط"}
          </Button>
          <button
            onClick={() => setOpen(false)}
            className={`
              border-2 w-48 h-12 rounded-full border-maincolor  text-maincolor mx-4 shadow-md shadow-maincolor/10 `}
          >
            {lang === "en" ? "cancel" : "إلغاء"}
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default UnarchiveModel;
