import React from "react";
import { Field } from "formik";
import { Form } from "semantic-ui-react";
import ErrorMessage from "./error-message";

function FormikDropdown({ label, name, className, onChangeCB, ...props }) {
  return (
    <Field name={name}>
      {({ form, field }) => {
        const { setFieldValue, setFieldTouched, errors, touched } = form;
        return (
          <div className="flex flex-col">
            <Form.Dropdown
              id={name}
              label={
                <label htmlFor={name} className=" text-sm mt-2 text-primary">
                  {label}
                </label>
              }
              className={`block mt-2 ${className}`}
              error={Boolean(touched[name] && errors[name])}
              fluid
              selection
              {...field}
              {...props}
              onBlur={() => setFieldTouched(name, true)}
              onChange={(e, { value }) => {
                setFieldValue(name, value);
                if (typeof onChangeCB === "function") onChangeCB(value);
              }}
            >
              {props.children}
            </Form.Dropdown>
            {touched[name] && errors[name] && (
              <ErrorMessage error={errors[name]} />
            )}
          </div>
        );
      }}
    </Field>
  );
}

export default FormikDropdown;
