import React, { useEffect, useState } from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import { MdClose } from "react-icons/md";
import { useHistory, useLocation } from "react-router-dom";
import { useQuery } from "../../../hooks/use-query";
import { useLanguage } from "../../../lib/language-context";
import { content } from "../../../localization/content";
import routes from "../../../routes";
import Avatar from "../../common/Avatar/Avater";
import Navlinkedit from "./nav-link-edit";

const Sidebareedit = () => {
  const [lang, setLang] = useLanguage("");
  const langContent = content[lang];
  const history = useHistory();
  const { pathname } = useLocation();
  const query = useQuery();
  const adminName = query.get("name");
  const [name, setName] = useState();
  useEffect(() => {
    window.localStorage.setItem("adminName", adminName);
    setName(window.localStorage.getItem("adminName"));
  }, [name]);
  const [sid, SetSid] = React.useState(false);
  const HandleSid = () => {
    SetSid(!sid);
  };
  return (
    // sidbare
    <div>
      <div
        className=" flex lg:hidden bg-maincolor w-fit py-2 px-2 rounded-lg  cursor-pointer relative z-50 m-4 "
        onClick={HandleSid}
      >
        {sid ? (
          <MdClose size={25} className="text-white " />
        ) : (
          <GiHamburgerMenu size={25} className="text-white " />
        )}
      </div>
      <div className="lg:flex hidden ">
        <div className="fixed  flex flex-col  h-screen w-80 overflow-hidden bg-backgroundGray ">
          {/* border white  */}
          <div className="flex justify-center my-16">
            {/* img logo */}
            <Avatar
              name={name}
              styles={
                "w-24 h-24 rounded-full text-center text-4xl text-white pt-6"
              }
            />
          </div>
          <Navlinkedit
            title={langContent.criticalInfo.changePassword}
            isActive={
              pathname.length === 1 ||
              pathname.startsWith(routes.ediPersonalInfo.changePassword)
            }
            onClick={() => history.push(routes.ediPersonalInfo.changePassword)}
          />
        </div>
      </div>
      <div
        className={
          sid
            ? "fixed z-[100] ltr:left-0 top-0 rtl:right-0 w-full h-full ease-in-out duration-500"
            : "ease-in-out duration-500 fixed ltr:left-[-100%] rtl:right-[-100%]  z-[500] "
        }
      >
        <div>
          <div className="fixed  flex flex-col  h-screen w-80 overflow-hidden bg-backgroundGray ">
            {/* border white  */}
            <div className="flex justify-center my-16">
              {/* img logo */}
              <Avatar
                name={name}
                styles={
                  "w-24 h-24 rounded-full text-center text-4xl text-white pt-6"
                }
              />
            </div>
            <Navlinkedit
              title={langContent.criticalInfo.changePassword}
              isActive={
                pathname.length === 1 ||
                pathname.startsWith(routes.ediPersonalInfo.changePassword)
              }
              onClick={() => {
                history.push(routes.ediPersonalInfo.changePassword);
                HandleSid();
              }}
            />
          </div>
          <div className="w-full h-screen" onClick={HandleSid}></div>
        </div>
      </div>
    </div>
  );
};

export default Sidebareedit;
