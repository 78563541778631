import React, { useState } from "react";
import { Button, Progress } from "semantic-ui-react";

const Progresspar = ({ step }) => {
  return (
    <div>
      {step === 1 && <Progress percent={30} indicating />}
      {step === 2 && <Progress percent={60} indicating />}
      {step === 3 && <Progress percent={90} indicating />}
    </div>
  );
};

export default Progresspar;
