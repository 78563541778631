const api = {
  auth: {
    SentOtp: "/auth/send-otp",
    verifyOtp: "/auth/verify-otp",
    ResendOtp: "/auth/resend-otp",
    SignUp: "/auth/provider/register",
    ForgetPassword: "/auth/provider/forget-password",
    ResetPassword: "/auth/provider/reset-password",
    Login: "/auth/provider/login",
    RefrshToken: "/auth/refresh-token",
    createsupplier: "/providers/create-supplier",
    supplierDocuments: "/providers/upload-supplierDocuments",
    sendforgetpassword: "/providers/send-forget-password",
  },
  criticalinfo: {
    forgetPassword: "/providers/send-forget-password",
  },
  Dashboard: {
    AddBranches: "/locations",
    GetCity: "/areas/all",
    GetProvinces: "/areas/provinces",
    GetNearestArea: "/areas/nearest-area",
    GetNearestRegion: "/areas/nearest-region",
    ViewBraches: "/locations",
    BranchesDetails: (BranchdetailsId) => `/locations/${BranchdetailsId}`,
    containers: {
      containers: "/containers",
      getcontainers: (containersId) => `/containers/${containersId}`,
      postimgcontainer: (containersId) =>
        `/containers/${containersId}/replace-image`,
    },
    profile: {
      getProfile: "/providers",
      putProfile: (providersId) => `/providers/${providersId}/update-info/`,
      putProfileImg: (providersId) => `/providers/${providersId}/update-image`,
      putpass: "/providers/update-credential",
    },

    orders: {
      today: "/orders/provider/today",
      active: "/orders/provider/active",
      pending: "/orders/provider/pending",
      history: "/orders/provider/history",
      pickup: "/orders/provider/pickups",
      orderdetails: (orderID) => `/orders/provider/order-details/${orderID}`,
      sentoffer: "/orders/provider/send-offer-to-request",
      setdelivered: "/orders/provider/set-order-delivered",
      setcomplited: "/orders/provider/set-order-completed",
      setreject: "/orders/provider/reject-request",
      sentpickup: "/orders/provider/send-pickup-date",
    },

    Notification: {
      default: "/providers/notifications",
    },

    reviews: {
      default: "/providers/reviews",
    },
  },
};

export default api;
